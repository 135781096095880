import { Box, MenuItem, Typography, Checkbox } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from 'react-beautiful-dnd';
import Image from 'next/image';

interface ConfigureColumnsProps {
    onCheck: (val: any) => void;
    onDragged: (val: any) => void;
    data: {
        columnName: string;
        checked?: boolean;
        dataName: string;
    }[];
}
const label = { inputProps: { 'aria-label': 'Checkbox' } };

export const ConfigureColumns = (props: ConfigureColumnsProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mockData, setMockData] = useState(props.data);

    const open = Boolean(anchorEl);
    const handleClick = useCallback((event: any) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    useEffect(() => {
        setMockData(props.data);
    }, [props.data]);

    const handleCheck = (e: any) => {
        let tempData = [...(mockData ?? [])];
        const filterDataTrue = tempData?.filter(
            (data: any) => data?.checked === true
        );
        const filterDataFlase = tempData?.filter(
            (data: any) => data?.checked === false
        );

        if (filterDataTrue.length > 1) {
            tempData = tempData?.map((data) => {
                const copiedData = { ...data };
                if (copiedData && copiedData?.dataName == e.target.name) {
                    copiedData.checked = !copiedData?.checked;
                }
                return copiedData;
            });
        } else {
            if (filterDataFlase?.length > 1) {
                tempData = tempData?.map((item) => {
                    const temp = { ...item };
                    if (temp?.checked === false) {
                        if (temp && temp?.dataName == e.target.name) {
                            temp.checked = !temp.checked;
                        }
                    }
                    return temp;
                });
            }
        }
        props.onCheck(tempData);
        setMockData(tempData);
    };
    const StyledMenu = styled((Menuprops: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            {...Menuprops}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light'
                    ? 'rgb(55, 65, 81)'
                    : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0'
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5)
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity
                    )
                }
            }
        }
    }));

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;
        const newItems = reorder(mockData, source.index, destination.index);
        setMockData(newItems);
    };

    const reorder = (
        list: {
            dataName: string;
            checked?: boolean;
            columnName: string;
        }[],
        startIndex: number,
        endIndex: number
    ) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        props.onDragged(result);
        setMockData(result);
        return result;
    };

    return (
        <Box>
            <MenuItem style={{ color: '#6663FD' }} onClick={handleClick}>
                <Typography variant="body2">Configure columns</Typography>
            </MenuItem>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-list">
                        {(dropProvided: any) => (
                            <div
                                ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}
                            >
                                {mockData?.length > 0 &&
                                    mockData?.map((data, index) => (
                                        <Draggable
                                            draggableId={data?.dataName}
                                            index={index}
                                            key={data?.dataName}
                                        >
                                            {(dragProvided) => (
                                                <MenuItem
                                                    ref={dragProvided.innerRef}
                                                    {...dragProvided.draggableProps}
                                                    {...dragProvided.dragHandleProps}
                                                    sx={{
                                                        padding: '8px 12px'
                                                    }}
                                                >
                                                    {' '}
                                                    <Image
                                                        src={
                                                            '/images/icons/reorderIcon.png'
                                                        }
                                                        width={20}
                                                        height={20}
                                                    />
                                                    <Checkbox
                                                        sx={{
                                                            width: '20px',
                                                            height: '20px',
                                                            margin: '0 12px'
                                                        }}
                                                        {...label}
                                                        name={data?.dataName}
                                                        checked={data?.checked}
                                                        onChange={handleCheck}
                                                    />
                                                    {data?.columnName}
                                                </MenuItem>
                                            )}
                                        </Draggable>
                                    ))}
                                {dropProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </StyledMenu>
        </Box>
    );
};

import { TextField, SaveButton } from '@components/Atoms';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import SaveIcon from '@mui/icons-material/Save';
import {
    CreateAllowListModalLayoutProps,
    AddWalletAddressLayoutProps,
    AddAuth0IDsLayoutProps,
    InvalidEntriesLayoutProps,
    Options
} from '@interface/CreateAllowListModalLayout';
import {
    Box,
    Typography,
    Stack,
    MenuItem,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow
} from '@mui/material';
import { Button } from '@components/Atoms';
import React, { useCallback, useMemo } from 'react';
import Image from 'next/image';
import { useTheme } from '@mui/material/styles';
import { MixTheme, useStyle } from '@styles';
import { makeStyles } from '../allowList/allowList.style';
import { AddAuthId } from '@interface/Allowlist';
import CustomTooltip from '@components/Atoms/tooltip';

const IdentifierTypes: Options[] = [
    {
        label: 'Auth0 ID',
        value: 'ExternalUserID'
    },
    {
        label: 'Wallet Address',
        value: 'WalletAddress'
    }
];

export const CreateAllowListModalBodyLayout = ({
    onReadAndProcessFileHandler,
    bulkUploadFileDetails,
    onHandleDeleteUploadedFile,
    inputFileRef,
    values,
    setFieldValue,
    allowListOptions
}: CreateAllowListModalLayoutProps) => {
    const theme: MixTheme = useTheme();

    const onClickChooseFile = useCallback(() => {
        inputFileRef?.current?.click();
    }, [inputFileRef]);

    const msg = useMemo(() => {
        if (values.allowlistType === 'Manual') {
            return 'Search for wallet criteria and add pick wallet addresses to add';
        } else if (values.allowlistType === 'Dynamic') {
            return 'Set your search criteria and automatically populate your allowlist with valid wallet addresses';
        } else if (values.allowlistType === 'Bulk') {
            return 'Bulk upload Wallets, Auth0, and Email addresses';
        }
    }, [values?.allowlistType]);
    return (
        <Box>
            <TextField
                name={'name'}
                title={'Name'}
                onChange={(data: any) => {
                    setFieldValue('name', data);
                }}
                value={values.name}
                error={values.name === ''}
                helperText={values.name === '' && 'Please enter name'}
            />

            {values.allowlistType !== 'Bulk' && (
                <>
                    <TextField
                        title={'Allowlist Type'}
                        select
                        value={values.allowlistType}
                        error={values.allowlistType === ''}
                        helperText={
                            values.allowlistType === '' &&
                            'Please select allowlist type'
                        }
                        onChange={(data: any) => {
                            setFieldValue('allowlistType', data);
                        }}
                    >
                        {allowListOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Typography
                        fontWeight={'400'}
                        fontSize={'14px'}
                        color={theme.color?.textColor}
                    >
                        {msg}
                    </Typography>
                </>
            )}

            {/* { values.allowlistType === 'Bulk' && ( */}
            <TextField
                title={'Identifier Type'}
                select
                value={values.identifierType}
                error={values.identifierType === ''}
                helperText={
                    values.identifierType === '' &&
                    'Please select identifier type'
                }
                onChange={(data: any) => {
                    setFieldValue('identifierType', data);
                }}
            >
                {IdentifierTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            {/* ) } */}
            {values.allowlistType === 'Bulk' &&
                Boolean(values?.identifierType) && (
                    <>
                        <Typography
                            variant="h6"
                            sx={{ margin: '20px 0 5px 0' }}
                        >
                            Upload Allowlist
                        </Typography>

                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: theme.color?.textColor,
                                marginBottom: '10px'
                            }}
                        >
                            Not sure how to format your list before uploading?
                            Read our guide here and download our csv template!
                        </Typography>

                        <Box
                            component={'label'}
                            sx={{
                                background: theme.color?.gray,
                                border: `1px dashed ${theme.color?.textColor}`,
                                borderRadius: '4px',
                                padding: '30px',
                                justifyContent: 'center',
                                display:
                                    bulkUploadFileDetails.fileName === ''
                                        ? 'flex'
                                        : 'none',
                                cursor: 'pointer',
                                marginBottom: '15px'
                            }}
                        >
                            <Box
                                component={'span'}
                                sx={{
                                    color: theme.global?.border,
                                    fontFamily: 'inherit',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    fontSize: '14px',
                                    width: '85%',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <SaveButton
                                    title="Choose file"
                                    onClick={onClickChooseFile}
                                />
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        fontFamily: 'inherit',
                                        textAlign: 'center'
                                    }}
                                >
                                    or drag and drop file here
                                </Typography>
                            </Box>
                            <input
                                hidden
                                accept=".csv"
                                type="file"
                                onChange={onReadAndProcessFileHandler}
                                ref={inputFileRef}
                            />
                        </Box>
                        {bulkUploadFileDetails.fileName !== '' && (
                            <Box
                                component={'label'}
                                sx={{
                                    background: theme.color?.gray,
                                    border: `1px solid ${theme.global?.border}`,
                                    borderRadius: '4px',
                                    height: '70px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    marginBottom: '15px',
                                    padding: '0 20px'
                                }}
                            >
                                <Box component={'span'}>
                                    <Typography
                                        sx={{ color: theme.global?.grey }}
                                    >
                                        File Name
                                    </Typography>
                                    <Typography>
                                        {bulkUploadFileDetails.fileName}
                                    </Typography>
                                </Box>
                                <Box component={'span'}>
                                    <Typography
                                        sx={{ color: theme.global?.grey }}
                                    >
                                        File Size
                                    </Typography>
                                    <Typography>
                                        {bulkUploadFileDetails.fileSize}
                                    </Typography>
                                </Box>
                                <Box component={'span'}>
                                    <Image
                                        src={'/images/icons/remove.svg'}
                                        width={15}
                                        height={15}
                                        onClick={onHandleDeleteUploadedFile}
                                    />
                                </Box>
                            </Box>
                        )}
                        <Typography
                            variant="subtitle2"
                            sx={{ color: theme.color?.textColor }}
                        >
                            File types accepted: . CSV, and .XLS. Max file size:
                            2MB.
                        </Typography>
                    </>
                )}
        </Box>
    );
};

export const BlockUserBodyLayout = () => {
    return (
        <Box>
            <Typography variant="subtitle1">
                Once blocked, this customer will no longer have access to your
                marketplace. All on-chain and transactional information will be
                lost.
            </Typography>
        </Box>
    );
};

export const AddToAllowList = () => {
    return <Box></Box>;
};

export const UploadCompleteLayout = () => {
    return (
        <Box>
            <Typography variant="subtitle1">
                Your file has been successfully uploaded. Close this window to
                continue.
            </Typography>
        </Box>
    );
};

export const AddWalletAddressModalBodyLayout = ({
    onAddAnotherWalletAddresses,
    walletAddresses,
    onHandleDeleteWalletAddresses,
    onWalletAddressChange,
    errors
}: AddWalletAddressLayoutProps) => {
    const theme: MixTheme = useTheme();
    return (
        <Stack marginTop={2}>
            {walletAddresses?.map((item: string, index: number) => {
                return (
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center'
                        }}
                        key={index.toString()}
                    >
                        <Typography variant="subtitle2">{index + 1}</Typography>
                        <span
                            style={{
                                width: '475px'
                            }}
                        >
                            <TextField
                                title={''}
                                value={item}
                                onChange={(value) => {
                                    onWalletAddressChange(index, value);
                                }}
                                error={errors[index] ? true : false}
                                helperText={errors[index]}
                            />
                        </span>

                        <span style={{ cursor: 'pointer' }}>
                            <Image
                                src={'/images/icons/remove.svg'}
                                width={15}
                                height={15}
                                onClick={() =>
                                    onHandleDeleteWalletAddresses(index)
                                }
                            />
                        </span>
                    </Stack>
                );
            })}
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}
            >
                <Button
                    onClick={onAddAnotherWalletAddresses}
                    title={'Add Another'}
                    padding="6px 12px"
                    boxShadow="none"
                    backgroundColor={theme?.global?.hoverBackground}
                    hoverBackgroundColor={theme?.global?.hoverBackground}
                    color={theme?.global?.hover}
                    borderRadius={'4px'}
                    hoverBoxShadow={'none'}
                />
            </Stack>
        </Stack>
    );
};

export const AddAuth0IDsModalBodyLayout = ({
    addAuth0IDs,
    onAddAuth0IDsChange,
    onAddAuth0IDs,
    onDeleteAuth0IDs,
    errors
}: AddAuth0IDsLayoutProps) => {
    const theme: MixTheme = useTheme();
    const { styles } = useStyle(makeStyles);
    return (
        <Stack>
            {addAuth0IDs.map((item, index) => {
                return (
                    <Stack
                        sx={styles.addAuth0IDsContainer}
                        key={index.toString()}
                    >
                        <Typography variant="subtitle2">{index + 1}</Typography>

                        <span
                            style={{
                                width: '475px'
                            }}
                        >
                            <TextField
                                nolimit={false}
                                title={''}
                                value={item}
                                onChange={(value) =>
                                    onAddAuth0IDsChange(index, value)
                                }
                                error={errors[index] ? true : false}
                                helperText={errors[index]}
                            />
                        </span>

                        <span style={{ cursor: 'pointer' }}>
                            <Image
                                src={'/images/icons/remove.svg'}
                                width={15}
                                height={15}
                                onClick={() => onDeleteAuth0IDs(index)}
                            />
                        </span>
                    </Stack>
                );
            })}

            <Stack sx={styles.addAuth0IDsAddAnotherContainer}>
                <Button
                    onClick={onAddAuth0IDs}
                    title={'Add Another'}
                    padding="6px 12px"
                    boxShadow="none"
                    backgroundColor={theme?.global?.hoverBackground}
                    hoverBackgroundColor={theme?.global?.hoverBackground}
                    color={theme?.global?.hover}
                    borderRadius={'4px'}
                    hoverBoxShadow={'none'}
                />
            </Stack>
        </Stack>
    );
};

export const InvalidEntriesModalBodyLayout = ({
    invalidEntriesArr,
    updateEntries,
    isAuth0
}: InvalidEntriesLayoutProps) => {
    const theme: MixTheme = useTheme();

    const onClickEdit = useCallback(
        (index: number) => {
            const entries = [...invalidEntriesArr];
            entries[index].isEditing = !entries[index]?.isEditing;
            updateEntries(entries);
        },
        [invalidEntriesArr, updateEntries]
    );
    const onClickDelete = useCallback(
        (index: number) => {
            const entries = [...invalidEntriesArr];
            entries.splice(index, 1);
            updateEntries(entries);
        },
        [invalidEntriesArr, updateEntries]
    );
    const pattern = useMemo(
        () =>
            isAuth0 ? /([a-zA-Z0-9]+)\|([a-zA-Z0-9]+)/ : /^0x[a-fA-F0-9]{40}$/,
        [isAuth0]
    );

    const onChangeAuthId = useCallback(
        (index: number, val: string) => {
            const entries = [...invalidEntriesArr];
            entries[index].authId = val;
            entries[index].isValidAfterEditing = pattern.test(val);
            updateEntries(entries);
        },
        [invalidEntriesArr, updateEntries, pattern]
    );

    return (
        <Stack>
            <Stack>
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="subtitle2" sx={{ marginTop: '10px' }}>
                        The following entries are invalid. Remove or edit the
                        entries and check again or click “Continue” to upload
                        anyway.
                    </Typography>
                </Stack>
                <Table>
                    <TableHead
                        sx={{
                            border: `1px solid ${theme.global?.gray90}`,
                            marginTop: '10px',
                            borderRadius: '4px',
                            backgroundColor: theme.global?.background
                        }}
                    >
                        <TableCell>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    padding: '6px',
                                    letterSpacing: '0.01em',
                                    color: theme.color?.textColor,
                                    backgroundColor: theme.global?.background,
                                    marginLeft: '10px'
                                }}
                            >
                                {isAuth0 ? 'Auth0 ID' : 'Wallet Address'}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableHead>
                    <TableBody>
                        {invalidEntriesArr.map(
                            (item: AddAuthId, index: number) => {
                                if (!item.isValid)
                                    return (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                borderTop: 0,
                                                borderRadius: '4px',
                                                padding: '15px'
                                            }}
                                        >
                                            <TableCell>
                                                <Stack
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {!item.isValidAfterEditing && (
                                                        <Image
                                                            src={
                                                                '/images/icons/red_circle_exclamation.svg'
                                                            }
                                                            width={15}
                                                            height={15}
                                                        />
                                                    )}
                                                    {item?.isEditing ? (
                                                        <TextField
                                                            nolimit={false}
                                                            id="outlined-basic"
                                                            variant="outlined"
                                                            onChange={(
                                                                e: string
                                                            ) => {
                                                                onChangeAuthId(
                                                                    index,
                                                                    e
                                                                );
                                                            }}
                                                            sx={{
                                                                marginLeft:
                                                                    '10px'
                                                            }}
                                                            value={item.authId}
                                                        />
                                                    ) : (
                                                        <Typography
                                                            variant="subtitle2"
                                                            sx={{
                                                                marginLeft:
                                                                    '10px'
                                                            }}
                                                        >
                                                            {item.authId}
                                                        </Typography>
                                                    )}
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack
                                                    onClick={() =>
                                                        onClickEdit(index)
                                                    }
                                                >
                                                    {item.isEditing ? (
                                                        <CustomTooltip
                                                            placement="top"
                                                            message="Save"
                                                        >
                                                            <SaveIcon
                                                                sx={{
                                                                    color: theme
                                                                        ?.global
                                                                        ?.indico,
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </CustomTooltip>
                                                    ) : (
                                                        <CustomTooltip
                                                            placement="top"
                                                            message="Edit"
                                                        >
                                                            <EditIcon
                                                                sx={{
                                                                    color: theme
                                                                        ?.global
                                                                        ?.indico,
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </CustomTooltip>
                                                    )}
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack
                                                    onClick={() =>
                                                        onClickDelete(index)
                                                    }
                                                >
                                                    <CustomTooltip
                                                        placement="top"
                                                        message="Delete"
                                                    >
                                                        <DeleteIcon
                                                            sx={{
                                                                color: theme
                                                                    ?.global
                                                                    ?.indico,
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                    </CustomTooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                            }
                        )}
                    </TableBody>
                </Table>
            </Stack>
        </Stack>
    );
};

import { Environment, Space } from 'contentful-management';
import { createContext, useContext } from 'react';

export interface ContentfullType {
    space: Space;
    env: Environment;
}

const ContentFullContext = createContext<ContentfullType>(
    {} as ContentfullType
);
export default ContentFullContext;

export function useContentfull() {
    return useContext(ContentFullContext);
}

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export interface LoadingContainerProps {
    loaderTitle: string;
}

const LoadingContainer = ({ loaderTitle }: LoadingContainerProps) => {
    return (
        <Box
            sx={{
                padding: '40px'
            }}
        >
            <Typography
                component="h4"
                align="center"
                sx={{
                    fontWeight: 700,
                    fontSize: '24px',
                    padding: '24px 0 16px 0'
                }}
            >
                {loaderTitle}
            </Typography>
            <Typography align="center">
                Please do not leave or reload this window.
            </Typography>
        </Box>
    );
};
export default LoadingContainer;

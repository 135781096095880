import { gql } from '@apollo/client';
//mutation for wallets page
export const ADD_WALLET_MUTATION = gql`
    mutation CreateOrgMultisig($orgId: UUID1!, $chainId: Int!, $name: String!) {
        createOrgMultisig(orgId: $orgId, chainId: $chainId, name: $name) {
            id
        }
    }
`;
//queries for wallets page
export const GET_WALLETS_QUERY = gql`
    query getWalletsQuery(
        $orgID: UUID1!
        $networkID: UUID1
        $isDeployed: Boolean
        $limit: Int
        $Offset: Int
        $sort: WalletSortInput
    ) {
        getWallets(
            orgID: $orgID
            networkID: $networkID
            isDeployed: $isDeployed
            limit: $limit
            Offset: $Offset
            sort: $sort
        ) {
            totalCount
            data {
                id
                name
                address
                gnosisSafeURL
                isExternal
                network {
                    id
                    name
                    chainID
                    wethAddress
                    rpcURL
                }
            }
        }
    }
`;

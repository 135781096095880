import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { RouterPath } from '@constants';
import {
    collectionRoutes,
    customerRoutes,
    marketplaceRoutes,
    reportsRoutes,
    settingsRoutes,
    sitesRoutes,
    superAdminRoutes
} from './Config';

export interface SideMenuOptions {
    activeMenu: string;
    setActiveMenu: (value: string) => void;
    hideSubMenu: boolean;
}

const useSideMenu = () => {
    const router = useRouter();
    const initalMenu = useMemo(() => {
        if (superAdminRoutes.includes(router.pathname)) {
            return 'superadmin';
        }
        if (
            customerRoutes.includes(router.pathname) ||
            router.pathname.includes('/customers/')
        ) {
            return 'customers';
        }
        if (router.pathname == RouterPath.root) {
            return 'marketplace';
        }
        if (sitesRoutes.includes(router.pathname)) {
            return 'sites';
        }
        if (collectionRoutes.includes(router.pathname)) {
            return 'collections';
        }
        if (router.pathname == RouterPath.dashboard) {
            return 'dashboard';
        }
        if (marketplaceRoutes.includes(router.pathname)) {
            return 'marketplace';
        }
        if (reportsRoutes.includes(router.pathname)) {
            return 'reports';
        }
        if (settingsRoutes.includes(router.pathname)) {
            return 'settings';
        }
        if (router.pathname == RouterPath.tokengates) {
            return 'tokengates';
        }
        if (router.pathname == RouterPath.discounts) {
            return 'discountCode';
        }
        if (router.pathname == RouterPath.paymentLinks) {
            return 'paymentLinks';
        }
    }, [router.pathname]);

    const [activeMenu, setActiveMenu] = useState(initalMenu || '');

    useEffect(() => {
        setActiveMenu(initalMenu ?? '');
    }, [initalMenu]);

    const hideSubMenu = useMemo(() => {
        return (
            activeMenu === 'dashboard' ||
            activeMenu === 'home' ||
            activeMenu === 'tokengates' ||
            activeMenu === 'discountCode' ||
            activeMenu === 'paymentLinks'
        );
    }, [activeMenu]);

    const values = useMemo(() => {
        return { activeMenu, setActiveMenu, hideSubMenu } as SideMenuOptions;
    }, [activeMenu, hideSubMenu]);

    return values;
};

export { useSideMenu };

import * as React from 'react';
import { createContext, useContext, useState, useRef, useEffect } from 'react';
import { MeData } from '@interface/MeData';
import { useApolloClient } from '@apollo/client';
import { meQuery } from '@graphql/queries/query';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from './GlobalContext';
import { StorageService } from '@services/StorageService';

export interface MeContextProps {
    meData: MeData | undefined;
    loading: boolean;
}

const MeContext = createContext<MeContextProps>({} as MeContextProps);

export const useMeStore = () => {
    return useContext(MeContext);
};

const MeProvider = ({ children }: { children?: React.ReactNode }) => {
    const [meData, setMeData] = useState<MeData>();
    const initialDataBind = useRef<boolean | null>(null);
    const { isAuthenticated } = useAuth0();
    const client = useApolloClient();
    const [meLoading, setMeLoading] = useState<boolean>(false);
    const { data: globalData, setData: setGlobalData } = useGlobalState();

    // initial Fetch Me QUery
    useEffect(() => {
        if (!initialDataBind.current) {
            initialDataBind.current = true;
            setGlobalData((prev) => ({
                ...prev,
                isRefetchMe: true
            }));
        }
    }, [setGlobalData]);
    useEffect(() => {
        (async () => {
            const isWeb3Login = StorageService.web3.getValue();
            const web3Token = StorageService.token.getValue();
            if (
                globalData?.isRefetchMe &&
                ((isAuthenticated && !isWeb3Login) ||
                    (web3Token && isWeb3Login))
            ) {
                setGlobalData((prev) => ({
                    ...prev,
                    isRefetchMe: false
                }));
                setMeLoading(true);
                const meResponse = await client.query({
                    query: meQuery,
                    fetchPolicy: 'no-cache'
                });
                setMeData(meResponse?.data as MeData);
                setMeLoading(false);
            }
        })();
    }, [isAuthenticated, client, globalData.isRefetchMe, setGlobalData]);

    return (
        <MeContext.Provider value={{ meData, loading: meLoading }}>
            {children}
        </MeContext.Provider>
    );
};

export default MeProvider;

import * as React from 'react';
import {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction
} from 'react';
import { OrganizationData } from '@interface/OrganizationData';

export interface OrganizationContextProps {
    organizationData: OrganizationData | undefined;
    loading: boolean;
    organizationHandle: string | undefined;
    setOrganizationHandle: Dispatch<SetStateAction<string | undefined>>;
    setOrganizationData: Dispatch<SetStateAction<OrganizationData | undefined>>;
    setOrganizationLoading: Dispatch<SetStateAction<boolean>>;
}

const OrganizationContext = createContext<OrganizationContextProps>(
    {} as OrganizationContextProps
);

export const useOrganizationStore = () => {
    return useContext(OrganizationContext);
};

const OrganizationProvider = ({ children }: { children?: React.ReactNode }) => {
    const [organizationData, setOrganizationData] =
        useState<OrganizationData>();
    const [organizationHandle, setOrganizationHandle] = useState<string>();
    const [organizationLoading, setOrganizationLoading] =
        useState<boolean>(false);

    return (
        <OrganizationContext.Provider
            value={{
                organizationData,
                loading: organizationLoading,
                organizationHandle,
                setOrganizationHandle,
                setOrganizationLoading,
                setOrganizationData
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};

export default OrganizationProvider;

import * as React from 'react';
import { useMemo } from 'react';
import Link from 'next/link';

const TermsAndConditionContentLayout = () => {
    const containerStyle = useMemo(() => {
        return {
            fontSize: '16px',
            lineHeight: '30.4px',
            marginBottom: '20px'
        };
    }, []);

    return (
        <>
            <b style={{ ...containerStyle, marginBottom: '10px' }}>
                <i>Last Revised on February 1, 2024</i>
            </b>
            <div style={containerStyle}>
                Welcome to the Terms of Service (these “<b>Terms</b>”) for the
                website,{' '}
                <a
                    href="https://beta.mint.mojito.xyz/"
                    target="_blank"
                    style={{ color: 'black' }}
                    rel="noreferrer"
                >
                    https://beta.mint.mojito.xyz/
                </a>{' '}
                (the “<b>Website</b>”), operated by Mojito Inc (“<b>Company</b>”
                and “<b>we</b>” or “<b>us</b>”). The Website and any content,
                tools, features and functionality offered on or through our
                Website are collectively referred to as the “<b>Services</b>”.
            </div>
            <div style={containerStyle}>
                These Terms govern your access to and use of the Services.
                Please read these Terms carefully, as they include important
                information about your legal rights. By accessing and/or using
                the Services, you are agreeing to these Terms. If you do not
                understand or agree to these Terms, please do not use the
                Services.
            </div>
            <div style={containerStyle}>
                For purposes of these Terms, “<b>you</b>” and “<b>your</b>”
                means you as the user of the Services. If you use the Services
                on behalf of a company or other entity then “you” includes you
                and that entity, and you represent and warrant that (a) you are
                an authorized representative of the entity with the authority to
                bind the entity to these Terms, and (b) you agree to these Terms
                on the entity&rsquo;s behalf.
            </div>
            <div style={containerStyle}>
                You also may be subject to additional terms and conditions that
                may apply when you use affiliate or third-party services, data,
                information, applications, software, or materials (collectively,
                “<b>Third-Party Materials</b>”) Company makes available to you
                via the Services. Except to the extent that they are pre-empted
                by U.S. federal law, the laws of the state of Delaware,
                excluding conflict-of-laws principles of any jurisdiction,
                govern these Terms and any Disputes (whether contract, tort, or
                otherwise) arising out of or relating to these Terms or their
                subject matter.
            </div>
            <div
                style={{
                    border: '1px solid black',
                    padding: '5px',
                    marginBottom: '20px'
                }}
            >
                <b>
                    Section 9 contains an arbitration clause and class action
                    waiver. By agreeing to these Terms, you agree (a) to resolve
                    all disputes with us related to the Services through binding
                    individual arbitration, which means that you waive any right
                    to have those disputes decided by a judge or jury, and (b)
                    to waive your right to participate in class actions, class
                    arbitrations, or representative actions in connection with
                    your use of the Services. You have the right to opt-out of
                    arbitration as explained in Section 9.
                </b>
            </div>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>
                    1.&nbsp;&nbsp;THE SERVICES AND ELIGIBILITY TO USE THE
                    SERVICES
                </b>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>1.1&nbsp;&nbsp;</span>
                <div>
                    <u>Digital Collectibless</u>. The Services allow you to
                    create, mint, sell, claim, purchase or redeem Digital
                    Collectible(s) (individually a “<b>Collectible</b>” and,
                    together, the “<b>Collectibles</b>”) via a buy-now, claim
                    auction or other digital creation and sale mechanism, whose
                    ownership is recorded on the Ethereum blockchain or such
                    other blockchain that we may support from time to time. The
                    Collectibles are technologies known singularly as a “
                    <b>NFT</b>”, meaning a blockchain-tracked, non-fungible
                    token. The Collectibles are associated with metadata,
                    artwork, graphics, images, designs, logos, drawings,
                    photographs, texts taglines or video or audio recordings
                    which for the avoidance of doubt constitute NFT Media (as
                    defined below in Section 5.1). NFT Media associated with
                    Collectibles is created by artists, yourself or related
                    third parties (collectively, “<b>Creators</b>”). All right,
                    title and interest in the NFT Media associated with each
                    Collectible (including all copyrights, trademark rights and
                    all other intellectual property rights) is owned by Creator
                    and/or their licensors. Ownership of a Collectible, is
                    limited solely to ownership of such Collectible and does not
                    extend to the NFT Media and may be subject to a license in
                    the NFT Media as specified in Section 5.1(b). “<b>Own</b>”
                    for purposes of the foregoing, means a Collectible that was
                    created, minted, sold, purchased or otherwise rightfully
                    acquired from a legitimate source, where proof of such
                    ownership is recorded on the relevant blockchain. The
                    purchase of a Collectible may also allow the owner to access
                    certain other features that may be made available from time
                    to time (collectively, such access and features, the “
                    <b>Additional Features</b>”). For clarity, Company&rsquo;s
                    or your inclusion of any Additional Features does not imply
                    or warrant that they will be available at any other time,
                    and Company may cease providing such Additional Features at
                    any time in their sole discretion. We may use a third-party
                    payment services provider, KYC provider, KYT Provider,
                    anti-fraud provider and sales tax solution, as applicable.
                    You are solely responsible for any actions taken on the
                    Website or any third-party websites and we shall not be
                    liable for any loss resulting in whole or in part from your
                    use of such provider.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>1.2&nbsp;&nbsp;</span>
                <div>
                    <u>Eligibility</u>. You must be at least 13 years of age or
                    older to use the Services. Children under the age of 13 are
                    not permitted to use the Services. Minors under the age of
                    majority in their jurisdiction but that are at least 13
                    years of age are only permitted to use the Services if the
                    minor&rsquo;s parent or guardian accepts these Terms on the
                    minor&rsquo;s behalf prior to use of the Services. By using
                    the Services, you represent and warrant that you meet this
                    requirement. In furtherance of our policy of not collecting
                    personal information from persons under the age of 13, Users
                    are not allowed to give Company the personal information of
                    any persons under the age of 13 for any reason.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>1.3&nbsp;&nbsp;</span>
                <div>
                    <u>Wallets</u>. Transactions for Collectibles initiated
                    through our Services may require you or third parties to use
                    multi-signature wallets or third party non-custodial digital
                    wallets (“<b>Wallets</b>”), such as MetaMask. By using our
                    Services you agree that you are governed by the terms or
                    service and privacy policies of those Wallets that you
                    choose to use. You are solely responsible for keeping your
                    Wallet and any private keys necessary to decrypt your Wallet
                    secure and you should never share your Wallet seed phrase or
                    private keys with anyone else via the Services. We have no
                    ability to help you access or recover your private keys for
                    your Wallet, so please keep them in a safe place. You
                    acknowledge and agree that for any Collectibles purchased
                    with a credit card and transferred to a semi-custodial
                    wallet may be subject to a holding period. By using such
                    Wallets to conduct such transactions via the Services, you
                    agree that you are governed by the terms of service and
                    privacy policy for the applicable Wallets, and that the
                    Company has no responsibility, liability or responsibility
                    to you in any way arising from your use of such third-party
                    Wallets, including for any security failures or other errors
                    or failures of such Wallets.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>1.4&nbsp;&nbsp;</span>
                <div>
                    <u>Secondary Marketplaces and Third-Party Platforms</u>. You
                    or others may be able to buy, sell, trade, and transact
                    Collectibles on secondary marketplaces that we or our
                    affiliates may operate, or via the secondary marketplaces of
                    third-party platforms. We may not be able to control the
                    actions of secondary marketplaces of third-party platforms,
                    and make no promises or guarantees of any kind regarding
                    such third-party marketplaces. You also acknowledge and
                    agree that we may implement a transaction fee on any
                    secondary sale of Collectibles or that a royalty may be
                    required by the terms of such Collectible and such
                    transaction fees and royalties may be incorporated into the
                    smart contracts for such Collectibles or in our agreements
                    with such marketplaces.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '20px'
                }}
            >
                <span>1.5&nbsp;&nbsp;</span>
                <div>
                    <u>Limitations on Gas Usage</u>. Please note that we have
                    the right to limit access to the Services or the amount of
                    gas or other expenses incurred as part of your use of the
                    Services at our sole discretion and you shall be liable and
                    reimburse us for any expenses incurred as invoiced by us in
                    a cadence we choose. Such limitations may be changed by us
                    from time to time.
                </div>
            </div>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>2.&nbsp;&nbsp;USER ACCOUNTS</b>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '0'
                }}
            >
                <span>2.1&nbsp;&nbsp;</span>
                <div>
                    <u>Creating and Safeguarding your Account</u>. To use
                    certain Services, you may need to create an account (“
                    <b>Account</b>”). You can create an Account by linking your
                    Wallet address to the Website, and providing an email
                    address and password that will be associated with your
                    Wallet address. You agree to provide us with accurate,
                    complete, and updated information for your Account. You are
                    solely responsible for any activity on your Account and for
                    maintaining the confidentiality and security of your
                    password and Account. Company shall not be liable for any
                    acts or omissions by you in connection with your Account.
                    You must immediately notify us at{' '}
                    <Link href={'mailto:operations@getmojito.com'}>
                        operations@getmojito.com
                    </Link>{' '}
                    if you know or have any reason to suspect that your Account
                    or password have been stolen, misappropriated or otherwise
                    compromised, or in case of any actual or suspected
                    unauthorized use of your Account. Company may require you to
                    provide additional information and documents regarding your
                    use of the Services, including at the request of any
                    competent authority or in case of application of any
                    applicable law or regulation, including laws related to
                    anti-money laundering, or for counteracting financing of
                    terrorism. Company may also require you to provide
                    additional information and documents in cases where it has
                    reasons to believe that:
                </div>
            </div>
            <ol
                style={{
                    listStyleType: 'lower-alpha',
                    margin: '0px',
                    marginTop: '12px'
                }}
            >
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    Your Account or your Wallet is being used for illegal money
                    laundering or for any other illegal activity;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        marginTop: '12px',
                        lineHeight: '30.4px'
                    }}
                >
                    You have concealed or reported false identification
                    information and other details; or
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        marginTop: '12px',
                        marginBottom: '20px',
                        lineHeight: '30.4px'
                    }}
                >
                    Transactions effected via your use of the Services were made
                    in breach of these Terms.
                </li>
            </ol>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>3.&nbsp;&nbsp;PURCHASES AND SALES VIA THE SERVICES</b>
            </div>
            <div style={{ ...containerStyle, display: 'flex' }}>
                <span>3.1&nbsp;&nbsp;</span>
                <div>
                    <u>Purchases of Offerings</u>. The Services may permit you
                    to sell and/or purchase Collectibles and certain other
                    products or services created by you or others through the
                    Services (collectively, the “<b>Offerings</b>”). All sales
                    or purchases of Offerings via the Services are
                    non-refundable. You acknowledge and agree that all wiring,
                    bank account or other payment information provided with
                    regards to the Offerings is accurate, current and complete.
                    All fees and charges are payable in accordance with payment
                    terms in effect at the time the fee or the charge becomes
                    payable and you and any applicable third parties shall be
                    responsible for all related taxes. All purchases and sales
                    must be made via the cryptocurrency, fiat or other payment
                    currency designated by the Services. In certain cases, your
                    transaction may not be successful due to an error with the
                    blockchain or the Wallet. We accept no responsibility or
                    liability to you for any such failed transactions, or any
                    transaction or gas fees or other fees or taxes that may be
                    incurred by you or third parties in connection with the
                    Services. We are not a cryptocurrency or fiat payment
                    processor. We use third party payment processor services to
                    enable you to purchase and sell Collectibles and certain
                    goods or services. You are solely responsible for any
                    actions taken on such website and we shall not be liable for
                    any loss resulting in whole or in part from such provider.
                </div>
            </div>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>4.&nbsp;&nbsp;LOCATION OF OUR PRIVACY POLICY</b>
            </div>
            <div style={{ ...containerStyle, display: 'flex' }}>
                <span>4.1&nbsp;&nbsp;</span>
                <div>
                    <u>Privacy Policy</u>. Our Privacy Policy describes how we
                    handle the information you provide to us when you use the
                    Services. For an explanation of our privacy practices,
                    please visit our Privacy Policy located at{' '}
                    <a
                        href="https://www.getmojito.com/privacy-and-cookies-policy"
                        target="_blank"
                        style={{ color: 'black' }}
                        rel="noreferrer"
                    >
                        https://www.getmojito.com/privacy-and-cookies-policy
                    </a>
                    .
                </div>
            </div>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>5.&nbsp;&nbsp;RIGHTS WE GRANT YOU</b>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '0'
                }}
            >
                <span>5.1&nbsp;&nbsp;</span>
                <u>License Grants</u>.
            </div>
            <ol style={{ listStyleType: 'lower-alpha', margin: '12px 0' }}>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    <u>Services License</u>. Subject to your compliance with
                    these Terms, Company hereby grants to you, a personal,
                    worldwide, royalty-free, non-assignable, non-sublicensable,
                    non-transferrable (except in the case of a valid and lawful
                    transfer by you to another purchaser), and non-exclusive
                    license to use the software provided to you as part of the
                    Services, provided that the license for any digital media,
                    artwork, designs, or content that is associated with any
                    Collectible shall be solely owned by its owner prior to
                    using the Services and any license or other rights
                    associated with such artwork must be granted by such owner
                    and we take on no responsibility related to any transfer or
                    assignment or license of any such rights. This license has
                    the sole purpose of enabling you to use and enjoy the
                    benefit of the Services as provided by us, in the manner
                    permitted by these Terms and subject to the use restrictions
                    described below. Your access and use of the Services may be
                    interrupted from time to time for any of several reasons,
                    including, without limitation, the malfunction of equipment,
                    periodic updating, maintenance, or repair of the Services or
                    other actions that Company in its sole discretion may elect
                    to take.
                </li>
            </ol>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>5.2&nbsp;&nbsp;</span>
                <div>
                    <u>Restrictions On Your Use of the Services</u>. You may not
                    do any of the following, unless applicable laws or
                    regulations prohibit these restrictions, or you have our
                    written permission to do so:
                </div>
            </div>
            <ol
                style={{
                    listStyleType: 'inherit',
                    margin: '12px 0',
                    marginBottom: '20px'
                }}
            >
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    duplicate, decompile, reverse engineer, disassemble or
                    decode the Services (including any underlying idea or
                    algorithm), or attempt to do any of the same;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    use, reproduce or remove any copyright, trademark, service
                    mark, trade name, slogan, logo, image, or other proprietary
                    notation displayed on or through the Services;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    use automation software (bots), hacks, modifications (mods)
                    or any other unauthorized third-party software designed to
                    modify the Services or impersonate a real-person or to
                    engage in multiple transactions via the Services;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    access or use the Services in any manner that could disable,
                    overburden, damage, disrupt, or impair the Services or
                    interfere with any other party&rsquo;s access to or use of
                    the Services or use any device, software or routine that
                    causes the same;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    attempt to gain unauthorized access to, interfere with,
                    damage or disrupt the Services, accounts registered to other
                    users, or the computer systems or networks connected to the
                    Services;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    circumvent, remove, alter, deactivate, degrade, or thwart
                    any technological measure or content protections of the
                    Services;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    use any robot, spider, crawlers, or other automatic device,
                    process, software, or queries that intercepts, “mines”,
                    scrapes, or otherwise accesses the Services to monitor,
                    extract, copy, or collect information or date from or
                    through the Services, or engage in any manual process to do
                    the same;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    introduce any viruses, trojan horses, worms, logic bombs, or
                    other materials that are malicious or technologically
                    harmful into our systems;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    use the Services for illegal, harassing, unethical, or
                    disrupting purposes;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    violate any applicable law or regulation in connection with
                    your access to or use of the Services; or
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    access or use the Services in any way not expressly
                    permitted by these Terms.
                </li>
            </ol>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>6.&nbsp;&nbsp;OWNERSHIP AND CONTENT</b>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>6.1&nbsp;&nbsp;</span>
                <div>
                    <u>Ownership of the Services</u>. The Services, including
                    their “look and feel” (e.g., text, graphics, images, logos),
                    proprietary content, information and other materials, are
                    protected under copyright, trademark and other intellectual
                    property laws. You agree that Company and/or their licensors
                    own all right, title and interest in and to the Services and
                    you agree not to take any action(s) inconsistent with such
                    ownership interests. We and our licensors reserve all rights
                    in connection with the Services and its content (other than
                    your Content), including the right to create derivative
                    works.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>6.2&nbsp;&nbsp;</span>
                <div>
                    <u>Ownership of Trademarks</u>. Company&rsquo;s name,
                    trademarks, logo, and all related names, logos, product and
                    service names, designs and slogans are trademarks of Company
                    or their affiliates, service providers, creators or
                    licensors. Other names, logos, product and service names,
                    designs, and slogans that appear on the Services are the
                    property of their respective owners, who may or may not be
                    affiliated with, connected to, or sponsored by Company.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>6.3&nbsp;&nbsp;</span>
                <div>
                    <u>Your Content License Grant</u>. In connection with your
                    use of the Services, you may be able to post, upload, or
                    submit content to be made available through the Services (“
                    <b>Your Content</b>”). In order to operate the Service,
                    Company must obtain from you certain license rights in Your
                    Content so that actions we take in operating the Service are
                    not considered legal violations. Accordingly, by using the
                    Service and uploading Your Content, you grant us a license
                    to access, use, host, cache, store, reproduce, transmit,
                    display, publish, distribute, and modify (for technical
                    purposes, e.g., making sure content is viewable on
                    smartphones as well as computers and other devices) Your
                    Content but solely as required to be able to operate and
                    provide the Services. You agree that these rights and
                    licenses are royalty free, transferable, sub-licensable,
                    worldwide, and irrevocable (for so long as Your Content is
                    stored with us), and include a right for us to make Your
                    Content available to, and pass these rights along to, others
                    with whom we have contractual relationships related to the
                    provision of the Services, solely for the purpose of
                    providing such Services, and to otherwise permit access to
                    or disclose Your Content to third parties if we determine
                    such access is necessary to comply with our legal
                    obligations. As part of the foregoing license grant you
                    agree that the other users of the Services shall have the
                    right to comment on and/or tag Your Content and/or to use,
                    publish, display, modify, or include a copy of Your Content
                    as part of their own use of the Services; except that the
                    foregoing shall not apply to any of Your Content that you
                    post privately for non-public display on the Services. By
                    posting or submitting Your Content through the Services, you
                    represent and warrant that you have obtained, all rights,
                    licenses, consents, permissions, power, and/or authority
                    necessary to grant the rights granted herein for Your
                    Content. You agree that Your Content will not contain
                    material subject to copyright or other proprietary rights,
                    unless you have the necessary permission or are otherwise
                    legally entitled to post the material and to grant us the
                    license described above.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '0'
                }}
            >
                <span>6.4&nbsp;&nbsp;</span>
                <div>
                    <span>
                        <u>Notice of Infringement-DMA Policy</u>
                    </span>
                    <div>
                        If you believe that any text, graphics, photos, audio,
                        videos or other materials or works uploaded, downloaded
                        or appearing on the Services have been copied in a way
                        that constitutes copyright infringement, you may submit
                        a notification to our copyright agent in accordance with
                        17 USC 512(c) of the Digital Millennium Copyright Act
                        (the “<b>DMCA</b>”), by providing the following
                        information in writing:
                    </div>
                </div>
            </div>
            <ol
                style={{
                    listStyleType: 'lower-alpha',
                    margin: '12px 0px 20px'
                }}
            >
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    identification of the copyrighted work that is claimed to be
                    infringed;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    identification of the allegedly infringing material that is
                    requested to be removed, including a description of where it
                    is located on the Service;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    information for our copyright agent to contact you, such as
                    an address, telephone number, and e-mail address;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    a statement that you have a good faith belief that the
                    identified, allegedly infringing use is not authorized by
                    the copyright owners, its agent or the law;
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    a statement that the information above is accurate, and
                    under penalty of perjury, that you are the copyright owner
                    or the authorized person to act on behalf of the copyright
                    owner; and
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    the physical or electronic signature of a person authorized
                    to act on behalf of the owner of the copyright or of an
                    exclusive right that is allegedly infringed.
                </li>
            </ol>
            <div style={{ lineHeight: '30.4px', marginBottom: '10px' }}>
                Notices of copyright infringement claims should be sent by mail
                to 200 Continental Drive Suite 401, Newark, DE 19713 Attn:
                General Counsel or by e-mail to{' '}
                <Link href={'mailto:operations@getmojito.com'}>
                    operations@getmojito.com
                </Link>
                . It is our policy, in appropriate circumstances and at our
                discretion, to disable or terminate the use of Services via the
                Accounts or Wallets connected to the Accounts of users who
                repeatedly infringe copyrights or intellectual property rights
                of others.
            </div>
            <div style={{ lineHeight: '30.4px', marginBottom: '20px' }}>
                Any user of the Services who has uploaded or posted materials
                identified as infringing as described above may supply a
                counter-notification pursuant to sections 512(g)(2) and (3) of
                the DMCA. When we receive a counter-notification, we may
                reinstate the posts or material in question, in our sole
                discretion. To file a counter-notification with us, you must
                provide a written communication (by fax or regular mail or by
                email) that sets forth all of the items required by sections
                512(g)(2) and (3) of the DMCA. Please note that you will be
                liable for damages if you materially misrepresent that content
                or an activity is not infringing the copyrights of others.
            </div>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>7.&nbsp;&nbsp;THIRD PARTY SERVICES AND MATERIALS</b>
            </div>
            <div style={{ ...containerStyle, display: 'flex' }}>
                <span>7.1&nbsp;&nbsp;</span>
                <div>
                    <u>Use of Third-Party Materials in the Services</u>. Certain
                    Services may display, include or make available content,
                    data, information, applications or materials from third
                    parties (“<b>Third Party Materials</b>”) or provide links to
                    certain third-party websites (such as Twitter, Discord, and
                    secondary NFT marketplaces). By using the Services, you
                    acknowledge and agree that Company is not responsible for
                    examining or evaluating the content, accuracy, completeness,
                    availability, timeliness, validity, copyright compliance,
                    legality, decency, quality or any other aspect of such
                    Third-Party Materials or websites. We do not warrant or
                    endorse and do not assume and will not have any liability or
                    responsibility to you or any other person for any
                    third-party services, Third Party Materials or third-party
                    websites, or for any other materials, products, or services
                    of third parties. Third Party Materials and links to other
                    websites are provided solely as a convenience to you.
                </div>
            </div>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>
                    8.&nbsp;&nbsp;DISCLAIMERS, LIMITATIONS OF LIABILITY, AND
                    INDEMNIFICATION
                </b>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>8.1&nbsp;&nbsp;</span>
                <div>
                    <u>Disclaimers</u>. Your access to and use of the Services
                    are at your own risk. You understand and agree that the
                    Services are provided to you on an “AS IS” and “AS
                    AVAILABLE” basis. Without limiting the foregoing, to the
                    maximum extent permitted under applicable law, Company,
                    their parents, affiliates, related companies, officers,
                    directors, employees, agents, representatives, partners,
                    service providers, creators and licensors (the “
                    <b>the Company Entities</b>”) DISCLAIM ALL WARRANTIES AND
                    CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. The
                    Company Entities make no warranty or representation and
                    disclaim all responsibility and liability for: (a) the
                    completeness, accuracy, availability, timeliness, security
                    or reliability of the Services; (b) the operation or
                    compatibility with any other application or any particular
                    system or device; and (c) whether the Services will meet
                    your requirements or be available on an uninterrupted,
                    secure or error-free basis; and (d) the deletion of, or the
                    failure to store or transmit, Your Content and other
                    communications maintained by the Services. No advice or
                    information, whether oral or written, obtained from the
                    Company Entities or through the Services, will create any
                    warranty or representation not expressly made herein.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>8.2&nbsp;&nbsp;</span>
                <div>
                    <u>Limitations of Liability</u>. TO THE EXTENT NOT
                    PROHIBITED BY LAW, YOU UNDERSTAND AND AGREE THAT IN NO EVENT
                    WILL THE COMPANY ENTITIES, SUBSIDIARIES, AFFILIATES AND
                    LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTIES (A) FOR
                    DAMAGES OF ANY KIND, INCLUDING DIRECT, INDIRECT, SPECIAL,
                    EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
                    (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
                    GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS, BUSINESS
                    INTERRUPTION OR ANY OTHER DAMAGES OR LOSSES (WHETHER
                    INCURRED DIRECTLY OR INDIRECTLY), ARISING OUT OF OR RELATED
                    TO YOUR USE OR INABILITY TO USE THE SERVICES), HOWEVER
                    CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER UNDER
                    THESE TERMS OR OTHERWISE ARISING IN ANY WAY IN CONNECTION
                    WITH THE SERVICES OR THESE TERMS AND WHETHER IN CONTRACT,
                    STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE)
                    EVEN IF THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGE; OR (B) FOR ANY OTHER CLAIM,
                    DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUT
                    OF OR IN CONNECTION WITH THESE TERMS OR THE DELIVERY, USE OR
                    PERFORMANCE OF THE SERVICES. SOME JURISDICTIONS DO NOT ALLOW
                    THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL
                    DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION MAY NOT APPLY
                    TO YOU. THE COMPANY ENTITIES ARE NOT LIABLE FOR YOUR CONTENT
                    POSTED ON THE SERVICES. THE COMPANY ENTITIES&rsquo; TOTAL
                    LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT
                    EXCEED THE GREATER OF THE AMOUNT OF ONE HUNDRED DOLLARS
                    ($100.00), OR THE AMOUNT YOU PAID THE COMPANY ENTITIES, IF
                    ANY, IN THE PAST SIX (6) MONTHS FOR THE SERVICES (OR
                    OFFERINGS PURCHASED ON THE SERVICES) GIVING RISE TO THE
                    CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE
                    ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>8.3&nbsp;&nbsp;</span>
                <div>
                    <span style={{ marginBottom: '10px', display: 'block' }}>
                        YOU ALSO UNDERSTAND AND AGREE THAT THE COMPANY ENTITIES,
                        OUR PARENT, SUBSIDIARIES, AFFILIATES AND LICENSORS WILL
                        NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY
                        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
                        EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED
                        AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT
                        LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED
                        DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS
                        REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF
                        SUBSTITUTE GOODS OR SERVICES, DIMINUTION OF VALUE OR ANY
                        OTHER INTANGIBLE LOSS, EVEN IF THE COMPANY ENTITIES HAVE
                        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </span>
                    <span style={{ marginBottom: '10px', display: 'block' }}>
                        &nbsp;&nbsp;&nbsp;THIS LIMITATION OF LIABILITY SHALL
                        SURVIVE THE TERMINATION OR EXPIRATION OF THESE TERMS.
                    </span>
                    <span style={{ marginBottom: '10px' }}>
                        &nbsp;&nbsp;&nbsp;IF YOU ARE A USER FROM NEW JERSEY, THE
                        FOREGOING SECTIONS TITLED “DISCLAIMERS” AND “LIMITATION
                        OF LIABILITY” ARE INTENDED TO BE ONLY AS BROAD AS IS
                        PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF
                        ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID
                        UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE
                        INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE VALIDITY
                        OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
                    </span>
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '0'
                }}
            >
                <span>8.4&nbsp;&nbsp;</span>
                <div>
                    <u>Assumption of Risks</u>.
                </div>
            </div>
            <ol
                style={{
                    listStyleType: 'lower-alpha',
                    margin: '0px',
                    marginTop: '10px',
                    marginBottom: '10px'
                }}
            >
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        lineHeight: '30.4px'
                    }}
                >
                    You acknowledge and agree that there are risks associated
                    with purchasing and holding NFTs and using blockchain
                    technology. These including, but are not limited to, risk of
                    losing access to NFT due to loss of private key(s),
                    custodial error or purchaser error, risk of mining or
                    blockchain attacks, risk of hacking and security weaknesses,
                    risk of unfavourable regulatory intervention in one or more
                    jurisdictions, risks related to token taxation, risk of
                    personal information disclosure, risk of uninsured losses,
                    unanticipated risks, and volatility risks.
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        marginTop: '12px',
                        lineHeight: '30.4px'
                    }}
                >
                    The prices of collectible blockchain assets are extremely
                    volatile and subjective and collectible blockchain assets
                    have no inherent or intrinsic value. Each Collectible has no
                    inherent or intrinsic value. You acknowledge and affirm that
                    you are collecting, trading, or purchasing Collectibles for
                    purposes of acquiring digital collectibles for your personal
                    use and enjoyment, and not for any investment or speculative
                    purposes. Any economic benefit that may be derived from
                    appreciation in the value of a Collectible is incidental to
                    obtaining it for its collectible purpose. You agree that
                    Collectibles are not to be used as a substitute for currency
                    or medium of exchange, resale, or redistribution and that
                    you are not acquiring any equity or other ownership or
                    revenue sharing interest in Company Entities, their
                    affiliates, or any brand as a result of your acquisition of
                    Collectibles.
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        marginTop: '12px',
                        lineHeight: '30.4px'
                    }}
                >
                    We will use commercially reasonable efforts to deploy secure
                    and functional smart contracts underlying Collectibles
                    minted directly by us. However, we will not be liable or
                    responsible to you for any failure in the intended function
                    of such smart contracts, or any bugs, viruses, exploits,
                    logic gaps, or malicious code which may be incorporated into
                    any such smart contracts, or which could be used to commit
                    fraud or otherwise cause harm. You acknowledge that you have
                    obtained sufficient information to make an informed decision
                    to purchase a Collectible, including carefully reviewing the
                    code of the smart contract and the Collectible, and fully
                    understand and accept the functions of the same. Further, we
                    will not be liable for any failure or removal of the storage
                    system used to store any NFT Media that is linked to or
                    associated with Collectibles, or for any failures in the
                    underlying blockchain on which the Collectibles ownership
                    may be recorded.
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        marginTop: '12px',
                        lineHeight: '30.4px'
                    }}
                >
                    The regulatory regime governing blockchain technologies,
                    cryptocurrencies, and tokens is uncertain, and new
                    regulations or policies may materially adversely affect the
                    potential utility or value of your Collectible(s). Upgrades
                    to any blockchain network or hard forks in such networks, or
                    a change in how transactions are confirmed on such
                    blockchain networks may have unintended, adverse effects on
                    all blockchain, including any that are related to your
                    Collectible(s).
                </li>
                <li
                    style={{
                        marginLeft: '20px',
                        paddingLeft: '8px',
                        marginTop: '12px',
                        lineHeight: '30.4px'
                    }}
                >
                    Any purchase or sale you make, accept, or facilitate outside
                    of the Website of any Collectible(s) will be entirely at
                    your risk. We do not authorize, control, or endorse
                    purchases or sales of Collectibles outside of the Website.
                    We expressly deny and disclaim any liability to you and deny
                    any obligation to indemnify you or hold you harmless for any
                    losses you may incur by transacting, or facilitating
                    transactions, in Collectibles outside of the Website.
                </li>
            </ol>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    lineHeight: '30.4px'
                }}
            >
                <span>8.5&nbsp;&nbsp;</span>
                <div>
                    <u>Indemnification</u>. By entering into these Terms and
                    accessing or using the Services, you agree that you shall
                    defend, indemnify and hold the Company Entities harmless
                    from and against any and all claims, costs, damages, losses,
                    liabilities and expenses (including attorneys&rsquo; fees
                    and costs) incurred by the Company Entities arising out of
                    or in connection with: (a) your violation or breach of any
                    term of these Terms or any applicable law or regulation; (b)
                    your violation of any rights of any third party; (c) your
                    access to or use of the Services; (d) Your Content, or (e)
                    your negligence or wilful misconduct.
                </div>
            </div>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>9.&nbsp;&nbsp;ARBITRATION AND CLASS ACTION WAIVER</b>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>9.1&nbsp;&nbsp;</span>
                <div>
                    <u>Informal Process First</u>. You agree that in the event
                    of any dispute between you and the Company Entities, you
                    will first contact Company and make a good faith sustained
                    effort to resolve the dispute before resorting to more
                    formal means of resolution, including without limitation,
                    any court action.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>9.2&nbsp;&nbsp;</span>
                <div>
                    <u>Informal Process First</u>. After the informal dispute
                    resolution process, any remaining dispute, controversy, or
                    claim (collectively, “<b>Claim</b>”) relating in any way to
                    your use of Company&rsquo;s services and/or products,
                    including the Services, will be resolved by arbitration,
                    including threshold questions of arbitrability of the Claim.
                    You and Company agree that any Claim will be settled by
                    final and binding arbitration, using the English language,
                    administered by JAMS under its Comprehensive Arbitration
                    Rules and Procedures (the “<b>JAMS Rules</b>”) then in
                    effect (those rules are deemed to be incorporated by
                    reference into this section, and as of the date of these
                    Terms). Arbitration will be handled by a sole arbitrator in
                    accordance with the JAMS Rules. Any arbitration will be
                    conducted in the English language and the seat of the
                    arbitration shall be New York City, New York.
                    Notwithstanding the foregoing, you and Company will have the
                    right to bring an action in a court of proper jurisdiction
                    for injunctive or other equitable or conservatory relief,
                    pending a final decision by the arbitrator. You may instead
                    assert your claim in “small claims” court, but only if your
                    claim qualifies, your claim remains in such court and your
                    claim remains on an individual, non-representative and
                    non-class basis.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>9.3&nbsp;&nbsp;</span>
                <div>
                    <u>Waiver of Class Actions and Class Arbitrations</u>. You
                    and Company agree that each party may bring Claims against
                    the other party only in an individual capacity, and not as a
                    plaintiff or class member in any purported class or
                    representative proceeding, including without limitation
                    federal or state class actions, or class arbitrations.
                    Accordingly, under the arbitration procedures outlined in
                    this section, an arbitrator shall not combine or consolidate
                    more than one party&rsquo;s claims without the written
                    consent of all affected parties to an arbitration
                    proceeding. Without limiting the generality of the
                    foregoing, you and Company agree that no dispute shall
                    proceed by way of class arbitration without the written
                    consent of all affected parties.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>9.4&nbsp;&nbsp;</span>
                <div>
                    <u>Costs of Arbitration</u>. Payment for any and all
                    reasonable JAMS filing, administrative and arbitrator fees
                    will be in accordance with the JAMS Rules. You are
                    responsible for all additional costs that you incur in the
                    arbitration, including without limitation, fees for
                    attorneys or expert witnesses.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '20px'
                }}
            >
                <span>9.5&nbsp;&nbsp;</span>
                <div>
                    <u>Opt-Out</u>. You have the right to opt-out and not be
                    bound by the arbitration provisions set forth in these Terms
                    by sending written notice of your decision to opt-out to{' '}
                    <Link href={'mailto:operations@getmojito.com'}>
                        operations@getmojito.com
                    </Link>
                    . The notice must be sent to Company within thirty (30) days
                    of your registering to use the Services or agreeing to these
                    Terms, otherwise you shall be bound to arbitrate disputes in
                    accordance with these Terms. If you opt-out of these
                    arbitration provisions, Company also will not be bound by
                    them.
                </div>
            </div>
            <div style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
                <b>10.&nbsp;&nbsp;ADDITIONAL PROVISIONS</b>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.1&nbsp;&nbsp;</span>
                <div>
                    <u>Updating These Terms</u>. We may modify these Terms from
                    time to time in which case we will update the “Last Revised”
                    date at the top of these Terms. If we make changes that are
                    material, we will use reasonable efforts to attempt to
                    notify you, such as by e-mail and/or by placing a prominent
                    notice on the first page of the Website. However, it is your
                    sole responsibility to review these Terms from time to time
                    to view any such changes. The updated Terms will be
                    effective as of the time of posting, or such later date as
                    may be specified in the updated Terms. Your continued access
                    or use of the Services after the modifications have become
                    effective will be deemed your acceptance of the modified
                    Terms.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.2&nbsp;&nbsp;</span>
                <div>
                    <u>Termination of License and Your Account</u>. If you
                    breach any of the provisions of these Terms, all licenses
                    granted by Company will terminate automatically.
                    Additionally, Company may suspend, disable, or delete your
                    Account and/or the Services (or any part of the foregoing)
                    with or without notice, for any or no reason. If Company
                    deletes your Account or restricts access for any suspected
                    breach of these Terms by you, you are prohibited from
                    re-registering for the Services under a different name. In
                    the event of Account deletion or restriction for any reason,
                    or termination of your access to the Services for any
                    reason, Company may, but is not obligated to, delete any of
                    Your Content. All sections which by their nature should
                    survive the termination of these Terms shall continue in
                    full force and effect subsequent to and notwithstanding any
                    termination of this Agreement by Company or you. Termination
                    will not limit any of Company&rsquo;s other rights or
                    remedies at law or in equity.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.3&nbsp;&nbsp;</span>
                <div>
                    <u>Injunctive Relief</u>. You agree that a breach of these
                    Terms will cause irreparable injury to Company for which
                    monetary damages would not be an adequate remedy and Company
                    shall be entitled to equitable relief in addition to any
                    remedies it may have hereunder or at law without a bond,
                    other security or proof of damages.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.4&nbsp;&nbsp;</span>
                <div>
                    <u>California Residents</u>. If you are a California
                    resident, in accordance with Cal. Civ. Code § 1789.3, you
                    may report complaints to the Complaint Assistance Unit of
                    the Division of Consumer Services of the California
                    Department of Consumer Affairs by contacting them in writing
                    at 1625 North Market Blvd., Suite N 112 Sacramento, CA
                    95834, or by telephone at (800) 952-5210.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.5&nbsp;&nbsp;</span>
                <div>
                    <u>No Professional Advice</u>. Any information provided by
                    or on behalf of us through the Website (including these
                    Terms) is for informational purposes only and should not be
                    construed as professional, accounting, tax or legal advice.
                    You should not take or refrain from taking any action in
                    reliance on any information contained in these Terms or on
                    any information provided by or on behalf of us through the
                    Website. Before you make any decision to purchase or sell
                    any NFT, you should consider seeking independent
                    professional advice from persons licensed and qualified in
                    the area for which such advice would be appropriate.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.6&nbsp;&nbsp;</span>
                <div>
                    <u>No Waivers</u>. No failure or delay on our part or on the
                    part of us in the exercise of any power, right, privilege or
                    remedy under these Terms shall operate as a waiver of such
                    power, right, privilege or remedy; and no single or partial
                    exercise of any such power, right, privilege or remedy shall
                    preclude any other or further exercise thereof or of any
                    other power, right, privilege or remedy. We shall not be
                    deemed to have waived any claim arising out of these Terms,
                    or any power, right, privilege or remedy under these Terms,
                    unless the waiver of such claim, power, right, privilege or
                    remedy is expressly set forth in a written instrument duly
                    executed and delivered on behalf of us, and any such waiver
                    shall not be applicable or have any effect except in the
                    specific instance in which it is given.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.7&nbsp;&nbsp;</span>
                <div>
                    <u>Certain Restrictions</u>. We are not permitted to engage
                    in any transactions with any person, entity, or country
                    prohibited by any applicable export control and sanctions
                    laws and regulations of the United States and any other
                    applicable governmental authority, including without
                    limitation, the U.S. Export Administration Regulations and
                    U.S. sanctions regulations (“Export Control and Sanctions
                    Laws”), including, without limitation, the prohibition
                    against transactions with: (i) a national or resident of any
                    country subject to U.S. sanctions or similar restrictions
                    (currently, Cuba, Iran, Syria, North Korea, and the region
                    Crimea), or (ii) anyone on the U.S. Treasury
                    Department&rsquo;s list of Specially Designated Nationals or
                    other sanctions lists. You will not be permitted to receive
                    an NFT from us if we learn that such action would be in
                    breach of Export Control and Sanctions Laws.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.8&nbsp;&nbsp;</span>
                <div>
                    <u>Force Majeure</u>. Company will not be liable or
                    responsible to you, nor be deemed to have defaulted under or
                    breached these Terms, for any failure or delay in fulfilling
                    or performing any of our obligations under these Terms or in
                    providing the Services, including operating the Website,
                    when and to the extent such failure or delay is caused by or
                    results from any events beyond our ability to control,
                    including acts of God, flood, fire, earthquake, epidemics,
                    pandemics, tsunami, explosion, war, invasion, hostilities
                    (whether war is declared or not), terrorist threats or acts,
                    riot or other civil unrest, government order, law, or
                    action, embargoes or blockades, strikes, labor stoppages or
                    slowdowns or other industrial disturbances, shortage of
                    adequate or suitable Internet connectivity,
                    telecommunication breakdown or shortage of adequate power or
                    electricity, and other similar events beyond our control.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.9&nbsp;&nbsp;</span>
                <div>
                    <u>Miscellaneous</u>. If any provision of these Terms shall
                    be unlawful, void or for any reason unenforceable, then that
                    provision shall be deemed severable from these Terms and
                    shall not affect the validity and enforceability of any
                    remaining provisions. These Terms and the licenses granted
                    hereunder may be assigned by Company but may not be assigned
                    by you without the prior express written consent of Company.
                    No waiver by either party of any breach or default hereunder
                    shall be deemed to be a waiver of any preceding or
                    subsequent breach or default. The section headings used
                    herein are for reference only and shall not be read to have
                    any legal effect. The Services are operated by us in the
                    United States. Those who choose to access the Services from
                    locations outside the United States do so at their own
                    initiative and are responsible for compliance with
                    applicable local laws. These Terms are governed by the laws
                    of the State of New York, without regard to conflict of laws
                    rules, and the proper venue for any disputes arising out of
                    or relating to any of the same will be the arbitration venue
                    set forth in Section 9, or if arbitration does not apply,
                    then the state and federal courts located in New York. You
                    and Company agree that the United Nations Convention on
                    Contracts for the International Sale of Goods will not apply
                    to the interpretation or construction of these Terms.
                </div>
            </div>
            <div
                style={{
                    ...containerStyle,
                    display: 'flex',
                    marginBottom: '10px'
                }}
            >
                <span>10.10&nbsp;&nbsp;</span>
                <div>
                    <u>How to Contact Us</u>. You may contact us regarding the
                    Services or these Terms by e-mail at{' '}
                    <Link href={'mailto:operations@getmojito.com'}>
                        operations@getmojito.com
                    </Link>
                    .
                </div>
            </div>
        </>
    );
};

export default TermsAndConditionContentLayout;

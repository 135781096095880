import { MixTheme, StyledObject, Style } from '@styles';

export interface LoginStyle extends StyledObject {
    cardTitle: Style;
    cardSubTitle: Style;
    buttonText: Style;
    header: Style;
    registerCard: Style;
    loginCard: Style;
    loginWithMojito: Style;
    loginWithEmail: Style;
}

export const makeStyles = (theme: MixTheme) => {
    return {
        cardTitle: {
            textTransform: 'uppercase',
            fontSize: '14px',
            fontStyle: 'normal'
        },
        cardSubTitle: {
            fontSize: '1.5rem',
            paddingBottom: '2rem'
        },
        buttonText: {
            fontStyle: 'normal',
            width: '100%',
            marginTop: '12px',
            backgroundColor: '#0F172A !important',
            color: '#ffffff',
            textTransform: 'capitalize'
        },
        header: {
            overflow: 'auto'
        },
        registerCard: {
            p: '40px',
            width: '100%',
            marginTop: '30px',
            marginBottom: '40px',
            border: `1px solid ${theme?.global?.border}`,
            boxShadow: 'none'
        },
        loginOrSignup: {
            width: '100%',
            marginTop: '32px',
            backgroundColor: '#6663FD',
            color: '#ffffff',
            textTransform: 'capitalize',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#6663FD'
            }
        },
        loginCard: {
            p: '40px',
            width: '100%',
            marginTop: '30px',
            marginBottom: '40px',
            border: '1px solid #CACAE0',
            boxShadow: 'none',
            backgroundColor: theme?.global?.loginCardBackground,
            backdropFilter: 'blur(20px)'
        },
        maskConnectButton: {
            width: '100%',
            backgroundColor: theme?.global?.darkBackground,
            color: '#ffffff',
            fontWeight: 600,
            height: '44px',
            fontSize: '1rem',
            fontFamily: theme?.font?.primary,
            padding: '14px 1rem',
            textTransform: 'capitalize',
            '&:hover': { backgroundColor: theme?.global?.darkBackground }
        },
        loginWithMojito: {
            marginTop: 2,
            width: '100%',
            backgroundColor: theme?.global?.darkBackground,
            color: '#ffffff',
            height: '44px',
            fontFamily: theme?.font?.primary,
            fontSize: '1rem',
            fontWeight: 600,
            textTransform: 'capitalize',
            '&:hover': {
                backgroundColor: theme?.global?.darkBackground
            }
        },
        loginWithEmail: {
            width: '100%',
            backgroundColor: theme?.global?.darkBackground,
            color: '#ffffff',
            height: '44px',
            textTransform: 'capitalize',
            fontSize: '1rem',
            fontWeight: 600,
            '&:hover': {
                backgroundColor: theme?.global?.darkBackground
            }
        }
    } as LoginStyle;
};

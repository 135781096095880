import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Close from '@mui/icons-material/Close';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Image from 'next/image';

export interface ErrorContainerProps {
    errorMessage: string;
    errorImage?: string;
    handleRetry: () => void;
    onCloseModal: () => void;
}

const ErrorContainer = ({
    errorMessage,
    errorImage,
    handleRetry,
    onCloseModal
}: ErrorContainerProps) => {
    const theme = useTheme();
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Close
                    data-testid="onClick_close"
                    sx={{
                        cursor: 'pointer',
                        color: theme.palette.background.default
                    }}
                    onClick={onCloseModal}
                />
            </Box>
            <Box
                sx={{
                    padding: '30px'
                }}
            >
                {errorImage ? (
                    <Typography align="center">
                        <Image
                            unoptimized
                            src={errorImage}
                            width={100}
                            height={100}
                            alt="error"
                        />
                    </Typography>
                ) : (
                    <Typography align="center">
                        <ErrorOutline sx={{ fontSize: '100px' }} />
                    </Typography>
                )}
                <Typography
                    component="h4"
                    align="center"
                    sx={{
                        fontWeight: 700,
                        fontSize: '24px',
                        padding: '24px 0 16px 0'
                    }}
                >
                    Connection Error!
                </Typography>
                <Typography sx={{ marginBottom: '15px' }} align="center">
                    {errorMessage}
                </Typography>
                <Button
                    data-testid="onClick_retry"
                    sx={{ width: '100%' }}
                    onClick={handleRetry}
                >
                    Retry
                </Button>
            </Box>
        </Box>
    );
};
export default ErrorContainer;

import { MixTheme, StyledObject, Style } from '@styles';

export interface Sites extends StyledObject {
    card: Style;
    cardTitle: Style;
    cardSubTitle: Style;
    subMenuTitle: Style;
    subMenuBackground: Style;
    menuList: Style;
    menuListSelected: Style;
    subMenuList: Style;
    menuTitle: Style;
    navigationCard: Style;
    mainMenuTitle: Style;
    headerDescription: Style;
    subMenuNotSelected: Style;
    subMenuSelected: Style;
}

export const makeStyles = (theme: MixTheme) => {
    return {
        card: {
            padding: '20px'
        },
        cardTitle: { fontWeight: 600, fontSize: 24 },
        cardSubTitle: {
            fontSize: '14px',
            fontWeight: 600,
            color: theme?.global?.hover
        },
        menuTitle: {
            margin: '30px 0'
        },
        subMenuTitle: {
            fontWeight: 400,
            fontSize: '15px',
            marginLeft: '16px',
            fontFamily: theme?.font?.tertiary,
            color: theme?.global?.darkBackground
        },
        subMenuBackground: {
            '&:hover': { cursor: 'pointer' },
            margin: '5px'
        },
        subMenuList: {
            fontSize: '14px',
            fontWeight: 600,
            color: theme?.global?.hover,
            fontFamily: theme?.font?.primary
        },
        subMenuSelected: {
            padding: '0 5px',
            fontSize: '14px',
            fontWeight: 600,
            fontFamily: theme?.font?.tertiary,
            color: theme?.global?.hover
        },
        subMenuNotSelected: {
            padding: '0 5px',
            fontSize: '14px',
            fontWeight: 600,
            fontFamily: theme?.font?.tertiary,
            color: theme?.color?.textColor
        },
        menuListSelected: {
            padding: '2px',
            marginBottom: '10px',
            borderRadius: '5px',
            backgroundColor: theme?.global?.hoverBackground,
            color: theme?.global?.hover,
            '&:hover': {
                backgroundColor: theme?.global?.hoverBackground,
                color: theme?.global?.hover,
                cursor: 'pointer'
            }
        },
        menuList: {
            padding: '2px',
            marginBottom: '10px',
            borderRadius: '5px',
            '&:hover': {
                backgroundColor: theme?.global?.hoverBackground,
                color: theme?.global?.hover,
                cursor: 'pointer'
            }
        },
        menuItemsTitle: {
            border: `1px solid ${theme?.global?.border}`,
            borderRadius: '4px',
            marginBottom: '15px',
            marginTop: '0px',
            padding: '15px'
        },
        navigationCard: { margin: '1rem' },
        mainMenuTitle: {
            fontFamily: theme?.font?.primary,
            fontWeight: '600'
        },
        headerDescription: {
            fontFamily: theme?.font?.primary,
            color: theme?.color?.textColor,
            fontSize: '14px'
        }
    } as Sites;
};

import { RouterPath } from './RouterPath';
import { ColumnsModal } from '@interface/ColumnModal';

export const superAdminUsersListColumns = [
    {
        columnName: 'User ID',
        dataName: 'user_id',
        checked: true
    },
    {
        columnName: 'Users',
        dataName: 'users',
        checked: true
    },
    {
        columnName: 'Email',
        dataName: 'email',
        checked: true
    },
    {
        columnName: 'Role',
        dataName: 'role',
        checked: true
    },
    {
        columnName: 'Organization',
        dataName: 'organization',
        checked: true
    },
    {
        columnName: 'Date Joined',
        dataName: 'data_joined',
        checked: true
    },
    {
        columnName: 'Last Active',
        dataName: 'last_active',
        checked: true
    }
];

export const superAdminOrganizationListColumns = [
    {
        columnName: 'OrgID',
        dataName: 'org_id',
        checked: true
    },
    {
        columnName: 'Name',
        dataName: 'name',
        checked: true
    },
    {
        columnName: 'Multi-sig',
        dataName: 'multi_sig',
        checked: true
    },
    {
        columnName: 'Subscription Type',
        dataName: 'subscription_type',
        checked: true
    },
    {
        columnName: 'MOR',
        dataName: 'mor',
        checked: true
    },
    {
        columnName: 'Total Users',
        dataName: 'total_users',
        checked: true
    },
    {
        columnName: 'Date Created',
        dataName: 'date_created',
        checked: true
    },
    {
        columnName: 'Last Active',
        dataName: 'last_active',
        checked: true
    }
];

export const CategoriesTableFieldOptions: ColumnsModal[] = [
    {
        columnName: 'Name',
        dataName: 'name',
        checked: true
    },
    {
        columnName: 'Description',
        dataName: 'description',
        checked: true
    },
    {
        columnName: 'Priority',
        dataName: 'priority',
        checked: true
    },
    {
        columnName: 'Date Added',
        dataName: 'date',
        checked: true
    }
];

export const AuctionBidTableHeader = [
    'Data',
    'Buyer',
    'Auth0 ID',
    'Current Bid',
    'Max Bid',
    'Bid Status',
    'KYC status',
    ''
];

export const TokenDetailsHeader = [
    'Token Id',
    'Item',
    'Editions',
    'Token Status',
    'Txn hash',
    'Txn Data',
    'Txn Fee'
];

export const EditListingHeader = [
    'Item',
    'Token ID',
    'Editions',
    'Collection Type',
    'Contract Type',
    'Token Source',
    'Token Status'
];

export const SecondaryMarketPlace = [
    {
        name: 'Overview',
        path: RouterPath.overview
    },
    {
        name: 'Market Activity',
        path: RouterPath.marketActivity
    },
    {
        name: 'Listing Registry',
        path: RouterPath.listingRegistry
    }
];

export const OverviewTableHeader = ['Txn hash', 'Action', 'Item', 'Price', 'From', 'To', 'Date'];

export const ListingRegistryTabs = [
    { id: 1, tabName: 'COLLECTIONS' },
    { id: 2, tabName: 'ARTISTS' },
    { id: 3, tabName: 'CATEGORIES' }
];

export const TokenAttributesField = [
    { id: '1.', value: 'Category' },
    { id: '2.', value: 'Date' },
    { id: '3.', value: 'Location' },
    { id: '4.', value: 'Location 2' },
    { id: '5.', value: 'State' }
];

export const RoyalitiesField = [];

export const ArtistContractStatus = {
    DRAFT: 'DRAFT',
    PROCESSING: 'PROCESSING',
    DEPLOYED: 'DEPLOYED',
    PENDING: 'PENDING',
    NOT_REQUIRED: 'NOT_REQUIRED',
    FAILED: 'FAILED'
};

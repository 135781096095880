import { Configuration } from '@constants';
import { ContentFields, Environment, Space } from 'contentful-management';
import { EntityType } from 'src/constants/ContentFullConstants';
import { convertBase64 } from 'src/utils/file.utils';
import { manageClient } from './ContentFullClientService';

export const createRequestObject = async (
    fields: ContentFields[],
    data: any
) => {
    const copiedParamData = { ...data };
    if (copiedParamData?.showButton) {
        copiedParamData.showButton =
            copiedParamData?.showButton === 'on' ||
            copiedParamData?.showButton === true;
    }
    const request: any = {};
    for (let i = 0; i < fields.length; i++) {
        const item = fields[i];
        if (
            copiedParamData?.[item.id] ||
            copiedParamData?.[item.id] === false
        ) {
            if (
                item?.type === EntityType.Link &&
                item?.linkType === EntityType.Asset
            ) {
                if (!copiedParamData[item?.id]?.urls) {
                    const id = await createEntryWithFile(
                        copiedParamData[item?.id]
                    );
                    request[item.id] = {
                        'en-US': {
                            sys: {
                                id: id,
                                linkType: EntityType.Asset,
                                type: EntityType.Link
                            }
                        }
                    };
                }
            } else if (item?.type === EntityType.Link) {
                request[item.id] = {
                    'en-US': {
                        sys: {
                            id: copiedParamData[item?.id],
                            linkType: EntityType.ENTRY,
                            type: EntityType.Link
                        }
                    }
                };
            } else
                request[item.id] = {
                    'en-US': copiedParamData[item?.id]
                };
        }
    }
    return request;
};

export const getDataFromFields = async (
    fields: ContentFields[],
    data: any,
    space: Space,
    env: Environment
) => {
    const newData: any = [];

    for (let i = 0; i < fields.length; i++) {
        const item = fields[i];
        if (Array.isArray(data?.[item?.id]?.['en-US'])) {
            newData[item.id] = data[item?.id]?.['en-US'].map(
                (ele: any) => ele?.sys?.id
            );
        } else if (
            item?.type === EntityType.Link &&
            item?.linkType === EntityType.Asset &&
            data[item?.id]?.['en-US']?.sys?.id
        ) {
            const asset = await env.getAsset(data[item?.id]['en-US']?.sys?.id);
            newData[item.id] = {
                urls: 'https:' + asset.fields.file?.['en-US'].url,
                type: asset.fields.file?.['en-US'].contentType
            };
        } else newData[item.id] = data?.[item?.id]?.['en-US'];
    }
    return newData;
};

export const createEntryWithFile = async (files: any) => {
    const space = await manageClient.getSpace(Configuration.SPACEID ?? '');
    const env = await space.getEnvironment('master');
    const arrayBuffer = await convertBase64(files);
    const asset = await env.createAssetFromFiles({
        fields: {
            title: {
                'en-US': 'scenary'
            },
            description: { 'en-US': 'scenary' },
            file: {
                'en-US': {
                    file: arrayBuffer as string,
                    contentType: files?.type,
                    fileName: files?.name
                }
            }
        }
    });
    const process = await asset.processForAllLocales();
    const publish = await process.publish();
    return publish.sys.id;
};

export const createObjectForFeatureList = async (
    data: any,
    items: any[],
    env: Environment
) => {
    const ids: any[] = [];
    for (let i = 0; i < data?.item?.length; i++) {
        const item = items.find((ele) => ele.id === data.item[i]);
        const requestData = {
            itemId: {
                'en-US': data?.item[i].toString()
            },
            itemName: {
                'en-US': item.name
            }
        };
        const create = await env.createEntry('item', {
            fields: {
                ...requestData
            }
        });

        await create.publish();
        ids.push(create.sys.id);
    }

    const request = {
        item: {
            'en-US': ids.map((item) => {
                return {
                    sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: item
                    }
                };
            })
        }
    };
    return request;
};

export const getDataById = (id: string, entries: any[]) => {
    const entry = entries.find((ele) => id === ele.sys.id);
    return entry;
};

export const getData = (data: any, entries: any[], assets: any[]) => {
    const keys = Object.keys(data);
    const newData: any = {};

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        const item = data[key];

        if (Array.isArray(item)) {
            const idList = item.map((ele) => ele?.sys?.id);
            newData[key] = idList.map((id) => {
                const element = getDataById(id, entries);
                return getData(element.fields, entries, assets);
            });
        } else if (item?.sys?.type === EntityType.Asset) {
            newData[key] = {
                urls: 'https:' + item.fields.file.url,
                type: item.fields.file.contentType
            };
        } else if (
            item?.sys?.type === EntityType.Link &&
            item?.sys?.linkType === EntityType.Asset
        ) {
            const asset = getDataById(item?.sys?.id, assets);
            newData[key] = {
                urls: 'https:' + asset.fields.file.url,
                type: asset.fields.file.contentType
            };
        } else newData[key] = item;
    }
    return { ...newData };
};

import { RouterPath } from '@constants';
import { MenuItemOption } from './Drawer';

export const topMenuItem: MenuItemOption[] = [
    {
        id: 'superadmin',
        src: '/images/icons/sidebar/mojito-logo.svg',
        href: RouterPath.superAdminUsers,
        disabled: true,
        name: ''
    },
    {
        id: 'dashboard',
        src: '/images/Contained.svg',
        href: RouterPath.dashboard,
        name: 'Sotheby’s'
    },
    {
        id: 'home',
        src: '/images/icons/sidebar/home.svg',
        href: RouterPath.dashboard,
        name: 'Home',
        tooltipName: 'Dashboard'
    },
    {
        id: 'marketplace',
        src: '/images/icons/sidebar/marketplace.svg',
        href: RouterPath.drops,
        name: 'Marketplace',
        tooltipName: 'Drops & Listings'
    },
    {
        id: 'collections',
        src: '/images/icons/sidebar/collections.svg',
        href: RouterPath.collections,
        name: 'Collections',
        tooltipName: 'NFT Collections'
    },
    {
        id: 'customers',
        src: '/images/icons/sidebar/customers.svg',
        href: RouterPath.customers,
        name: 'Community',
        tooltipName: 'Your Community'
    },
    {
        id: 'tokengates',
        src: '/images/icons/sidebar/lock-open.svg',
        href: RouterPath.tokengates,
        name: 'Tokengates',
        tooltipName: 'Your Tokengates'
    },
    {
        id: 'discountCode',
        src: '/images/icons/sidebar/list.svg',
        href: RouterPath.discounts,
        name: 'Discounts',
        tooltipName: 'Discount Codes'
    },
    {
        id: 'paymentLinks',
        src: '/images/icons/sidebar/paymentLink.svg',
        href: RouterPath.paymentLinks,
        name: 'Payment Links',
        tooltipName: 'Payment Links'
    }
];

export const bottomMenuItem: MenuItemOption[] = [
    {
        id: 'reports',
        src: '/images/icons/sidebar/reports.svg',
        href: RouterPath.reports,
        name: 'Reports',
        tooltipName: 'Reports'
    },
    {
        id: 'settings',
        src: '/images/icons/sidebar/settings.svg',
        href: RouterPath.organizationInfo,
        name: 'Settings',
        tooltipName: 'Settings'
    },
    {
        id: 'profile',
        src: '/images/icons/sidebar/Avatar.svg',
        href: '',
        name: 'User Name'
    }
];

export const customerRoutes = [
    RouterPath.customers,
    RouterPath.allowLists,
    RouterPath.allowListsDetail,
    RouterPath.allowListsSetting
];
export const settingsRoutes = [
    RouterPath.settings,
    RouterPath.teamMembers,
    RouterPath.walletSettings,
    RouterPath.apiKeysSettings,
    RouterPath.accountInfo,
    RouterPath.organizationInfo
];
export const reportsRoutes = [RouterPath.reports, RouterPath.reportDetails];
export const marketplaceRoutes = [
    RouterPath.drops,
    RouterPath.dropDetail,
    RouterPath.dropListingSettings,
    RouterPath.dropSettings,
    RouterPath.overview,
    RouterPath.listingRegistry,
    RouterPath.marketActivity,
    RouterPath.listingRegistryDetails,
    RouterPath.overrideTokenDetails,
    RouterPath.overviewSettings
];
export const collectionRoutes = [
    RouterPath.collections,
    RouterPath.collectionsDetail,
    RouterPath.collectionsSettings,
    RouterPath.addToken,
    RouterPath.bulkUploadToken
];
export const superAdminRoutes = [
    RouterPath.superAdminUsers,
    RouterPath.superAdminOrganizations,
    RouterPath.superAdminOrganizationOverview
];
export const sitesRoutes = [
    RouterPath.sitePages,
    RouterPath.siteNavigation,
    RouterPath.siteBranding,
    RouterPath.siteSettings
];

export const truncateStringInTheMiddle = (
    str = '',
    strLength = 35,
    strPositionStart = 6,
    strPositionEnd = 4
): string => {
    if (str?.length > strLength) {
        return `${str.substring(0, strPositionStart)}...${str.substring(
            str?.length - strPositionEnd,
            str.length
        )}`;
    }
    return str;
};

export const truncateAddress = (
    address: string,
    front = 5,
    back = 3
): string => {
    const frontSubstring = address?.substring(0, front);
    const backSubstring = address?.substring(Number(address?.length) - back);
    return `${frontSubstring}...${backSubstring}`;
};

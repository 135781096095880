import React from 'react';
import { Stack } from '@mui/material';
import Image from 'next/image';

interface LoadingComponentProps {
    show: boolean;
}
export const LoadingComponent = ({ show }: LoadingComponentProps) => {
    return show ? (
        <Stack
            sx={{
                position: 'fixed',
                zIndex: 10000,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                background: 'rgba(255, 255, 255, 1)',
                opacity: '0.7'
            }}
            display={'flex'}
            flexDirection={'row'}
            width="100%"
            height={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Image src="/images/mojito-loader.gif" width={100} height={100} />
        </Stack>
    ) : (
        <Stack></Stack>
    );
};

import React from 'react';
import CreateSiteModalBodyLayout from '@layouts/createSiteModalBody.layout';
import { ModalDialog } from '@components/Atoms';

export interface CreateSiteProps {
    pageTitle?: string;
    domainName?: string;
    siteId: string;
    orgId: string;
}

interface CreateSiteModalProps {
    isOpenModal: boolean;
    siteCreateFields: CreateSiteProps;
    closeDialog: () => void;
    submitDialog: () => void;
    onCreateSiteFields: (fieldName: string, value: string) => void;
}
const CreateSiteModal = ({
    isOpenModal,
    siteCreateFields,
    closeDialog,
    submitDialog,
    onCreateSiteFields
}: CreateSiteModalProps) => {
    return (
        <ModalDialog
            title="Create Your Site"
            open={isOpenModal}
            onClose={closeDialog}
            onCancel={closeDialog}
            onSubmit={submitDialog}
            submitButtonText={'Create Site'}
            isSubmitEnabled
            body={
                <CreateSiteModalBodyLayout
                    fieldsInitialValue={{
                        pageTitle: siteCreateFields.pageTitle || '',
                        domainName: siteCreateFields?.domainName || ''
                    }}
                    onSetFields={onCreateSiteFields}
                />
            }
        />
    );
};

export default CreateSiteModal;

import { gql } from '@apollo/client';

export const dropsListQuery = gql`
    query (
        $id: UUID!
        $sort: MarketPlaceCollectionSortInput
        $filters: MarketPlaceCollectionFilterInput
        $offset: Int
        $limit: Int
        $searchKey: String
    ) {
        marketplaceDropV2(
            id: $id
            sort: $sort
            filters: $filters
            offset: $offset
            limit: $limit
            search: $searchKey
        ) {
            count
            marketplaceCollection {
                marketplaceID
                name
                slug
                startDate
                endDate
                dropType
                status
                itemsCount
                id
                configuration {
                    payoutAddress {
                        walletAddress
                        percentage
                    }
                }
            }
        }
    }
`;

export const createDropQuery = gql`
    mutation CreateDropInput($data: CreateDropInput!) {
        createDrop(data: $data)
    }
`;

export const dropsActivityQuery = gql`
    query (
        $marketplaceCollectionID: UUID1!
        $searchKey: String
        $filter: [CollectionActivityFilterInput]
        $limit: Int
        $offset: Int
    ) {
        getCollectionActivity(
            marketplaceCollectionID: $marketplaceCollectionID
            searchKey: $searchKey
            filter: $filter
            limit: $limit
            offset: $offset
        ) {
            totalCount
            marketplaceCollectionActivity {
                collectionItemId
                collectionItemName
                collectionItemImage
                currencyType
                destinationAddress
                externalUserId
                invoiceItemId
                invoiceNumber
                invoiceStatus
                timestamp
                tokenDeliveryStatus
                tokenId
                totalPrice
                txnFee
                txnHash
                txnReference
                type
                units
                userId
                username
                invoiceId
                networkId
                usdUnitPrice
                saleType
            }
        }
    }
`;

export const dropsListingQuery = gql`
    query (
        $id: String!
        $searchKey: String
        $sort: CollectionListingSortInput
        $offset: Int
        $limit: Int
        $filter: [DropListingFilterActivityInput]
    ) {
        GetMarketplaceCollectionItem(
            id: $id
            searchKey: $searchKey
            sort: $sort
            offset: $offset
            limit: $limit
            filter: $filter
        ) {
            totalCount
            marketplaceCollectionItem {
                id
                marketplaceTokenId
                collectionId
                saleType
                name
                slug
                status
                deliveryMethod
                description
                asset {
                    currentVersion {
                        cdnUrl
                    }
                }
                allowList {
                    name
                }
                presale {
                    startDate
                    endDate
                    maxEntries
                }
                lot {
                    id
                }
                details {
                    ... on MarketplaceAuctionLot {
                        id
                        status
                        startDate
                        endDate
                        lotNumber
                        startingBid
                        __typename
                        subSaleType
                        marketplaceAuctionOnChainSettings {
                            id
                            marketplaceAuctionLotID
                            nftContractAddress
                            tokenID
                            quantityOf1155
                            tokenOwnerAddress
                            minimumBidCryptoPrice
                            reservedPrice
                            paymentCurrency
                            whitelistedBuyers
                            blacklistedBuyers
                            paymentSettlementAddress
                            taxSettlementAddress
                            commissionAddress
                            platformSettlementAddress
                            commissionFeePercentage
                            platformFeePercentage
                            endAuctionStatus
                            networkID
                            endTransactionHash
                            tokenType
                            supportedCurrencyID
                            halfLifeTime
                            availableQuantity
                            totalQuantity
                            reduceByAmount
                            reduceByInterval
                            walletLimit
                            transactionLimit
                            isInstantDelivery
                            fromTokenID
                            toTokenID
                            rebateEnabled
                        }
                    }
                    ... on MarketplaceBuyNowOutput {
                        id
                        unitPrice
                        totalUnits
                        totalAvailableUnits
                        startDate
                        endDate
                        sortNumber
                        __typename
                    }
                    ... on MarketplaceClaimableOutput {
                        id
                        totalUnits
                        perWalletLimit
                        startDate
                        endDate
                        claimingType
                        totalAvailableUnits
                        __typename
                    }
                }
                delivery {
                    ... on MarketplaceItemDeliveryERC721Transfer {
                        contractAddress
                        onChainTokenId
                    }
                    ... on MarketplaceItemDeliveryERC1155Transfer {
                        contractAddress
                        onChainTokenId
                    }
                    ... on MarketplaceItemDeliveryERC721Provenance {
                        nftContractId
                        extensionAddress
                    }
                    ... on MarketplaceItemDeliveryERC1155Provenance {
                        nftContractId
                        extensionAddress
                        onChainTokenId
                    }
                    ... on MarketplaceItemDeliveryERC721TransferByRange {
                        ownerWalletId
                        contractAddress
                        fromOnChainTokenId
                        toOnChainTokenId
                    }
                    ... on MarketplaceItemDeliveryNoOp {
                        notes
                        noOpcontractAddress
                        noOpOnChainTokenId
                    }
                    ... on MarketplaceItemDeliveryERC1155OpenEdition {
                        ownerWalletId
                        contractId
                        onChainTokenId
                    }
                    ... on MarketplaceItemDeliveryERC721MintOnDelivery {
                        nftContractId
                    }
                    ... on MarketplaceItemDeliveryERC1155MintOnDelivery {
                        nftContractId
                    }
                }
                artistId
                artist {
                    id
                    artistName
                    artistLocation
                    active
                    marketplaceId
                }
                supportedCurrency {
                    symbol
                }
            }
        }
    }
`;
export const dropsDetailsQuery = gql`
    query ($id: String!) {
        collection(id: $id) {
            id
            marketplaceID
            name
            slug
            description
            status
            startDate
            endDate
            collectionType
            itemsCount
            items {
                id
                marketplaceTokenId
                collectionId
                saleType
                name
                slug
                status
                deliveryMethod
                asset {
                    currentVersion {
                        cdnUrl
                    }
                }
                allowList {
                    name
                }
                presale {
                    startDate
                    endDate
                    maxEntries
                }
                details {
                    ... on MarketplaceAuctionLot {
                        id
                        status
                        startDate
                        endDate
                        lotNumber
                        startingBid
                        __typename
                    }
                    ... on MarketplaceBuyNowOutput {
                        id
                        unitPrice
                        totalUnits
                        totalAvailableUnits
                        startDate
                        endDate
                        sortNumber
                        __typename
                    }
                    ... on MarketplaceClaimableOutput {
                        id
                        totalUnits
                        perWalletLimit
                        startDate
                        endDate
                        claimingType
                        totalAvailableUnits
                        __typename
                    }
                }
                delivery {
                    ... on MarketplaceItemDeliveryERC721Provenance {
                        nftContractId
                        extensionAddress
                    }
                    ... on MarketplaceItemDeliveryERC721Transfer {
                        ownerWalletId
                        contractAddress
                        onChainTokenId
                        RelayTxInfo {
                            relayerAddress
                            networkId
                        }
                        randomizeTokensInAddress
                    }
                    ... on MarketplaceItemDeliveryERC1155Transfer {
                        ownerWalletId
                        contractAddress
                        onChainTokenId
                    }
                    ... on MarketplaceItemDeliveryERC1155OpenEdition {
                        ownerWalletId
                        contractId
                        onChainTokenId
                    }
                    ... on MarketplaceItemDeliveryNoOp {
                        notes
                        noOpcontractAddress
                        noOpOnChainTokenId
                    }
                    ... on MarketplaceItemDeliveryERC721TransferByRange {
                        ownerWalletId
                        contractAddress
                        fromOnChainTokenId
                        toOnChainTokenId
                    }
                }
                artistId
                artist {
                    id
                    artistName
                    artistLocation
                    active
                    marketplaceId
                }
            }
            redirectUrl
            dropType
            configuration {
                taxInvoiceSapType
                taxInvoiceSku
                purchaseLimit
            }
        }
    }
`;

export const updateListingQuery = gql`
    mutation (
        $marketplaceAuctionLotId: UUID!
        $startDate: Time
        $endDate: Time
    ) {
        updateMarketplaceAuctionLot(
            marketplaceAuctionLotId: $marketplaceAuctionLotId
            data: { startDate: $startDate, endDate: $endDate }
        ) {
            startDate
            endDate
            id
        }
    }
`;

export const getDropsSettings = gql`
    query ($collectionID: UUID1!) {
        getDropSettings(collectionID: $collectionID) {
            id
            name
            description
            dropType
            startDate
            endDate
            status
            configuration {
                payoutAddress {
                    walletAddress
                    percentage
                }
                walletLimitPerAddress
                transactionLimitPerAddress
                claimLimitPerAddress
                allowListLimitPerAddress
            }
            presale {
                allowList {
                    identifierType
                    id
                    startTime
                    name
                    endTime
                    description
                    allowListType
                    elements {
                        id
                        identifierValue
                    }
                }
                marketplaceCollectionId
                startDate
                endDate
                marketplaceCollectionItemId
                maxEntries
            }
        }
    }
`;

export const updateDropsSettings = gql`
    mutation ($data: DropSettingsInput!) {
        updateDropSettings(data: $data) {
            id
            name
            description
            dropType
            startDate
            endDate
            configuration {
                payoutAddress {
                    walletAddress
                    percentage
                }
                walletLimitPerAddress
                transactionLimitPerAddress
            }
            presale {
                allowList {
                    identifierType
                    id
                    startTime
                    name
                    endTime
                    description
                    allowListType
                    elements {
                        id
                        identifierValue
                    }
                }
                marketplaceCollectionId
                startDate
                endDate
                marketplaceCollectionItemId
                maxEntries
            }
        }
    }
`;

export const filterDataAuctionBidQuery = gql`
    query getBids(
        $marketplaceAuctionLotId: UUID1
        $orgId: UUID1!
        $dropId: UUID1
        $searchKey: String
        $offset: Int
        $limit: Int
        $filter: [DropBidFilterActivityInput]
        $sortBy: BidsSortInput
    ) {
        getBids(
            marketplaceAuctionLotId: $marketplaceAuctionLotId
            orgId: $orgId
            dropId: $dropId
            searchKey: $searchKey
            offset: $offset
            limit: $limit
            filter: $filter
            sortBy: $sortBy
        ) {
            totalCount
            data {
                id
                amount
                createdAt
                deletedAt
                maximumBid
                currentBid
                userId
                walletAddress
                isCurrent
                txHash
                txStatus
                nextBidIncrement
                marketplaceAuctionLotId
                marketplaceAuctionLot {
                    subSaleType
                    endDate
                    marketplaceAuctionOnChainSettings {
                        networkID
                    }
                    marketplaceCollectionItem {
                        name
                        asset {
                            currentVersion {
                                cdnUrl
                                id
                                slug
                                name
                            }
                        }
                    }
                }
                userOrganization {
                    externalUserId
                    username
                    kycStatus
                    __typename
                }
                marketplaceUser {
                    id
                    username
                    __typename
                }
            }
        }
    }
`;

export const secondaryMarketCategories = gql`
    query GetCategories(
        $orgId: UUID1!
        $marketplaceId: UUID1!
        $searchKey: String
        $sort: categorySortInput
        $offset: Int
        $limit: Int
        $categoriesFilter: [CategoriesFilterInput]
    ) {
        getCategories(
            orgId: $orgId
            marketplaceId: $marketplaceId
            searchKey: $searchKey
            sort: $sort
            offset: $offset
            limit: $limit
            categoriesFilter: $categoriesFilter
        ) {
            totalCount
            data {
                id
                name
                slug
                description
                priority
                active
                marketplaceId
                organizationId
                createdAt
                organization {
                    id
                    name
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export const createCategoryQuery = gql`
    mutation createCategory($input: CategoryInput!) {
        createCategory(input: $input) {
            marketplaceId
            organizationId
            slug
            priority
            description
            name
        }
    }
`;

export const deleteCategoryQuery = gql`
    mutation DeleteCategory(
        $categoryID: UUID1!
        $marketplaceId: UUID1!
        $orgId: UUID1!
    ) {
        deleteCategory(
            categoryID: $categoryID
            marketplaceId: $marketplaceId
            orgId: $orgId
        )
    }
`;

export const getMarketplaceAuctionLot = gql`
    query MarketplaceAuctionLot($marketplaceAuctionLotId: UUID!) {
        getMarketplaceAuctionLot(
            marketplaceAuctionLotId: $marketplaceAuctionLotId
        ) {
            id
            bids(filter: { returnDeleted: true }) {
                id
                isCurrent
                amount
                createdAt
                deletedAt
                marketplaceAuctionLotId
                nextBidIncrement
                currentBid
                userId
                maximumBid
                claimRebateAmount
                walletAddress
                claimStatus
                auctionEndDate
                __typename
            }
            marketplaceAuctionOnChainSettings {
                id
                marketplaceAuctionLotID
                nftContractAddress
                tokenID
                quantityOf1155
                tokenOwnerAddress
                minimumBidCryptoPrice
                reservedPrice
                paymentCurrency
                whitelistedBuyers
                blacklistedBuyers
                paymentSettlementAddress
                taxSettlementAddress
                commissionAddress
                platformSettlementAddress
                commissionFeePercentage
                platformFeePercentage
                endAuctionStatus
                networkID
                endTransactionHash
                tokenType
                supportedCurrencyID
                halfLifeTime
                availableQuantity
                totalQuantity
                reduceByAmount
                reduceByInterval
                walletLimit
                transactionLimit
                isInstantDelivery
                fromTokenID
                toTokenID
                rebateEnabled
            }
            __typename
        }
    }
`;

export const getRebateStatus = gql`
    query getRebateStatus($lotID: UUID1!, $bidID: UUID1!) {
        getRebateStatus(lotID: $lotID, bidID: $bidID) {
            claimAmount
            claimStatus
            auctionEndDate
        }
    }
`;

export const getDutchAuctionPurchaseInfo = gql`
    query getDutchAuctionPurchaseInfo(
        $walletAddress: String!
        $quantity: Int!
        $lotID: UUID1!
    ) {
        getDutchAuctionPurchaseInfo(
            walletAddress: $walletAddress
            quantity: $quantity
            lotID: $lotID
        ) {
            bidAmount
            totalBidAmount
            tax
            commissionFee
            platformFee
            networkID
            supportedCurrencyID
            dutchAuctionContractAddress
            supportedCurrency {
                id
                name
                organizationId
                networkId
            }
        }
    }
`;

export const getInvoices = gql`
    query getInvoices($orgID: UUID1!) {
        getInvoices(orgID: $orgID) {
            userName
            userEmail
            invoiceID
            invoiceNumber
            invoiceCreatedAt
            status
            cryptoAmount
            supportedCurrency {
                id
                name
                networkId
                network {
                    id
                    name
                }
            }
            paymentID
            items {
                invoiceItemID
                collectionItemID
                collectionTitle
                collectionItemTitle
                units
                unitPrice
                buyersPremium
                overheadPremium
                totalPrice
                taxes
            }
        }
    }
`;

export const supportedcurrencies = gql`
    query ($orgId: UUID1!) {
        getSupportedCurrenciesByOrganization(orgId: $orgId) {
            id
            name
            symbol
            networkId
            isCurrencyFiat
            isCurrencyCrypto
            isAuctionEnabled
            network {
                id
                name
                chainID
                rpcURL
                nftAPIURL
                openSeaProxyAddress
                wethAddress
                safeMasterContractAddress
                safeFactoryAddress
                safeFallbackHandler
                defaultCurrency
                isTestnet
                paymentCurrency
            }
            organizationId
            isErc20
            contractAddress
            is_currency_enabled
            isBuyNowEnabled
        }
    }
`;

export const collectionSummary = gql`
    query ($collectionId: UUID1!) {
        getCollectionSummary(collectionId: $collectionId) {
            collectionItemId
            collectionItemName
            saleType
            status
            totalUnits
            totalAvailableUnits
            totalSoldCount
            totalSoldAmount
            ConvCurrencyUnitRate(fiatCurrency: USD)
        }
    }
`;

export const getClaimableCodes = gql`
    query GetClaimableCodesForSetId(
        $claimableSetID: UUID1!
        $limit: Int!
        $offset: Int!
        $searchKey: String
    ) {
        getClaimableCodesForSetId(
            claimableSetID: $claimableSetID
            limit: $limit
            offset: $offset
            searchKey: $searchKey
        ) {
            count
            results {
                id
                isASingleUseCode
                redeemed
                code
                redemptions {
                    walletAddress
                    dateClaimed
                    code
                }
            }
        }
    }
`;

export const cancelBid = gql`
    mutation CancelMarketplaceAuctionBid(
        $bidID: UUID1!
        $marketplaceID: UUID1!
    ) {
        cancelMarketplaceAuctionBid(
            bidID: $bidID
            marketplaceID: $marketplaceID
        )
    }
`;

export const createListingBuyNow = gql`
    mutation CreateMarketplaceBuyNowLot(
        $input: CreateMarketplaceBuyNowLotInput!
    ) {
        createMarketplaceBuyNowLot(input: $input) {
            id
            marketplaceCollectionItem {
                id
            }
        }
    }
`;

export const createListingAuction = gql`
    mutation CreateMarketplaceAuctionLot(
        $marketplaceAuctionLot: MarketplaceAuctionLotInput!
    ) {
        createMarketplaceAuctionLot(
            marketplaceAuctionLot: $marketplaceAuctionLot
        ) {
            id
            marketplaceCollectionItem {
                id
            }
            marketplaceCollectionItemId
        }
    }
`;

export const createListingClaimable = gql`
    mutation CreateMarketplaceClaimableSet(
        $input: CreateMarketplaceClaimableSetInput!
    ) {
        createMarketplaceClaimableSet(input: $input) {
            id
            marketplaceCollectionItem {
                id
            }
            onchainClaimContractVersion {
                contractAddress
            }
        }
    }
`;

export const getDropCollectionById = gql`
    query ($id: UUID1!) {
        collectionItemById(id: $id) {
            lot {
                id
            }
            id
            marketplaceTokenId
            collectionId
            saleType
            name
            description
            status
            slug
            isBallot
            totalQuantity
            perWalletMaxClaim
            numberOfCodes
            prefix
            allowedClaimingMethod
            allowList {
                id
            }
            asset {
                id
                versions {
                    id
                    name
                    cdnUrl
                    assetID
                }
                currentVersion {
                    id
                    name
                    cdnUrl
                    assetID
                }
            }
            details {
                ... on MarketplaceAuctionLot {
                    id
                    lotNumber
                    marketplaceCollectionItemId
                    startingBid
                    reservePrice
                    previewDate
                    startDate
                    endDate
                    status
                    currentBid {
                        id
                        isCurrent
                        amount
                        createdAt
                        deletedAt
                        marketplaceAuctionLotId
                        nextBidIncrement
                        currentBid
                        userId
                        maximumBid
                        claimRebateAmount
                        walletAddress
                        claimStatus
                        auctionEndDate
                        marketplaceUser {
                            id
                            username
                        }
                    }
                    bids {
                        id
                        isCurrent
                        amount
                        createdAt
                        deletedAt
                        marketplaceAuctionLotId
                        nextBidIncrement
                        currentBid
                        userId
                        maximumBid
                        claimRebateAmount
                        walletAddress
                        claimStatus
                        auctionEndDate
                        marketplaceUser {
                            id
                            username
                        }
                    }
                    myBid {
                        id
                        isCurrent
                        amount
                        createdAt
                        deletedAt
                        marketplaceAuctionLotId
                        nextBidIncrement
                        currentBid
                        userId
                        maximumBid
                        claimRebateAmount
                        walletAddress
                        claimStatus
                        auctionEndDate
                        marketplaceUser {
                            id
                            username
                        }
                    }
                    marketplaceAuctionOnChainSettings {
                        id
                        marketplaceAuctionLotID
                        nftContractAddress
                        tokenID
                        quantityOf1155
                        tokenOwnerAddress
                        startingBidCryptoPrice
                        reservedPrice
                        paymentCurrency
                        whitelistedBuyers
                        blacklistedBuyers
                        paymentSettlementAddress
                        taxSettlementAddress
                        commissionAddress
                        platformSettlementAddress
                        commissionFeePercentage
                        platformFeePercentage
                        createAuctionJobStatus
                        endAuctionStatus
                        cancelAuctionStatus
                        networkID
                        endTransactionHash
                        tokenType
                        supportedCurrencyID
                        halfLifeTime
                        availableQuantity
                        totalQuantity
                        reduceByAmount
                        reduceByInterval
                        walletLimit
                        transactionLimit
                        isInstantDelivery
                        fromTokenID
                        toTokenID
                        rebateEnabled
                        rebateStatus
                        minimumBidCryptoPrice
                        isMint
                        maxTokenIDRange
                        isCollectionLevelAuction
                        onChainAuctionContractAddress
                        AuctionContractVersion {
                            id
                            contractABIFileURL
                        }
                        updateAuctionJobStatus
                    }
                    feeStructure {
                        buyersPremiumRate {
                            from
                            to
                            rate
                        }
                        overheadPremiumRate {
                            from
                            to
                            rate
                        }
                    }
                    isOnchainAuction
                    maxEndDate
                    subSaleType
                    isOnchainAuction
                    enableAutoExtension
                    lastIntervalMinutes
                    extensionMinutes
                    maxDuration
                }
                ... on MarketplaceBuyNowOutput {
                    id
                    unitPrice
                    totalUnits
                    totalAvailableUnits
                    startDate
                    endDate
                    sortNumber
                    configuration {
                        walletLimitPerAddress
                        transactionLimitPerAddress
                        allowListLimitPerAddress
                    }
                    onchainBuynowContractExtensionStatus
                    onchainBuynowContractApprovalStatus
                    buynowContractVersion {
                        contractABIFileURL
                        contractAddress
                    }
                    onchainBuynowCancelSaleStatus
                    onchainBuynowSaleCreationStatus
                    onchainBuynowSaleCreationTxhash
                }
                ... on MarketplaceClaimableOutput {
                    id
                    totalUnits
                    perWalletLimit
                    startDate
                    endDate
                    claimingType
                    totalAvailableUnits
                    isOnchainClaim
                    totalAvailableUnits
                }
            }
            deliveryMethod
            delivery {
                ... on MarketplaceItemDeliveryERC721TransferByRandomOrSequence {
                    ownerWalletId
                    contractAddress
                    isRandomDelivery
                    tokenIds
                    __typename
                }
                ... on MarketplaceItemDeliveryERC721Provenance {
                    nftContractId
                    extensionAddress
                }
                ... on MarketplaceItemDeliveryERC721Transfer {
                    ownerWalletId
                    contractAddress
                    onChainTokenId
                    RelayTxInfo {
                        relayerAddress
                        networkId
                    }
                    randomizeTokensInAddress
                }
                ... on MarketplaceItemDeliveryERC1155Transfer {
                    ownerWalletId
                    contractAddress
                    onChainTokenId
                }
                ... on MarketplaceItemDeliveryERC1155OpenEdition {
                    ownerWalletId
                    contractId
                    onChainTokenId
                }
                ... on MarketplaceItemDeliveryNoOp {
                    notes
                    noOpcontractAddress
                    noOpOnChainTokenId
                }
                ... on MarketplaceItemDeliveryERC721TransferByRange {
                    ownerWalletId
                    contractAddress
                    fromOnChainTokenId
                    toOnChainTokenId
                }
                ... on MarketplaceItemDeliveryERC721MintOnDelivery {
                    nftContractId
                }
                ... on MarketplaceItemDeliveryERC1155MintOnDelivery {
                    nftContractId
                }
                ... on MarketplaceItemDeliveryERC721Mint {
                    nftTokenId
                    nftToken {
                        id
                        name
                        metadataArweaveTxId
                        metadataArweaveTxLink
                        onChainId
                        ethereumTxId
                        editions
                        deployed
                        royaltyBasisPoints
                        assetId
                        asset {
                            currentVersion {
                                cdnUrl
                                id
                                name
                            }
                        }
                        metadataJSON
                        nftContractID
                        thumbnailImageCdnURL
                        status
                        network {
                            id
                            name
                        }
                        TokenDeploymentStatus
                    }
                }
            }
            isOnchain
            paymentCurrencyId
            isCreditCardPayment
            onChainPaymentInfo {
                platformFeePercentage
                commissionFeePercentage
                commissionAddress
                taxSettlementAddress
                paymentSettlementAddress
                platformSettlementAddress
                onchainPaymentAddress
            }
            supportedCurrency {
                symbol
                id
                isCurrencyFiat
            }
            NFTDetails {
                tokenStatus
                contractAddress
                collectionName
                nftTokenId
                tokenType
                tokenId
                tokenName
                editions
                contractId
                invoiceItemID
                isDelivered
            }
            gatingGroup {
                id
                groupName
            }
            isReserved
        }
    }
`;

export const updateListingBuyNowQuery = gql`
    mutation updateMarketplaceBuyNowLot(
        $marketplaceBuyNowLotID: UUID!
        $input: MarketplaceBuyNowUpdateInput!
    ) {
        updateMarketplaceBuyNowLot(
            marketplaceBuyNowLotID: $marketplaceBuyNowLotID
            input: $input
        ) {
            id
        }
    }
`;

export const updateListingAuctionQuery = gql`
    mutation updateMarketplaceAuctionLot(
        $marketplaceAuctionLotId: UUID!
        $data: MarketplaceAuctionLotUpdateInput!
    ) {
        updateMarketplaceAuctionLot(
            marketplaceAuctionLotId: $marketplaceAuctionLotId
            data: $data
        ) {
            id
        }
    }
`;

export const updateListingClaimableQuery = gql`
    mutation updateMarketplaceClaimableSet(
        $marketplaceClaimableSetID: UUID1!
        $input: UpdateMarketplaceClaimableSetInput!
    ) {
        updateMarketplaceClaimableSet(
            marketplaceClaimableSetID: $marketplaceClaimableSetID
            input: $input
        ) {
            id
            onchainClaimContractVersion {
                contractAddress
            }
        }
    }
`;

export const getCollectionsQuery = gql`
    query getNftContracts($orgID: UUID1!) {
        getNftContracts(orgID: $orgID) {
            id
            name
            contractAddress
            symbol
            extensionAddress
            networkId
            network {
                chainID
            }
            nftContractType {
                type
            }
            nftContractDeployment {
                collectionType
                mintingMethod
                delayedRevealImageId
                status
                totalSupply
            }
            wallet {
                id
                networkId
                address
                network {
                    chainID
                }
            }
            nftTokens {
                id
                name
                editions
                metadataJSON
                onChainId
                status
                TokenDeploymentStatus
                ownerAddress
            }
            isContractRedeemable
            isImported
        }
    }
`;

export const getCollectionTokensByIDQuery = gql`
    query getNftTokensByContractIdQuery(
        $nftContractId: UUID1!
        $offset: Int
        $limit: Int
        $filter: [getNftTokensByContractIdFilterInput]
    ) {
        getNftTokensByContractId(
            nftContractId: $nftContractId
            offset: $offset
            limit: $limit
            filter: $filter
        ) {
            count
            nftToken {
                id
                name
                editions
                metadataJSON
                onChainId
                status
                TokenDeploymentStatus
                ownerAddress
                nftContract {
                    isImported
                    networkId
                    contractAddress
                }
            }
        }
    }
`;
export const getCollectionDetailQuery = gql`
    query getNftTokenDetailsQuery($nftContractId: UUID1!) {
        getNftTokensForContract(nftContractId: $nftContractId) {
            nftTokenID
            nftName
            edition
            status
            tokenType
            imageUrl
            onChainTokenID
        }
    }
`;
export const updateListing = gql`
    mutation updateListing($input: UpdateListingInput!) {
        updateListing(input: $input) {
            collectionId
            artistId
            name
            description
            startDate
            endDate
            asset {
                id
                slug
                assetID
                asset {
                    id
                }
                name
                description
                isCurrent
                cdnUrl
                arweaveTx
            }
        }
    }
`;

export const getDropListings = gql`
    query ($collectionItemId: UUID1!) {
        getDropListingById(collectionItemId: $collectionItemId) {
            collectionId
            artistId
            name
            description
            startDate
            endDate
            name
            saleType
            marketplaceCollectionItemID
            allowListID
            asset {
                id
                slug
                assetID
                asset {
                    id
                }
                name
                description
                isCurrent
                cdnUrl
                arweaveTx
            }
            tokenDetails {
                marketplaceCollectionItemID
                marketplaceTokenID
                nftTokenId
                nftName
                edition
                status
                tokenType
                contractAddress
                totalUnits
                totalAvailableUnits
                unitPrice
                startingBid
                reservePrice
                startDate
                endDate
                lotID
            }
        }
    }
`;

export const getmarketplaces = gql`
    query GetMarketplaces($id: UUID!) {
        marketplace(id: $id) {
            id
            name
            organizationID
            isSecondaryMarketPlace
            platformFeeAddress
            platformFee
            collections {
                id
                marketplaceID
                name
                description
                status
                startDate
                endDate
                collectionType
                slug
                items {
                    id
                    name
                    collectionId
                }
            }
        }
    }
`;

export const deployOnChainBuyNowMutation = gql`
    mutation DeployOnchainBuynowSale($lotId: UUID1!) {
        DeployOnchainBuynowSale(lotId: $lotId)
    }
`;

export const setApprovalForAllMutation = gql`
    mutation setApprovalForAllMutation(
        $nftContractID: UUID1!
        $approvalAddress: String!
        $lotId: UUID1
    ) {
        setApprovalForAll(
            nftContractID: $nftContractID
            approvalAddress: $approvalAddress
            lotId: $lotId
        ) {
            approvalAddress
        }
    }
`;

export const createOnChainAuctionMutation = gql`
    mutation createOnChainAuctionMutation($lotID: UUID1!, $orgID: UUID1!) {
        createOnChainAuction(lotID: $lotID, orgID: $orgID)
    }
`;

export const registerAuctionExtensionMutation = gql`
    mutation registerAuctionExtensionMutation(
        $contractAddress: String!
        $networkID: UUID1!
        $orgID: UUID1!
        $extensionAddress: String
        $lotId: UUID1
    ) {
        registerAuctionExtension(
            contractAddress: $contractAddress
            networkID: $networkID
            orgID: $orgID
            extensionAddress: $extensionAddress
            lotId: $lotId
        )
    }
`;

export const saveOnchainAuctionMutation = gql`
    mutation saveOnchainAuctionMutation(
        $lotID: UUID1
        $orgID: UUID1!
        $input: MarketplaceAuctionOnChainSettingInput!
        $lotInput: DutchAuctionLot
    ) {
        saveOnchainAuction(
            lotID: $lotID
            orgID: $orgID
            input: $input
            lotInput: $lotInput
        ) {
            id
            marketplaceAuctionLotID
            onchainAuctionCollectionItemID
            onChainAuctionContractAddress
            AuctionContractVersion {
                contractABIFileURL
            }
        }
    }
`;

export const getContractSettlementDetailsQuery = gql`
    query getMarketplaceContractSettlementDetailsQuery($marketplaceId: UUID1!) {
        getMarketplaceContractSettlementDetails(marketplaceId: $marketplaceId) {
            taxSettlementAddress
            platformSettlementAddress
            platformFeePercentage
            commissionAddress
            commissionFeePercentage
        }
    }
`;

export const onChainEndAuctionMutation = gql`
    mutation onChainEndAuctionMutation(
        $lotID: UUID1!
        $orgID: UUID1!
        $endTxHash: String
    ) {
        onChainEndAuction(lotID: $lotID, orgID: $orgID, endTxHash: $endTxHash)
    }
`;

export const onChainCancelAuctionMutation = gql`
    mutation onChainCancelAuctionMutation(
        $lotID: UUID1!
        $orgID: UUID1!
        $cancelTxHash: String
    ) {
        onChainCancelAuction(
            lotID: $lotID
            orgID: $orgID
            cancelTxHash: $cancelTxHash
        )
    }
`;

export const onChainCancelBuyNowMutation = gql`
    mutation cancelOnchainBuynowSale(
        $lotId: UUID1!
        $orgID: UUID1!
        $cancelTxHash: String
    ) {
        cancelOnchainBuynowSale(
            lotId: $lotId
            orgID: $orgID
            cancelTxHash: $cancelTxHash
        )
    }
`;

export const updateSaleListingStatusMutation = gql`
    mutation updateSaleListingStatusMutation(
        $orgId: UUID1!
        $txHash: String!
        $Status: ContractStatus!
        $lotId: UUID1!
        $saleType: AuctionSaleType!
        $saleDeploymentType: SaleDeploymentTypeV2!
        $contractId: UUID1
    ) {
        UpdateSaleListingStatus(
            orgId: $orgId
            txHash: $txHash
            Status: $Status
            lotId: $lotId
            saleType: $saleType
            saleDeploymentType: $saleDeploymentType
            contractId: $contractId
        )
    }
`;

export const addOnchainClaimExtensionMutation = gql`
    mutation addOnchainClaimExtensionMutation(
        $nftContractID: UUID1!
        $maxCap: Int!
        $isClaimable: Boolean!
    ) {
        addOnchainClaimExtension(
            nftContractID: $nftContractID
            maxCap: $maxCap
            isClaimable: $isClaimable
        )
    }
`;

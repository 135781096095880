import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from './submenu.styles';
import { MixTheme, useStyle } from '@styles';
import { useRouter } from 'next/router';
import { RouterPath, ToastTypes } from '@constants';
import Link from 'next/link';
import { useStore } from '@state/StoreProvider';
import { marketplaceCollectionsData } from '@interface/Drops';
import { ModalDialog, Toasttypes } from '@components/Atoms';
import { useMutation } from '@apollo/client';
import { createDropQuery } from '@graphql/queries/drops';
import { useToast } from '@state/ToastProvider';
import { useLoading } from '@state/LoadingProvider';
import { useFormik } from 'formik';
import DropModal from '@layouts/drops/DropModal';
import { object, string } from 'yup';
import { useGlobalState } from '@state/GlobalContext';

export const SecondaryMarketPlace = [
    {
        name: 'Overview',
        path: RouterPath.overview
    },
    {
        name: 'Market Activity',
        path: RouterPath.marketActivity
    },
    {
        name: 'Listing Registry',
        path: RouterPath.listingRegistry
    }
];

export interface sortProps {
    column: string;
    type: string;
}

const DropsCollectionsSubMenuLayout = () => {
    const { styles } = useStyle(makeStyles);
    const theme: MixTheme = useTheme();
    const router = useRouter();
    const { initDropsListData, setSkipRequests, skipRequests, marketPlaceId, dropsFilter } = useStore();
    const { setData: setGlobalData } = useGlobalState();
    const [activeItem, setActiveItem] = useState<string>('');
    const { id } = router.query;
    const { setMessage, setShowToast, setToastType } = useToast();
    const { setLoading } = useLoading();

    const [openDropModal, setOpenDropModal] = useState<boolean>(false);

    useEffect(() => {
        setGlobalData((prev) => ({
            ...prev,
            isRefetchDrops: true
        }));
    }, [setGlobalData]);

    useEffect(() => {
        if (skipRequests.meQuery || skipRequests.dropsListQuery || skipRequests.organizationQuery)
            setSkipRequests({
                ...skipRequests,
                dropsListQuery: false,
                meQuery: false,
                organizationQuery: false
            });
    }, [skipRequests, setSkipRequests]);

    useEffect(() => {
        id && setActiveItem(id.toString());
    }, [id]);

    const isDropList = useMemo(() => router.pathname == RouterPath.drops, [router]);

    const newDrop = useMemo(() => router?.pathname?.includes('drops'), [router]);

    const changeSort = useCallback((getValue: sortProps) => {
        return getValue?.column + '-' + getValue?.type;
    }, []);

    const handleClick = useCallback(
        (currentId: string) => {
            setActiveItem(currentId);
            router.push({
                pathname: RouterPath.dropDetail,
                query: {
                    id: currentId,
                    p: dropsFilter?.offset / dropsFilter?.limit + 1,
                    sort: changeSort(dropsFilter?.sort),
                    filter: dropsFilter?.filters,
                    searchKey: dropsFilter?.searchKey
                }
            });
        },
        [
            dropsFilter?.filters,
            dropsFilter?.limit,
            dropsFilter?.offset,
            dropsFilter?.sort,
            dropsFilter?.searchKey,
            router,
            changeSort
        ]
    );

    const handleSecondaryMarketTab = useCallback(
        (item: string) => {
            router.push(item ?? RouterPath.root);
        },
        [router]
    );

    // CREATE NEW DROP QUERY

    const [createDropData] = useMutation(createDropQuery);

    const createDropCall = useCallback(
        async (values: any) => {
            try {
                if (values?.createDropName && values?.createDropType) {
                    setLoading(true);
                    const response = await createDropData({
                        variables: {
                            data: {
                                marketplaceID: marketPlaceId,
                                type: values?.createDropType,
                                dropName: values?.createDropName,
                                status: values?.status
                            }
                        }
                    });
                    setOpenDropModal(false);
                    setLoading(false);
                    setMessage('Drop Created');
                    setToastType(ToastTypes.SUCCESS as Toasttypes);
                    setShowToast(true);
                    formik?.resetForm();
                    router.push({
                        pathname: RouterPath.dropSettings,
                        query: {
                            id: response?.data?.createDrop,
                            p: dropsFilter?.offset / dropsFilter?.limit + 1,
                            sort: changeSort(dropsFilter?.sort),
                            filter: dropsFilter?.filters,
                            searchKey: dropsFilter?.searchKey
                        }
                    });
                }
            } catch (e: any) {
                setLoading(false);
                setShowToast(true);
                setToastType(ToastTypes.ERROR as Toasttypes);
                setMessage(
                    e?.message ==
                        'error creating new marketplace collection: ERROR: duplicate key value violates unique constraint "idx_slug_marketplace_collection" (SQLSTATE 23505)'
                        ? 'Dropname is already exists'
                        : e.message
                );
            }
        },
        [
            setLoading,
            createDropData,
            marketPlaceId,
            setMessage,
            setToastType,
            setShowToast,
            formik,
            router,
            dropsFilter?.offset,
            dropsFilter?.limit,
            dropsFilter?.sort,
            dropsFilter?.filters,
            dropsFilter?.searchKey,
            changeSort
        ]
    );

    const DropCreateSchema = object().shape({
        createDropName: string()
            .required('Dropname is Required')
            .trim()
            .min(1, 'Minimum 1 character is required')
            .max(100, 'Maximum 100 character allowed'),
        createDropType: string().required('DropType is required').trim()
    });

    const formik = useFormik({
        initialValues: {
            createDropName: '',
            createDropType: '',
            status: 'Active'
        },
        validateOnMount: false,
        validateOnChange: true,
        validationSchema: DropCreateSchema,
        onSubmit: createDropCall
    });

    const getStyle = useCallback(
        (path: string) => {
            if (router.pathname == path || router.pathname.includes(path)) {
                return { box: styles.BoxText, typo: styles.typoSelectedText };
            }
            return { box: styles.normalBoxText, typo: styles.typoText };
        },
        [router, styles]
    );

    const closeDialog = () => {
        formik.resetForm();
        setOpenDropModal(false);
    };

    return (
        <Stack padding={'16px 20px'}>
            <Typography
                variant="h3"
                style={{
                    marginBottom: '16px',
                    fontWeight: 700,
                    fontSize: '32px'
                }}
            >
                Marketplace
            </Typography>
            <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'8px'}
            >
                <Typography
                    variant="subtitle2"
                    style={{
                        fontWeight: 700,
                        fontSize: '12px',
                        color: theme?.color?.slate600,
                        textTransform: 'uppercase'
                    }}
                >
                    Drops
                </Typography>
                {newDrop && (
                    <Typography
                        variant="h6"
                        onClick={() => setOpenDropModal(true)}
                        style={{
                            fontSize: '12px',
                            fontWeight: 700,
                            color: theme?.color?.slate600,
                            cursor: 'pointer'
                        }}
                    >
                        +&nbsp;&nbsp;New
                    </Typography>
                )}
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    marginBottom: '8px',
                    height:
                        initDropsListData?.marketplaceDropV2?.marketplaceCollection === undefined
                            ? '40px'
                            : '300px',

                    scrollbarWidth: 'thin',
                    '&::-webkit-scrollbar': {
                        width: '0.4em'
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888'
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#555'
                    }
                }}
            >
                <Link href={RouterPath.drops}>
                    <Box sx={isDropList ? styles.BoxText : styles.normalBoxText}>
                        <Typography
                            variant="body2"
                            sx={isDropList ? styles.typoSelectedText : styles.typoText}
                            onClick={() => setActiveItem('')}
                        >
                            All Drops
                        </Typography>
                    </Box>
                </Link>
                {initDropsListData &&
                    initDropsListData?.marketplaceDropV2?.marketplaceCollection?.map(
                        (item: marketplaceCollectionsData) => (
                            <Box
                                onClick={() => handleClick(item.id)}
                                sx={
                                    activeItem == item.id && !isDropList
                                        ? styles.BoxText
                                        : styles.normalBoxText
                                }
                                key={item.id}
                            >
                                <Typography
                                    variant="body2"
                                    sx={
                                        activeItem == item.id && !isDropList
                                            ? styles.typoSelectedText
                                            : styles.typoText
                                    }
                                >
                                    {item.name ? item.name : '-'}
                                </Typography>
                            </Box>
                        )
                    )}
            </Box>
            <Stack flexDirection={'column'} sx={{ marginTop: '7px' }}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        ...styles.dropSecond,
                        fontSize: '12px',
                        padding: '7px 0'
                    }}
                >
                    SECONDARY MARKET
                </Typography>
            </Stack>
            <Stack flexDirection={'column'} gap={'0.4rem'}>
                {SecondaryMarketPlace.map((data: any) => {
                    return (
                        <Box sx={() => getStyle(data?.path).box} key={data?.path}>
                            <Typography
                                variant="body2"
                                sx={() => getStyle(data?.path).typo}
                                onClick={() => handleSecondaryMarketTab(data?.path)}
                            >
                                {data?.name}
                            </Typography>
                        </Box>
                    );
                })}
            </Stack>
            {openDropModal && (
                <ModalDialog
                    title={
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '20px',
                                fontFamily: theme?.font?.tertiary
                            }}
                        >
                            Create a New drops
                        </Typography>
                    }
                    open={openDropModal}
                    onClose={closeDialog}
                    onCancel={closeDialog}
                    submitButtonText={'Continue'}
                    isSubmitEnabled
                    onSubmit={() => formik.handleSubmit()}
                    body={<DropModal formik={formik} />}
                />
            )}
        </Stack>
    );
};

export default DropsCollectionsSubMenuLayout;

import { Box } from '@mui/material';
import { Drawer as MUIDrawer, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import MenuItem from './MenuItem';
import Image from 'next/image';

type DrawerProps = {
    open: boolean;
    children?: React.ReactNode;
    selectedMenu: string;
    topMenuItem: MenuItemOption[];
    bottomMenuItem: MenuItemOption[];
    hideSubMenu: boolean;
    onChange: (title: string, event: React.MouseEvent<HTMLElement>) => void;
    setContainerWidth: React.Dispatch<React.SetStateAction<string>>;
};

const drawerWidth = 300;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    drawer: {
        width: drawerWidth,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    drawerHide: {
        width: 210,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    mainContainer: {
        transition: 'width 0.3s',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        borderWidth: '0 1px 0 1px',
        borderColor: '#e0e0e0',
        borderStyle: 'solid',
        width: '100%'
    },
    topMenuContainer: {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    topMenuItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    bottomMenuContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    collapesExpanded: {
        width: '70px'
    }
}));

export interface MenuItemOption {
    id: string;
    src: string;
    href: string;
    disabled?: boolean;
    name?: string;
    tooltipName?: string;
}

const Drawer = ({
    open,
    selectedMenu,
    topMenuItem,
    bottomMenuItem,
    children,
    hideSubMenu,
    onChange,
    setContainerWidth
}: DrawerProps) => {
    const styles = useStyles({
        open,
        onlyMobile: true,
        minWidth: open ? '100%' : '0',
        display: open ? 'flex' : 'none'
    });

    const [collapse, setCollapse] = useState<boolean>(true);

    // useEffect(()=> {
    //     if (selectedMenu == 'dashboard') {
    //         setCollapse(true)
    //     }
    // }, [selectedMenu])

    useEffect(() => {
        if (collapse) {
            if (hideSubMenu) {
                setContainerWidth('210px');
            } else {
                setContainerWidth(`${drawerWidth}px`);
            }
        } else {
            setContainerWidth('70px');
        }
    }, [setContainerWidth, hideSubMenu, collapse]);

    return (
        <MUIDrawer
            className={
                collapse
                    ? hideSubMenu
                        ? styles.drawerHide
                        : styles.drawer
                    : styles.collapesExpanded
            }
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: collapse
                    ? hideSubMenu
                        ? styles.drawerHide
                        : styles.drawerPaper
                    : styles.collapesExpanded
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    height: '100%'
                }}
            >
                <Box
                    style={{
                        position: 'fixed',
                        marginTop: '3rem',
                        cursor: 'pointer',
                        marginLeft: collapse
                            ? hideSubMenu && styles.drawerPaper
                                ? '12.5rem'
                                : '18rem'
                            : '3.7rem',

                        overflow: 'hidden'
                    }}
                >
                    {!collapse ? (
                        <Image
                            src={'/images/icons/LeftNavbar.svg'}
                            onClick={() => setCollapse(true)}
                            width={20}
                            height={20}
                        />
                    ) : (
                        <Image
                            src={'/images/icons/RightNavbar.svg'}
                            onClick={() => setCollapse(false)}
                            width={20}
                            height={20}
                        />
                    )}
                </Box>
                <Box
                    className={styles.topMenuContainer}
                    sx={{
                        alignItems: hideSubMenu ? 'start' : 'center',
                        width: '40px',
                        margin: collapse ? '12px 12px 12px 20px' : '12px'
                    }}
                >
                    <Box
                        className={styles.topMenuItem}
                        sx={{ alignItems: 'center' }}
                    >
                        {topMenuItem.map((item, index) => (
                            <MenuItem
                                collapse={collapse}
                                key={`${index}`}
                                id={item.id}
                                src={item.src}
                                href={item.href}
                                isSelected={item.id === selectedMenu}
                                disabled={item.disabled}
                                onClick={onChange}
                                isDashboard={hideSubMenu}
                                name={item?.name ?? ''}
                                tooltip={item?.tooltipName ?? ''}
                            />
                        ))}
                    </Box>
                    <Box
                        className={styles.bottomMenuContainer}
                        sx={{
                            alignItems: 'center'
                        }}
                    >
                        {bottomMenuItem.map((item, index) => (
                            <MenuItem
                                key={`${index}`}
                                collapse={collapse}
                                id={item.id}
                                src={item.src}
                                href={item.href}
                                isSelected={item.id === selectedMenu}
                                disabled={item.disabled}
                                onClick={onChange}
                                isDashboard={hideSubMenu || !collapse}
                                name={item?.name ?? ''}
                                tooltip={item?.tooltipName ?? ''}
                            />
                        ))}
                    </Box>
                </Box>
                {!hideSubMenu && collapse && (
                    <Box className={styles.mainContainer}>{children}</Box>
                )}
            </Box>
        </MUIDrawer>
    );
};

export default Drawer;

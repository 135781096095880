import React, { useMemo } from 'react';
import { ToastTypes } from '@constants';
import {
    Alert,
    Snackbar,
    SnackbarOrigin,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import { MixTheme } from '@styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

export enum Toasttypes {
    SUCCESS = 'success',
    WARNING = 'warning',
    INFO = 'info',
    ERROR = 'error',
    MESSAGE = 'message'
}

interface ToastProps {
    type: Toasttypes | undefined;
    message: string | React.ReactNode;
    renderButton?: React.ReactNode;
    onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
    isOpen: boolean;
    position?: SnackbarOrigin;
}

export const Toast = ({
    type,
    message,
    renderButton,
    isOpen,
    onClose,
    position = { vertical: 'top', horizontal: 'right' }
}: ToastProps) => {
    const theme: MixTheme = useTheme();
    const toastType = useMemo(() => {
        if (type === ToastTypes.SUCCESS) {
            return {
                icon: <CheckCircleIcon sx={{ color: theme?.color?.green }} />,
                background: theme?.color?.statusDeployed
            };
        } else if (type === ToastTypes.INFO) {
            return {
                icon: <InfoIcon sx={{ color: theme?.global?.hover }} />,
                background: theme?.color?.warningBackground
            };
        } else if (type === ToastTypes.WARNING) {
            return {
                icon: <WarningIcon sx={{ color: theme?.global?.warning }} />,
                background: theme?.global?.hoverBackground
            };
        } else if (type === ToastTypes.ERROR) {
            return {
                icon: <ErrorIcon sx={{ color: theme?.color?.error }} />,
                background: theme?.color?.danger
            };
        } else if (type === ToastTypes.MESSAGE) {
            return {
                icon: null,
                background: theme?.global?.darkIndico
            };
        }
    }, [type, theme]);
    return (
        <Snackbar
            onClose={onClose}
            open={isOpen}
            autoHideDuration={5000}
            anchorOrigin={position}
            sx={{ width: 'auto', maxWidth: '50%' }}
        >
            <Alert
                onClose={onClose}
                icon={toastType?.icon ?? ''}
                sx={{
                    backgroundColor: `${toastType?.background} !important`,
                    alignItems: 'center',
                    display: 'flex',
                    color: `${type === ToastTypes.MESSAGE ? theme?.color?.white : theme?.color?.black} !important`
                }}
                variant="filled"
            >
                <Stack
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Typography sx={{ marginRight: renderButton ? 2 : null }}>
                        {message}
                    </Typography>
                    {renderButton && renderButton}
                </Stack>
            </Alert>
        </Snackbar>
    );
};

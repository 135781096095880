import React, { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Grid } from '@mui/material';

export const Breadcrumbs = () => {
    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();
            console.info('You clicked a breadcrumb.');
        },
        []
    );

    return (
        <Grid onClick={handleClick} sx={{ padding: '15px' }}>
            <MUIBreadcrumbs
                aria-label="breadcrumb"
                sx={{
                    fontWeight: '600',
                    color: '#000000',
                    fontFamily: 'inherit'
                }}
            >
                <Link underline="hover" color="inherit" href="/">
                    MUI
                </Link>
                <Link underline="hover" color="inherit" href="/">
                    Core
                </Link>
                <Typography sx={{ fontFamily: 'inherit', fontWeight: '600' }}>
                    Breadcrumbs
                </Typography>
            </MUIBreadcrumbs>
        </Grid>
    );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import { PaperEmbeddedWalletSdk } from '@paperxyz/embedded-wallet-service-sdk';
import { useConnectWallet } from '@mojito-inc/core-service';
import { useAccount, useBalance, useNetwork, useWalletClient, useDisconnect, useSignMessage } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { ConnectWalletLayout } from '../layout/index';
import { BalanceData, ConnectWalletProps, ModalType, SupportedNetworksData, WalletDetailsData } from '../interface';
import { emailRegex, ErrorsType, SessionVariable, WalletProviderType } from '../constant';
import { session } from '../utils/sessionStorage.utils';
import useWalletConnect from '../hooks/useConnectWallet';
import { getBalance } from '../utils/getBalance.utils';
import { getWalletProvider } from '../utils/getProviderByChainId.utils';
import { getChainId } from '../utils/getChainId.utils';
import { getWalletConnectProvider } from '../utils/getWalletConnectProvider.utils';
import { truncateDecimal } from '../utils/truncateDecimal.utils';

const ConnectWalletContainer = ({
  open,
  isWeb2Login,
  walletOptions,
  config,
  isDisConnect,
  image,
  content,
  isRefetchBalance,
  skipSignature,
  onChangeWalletAddress,
  onCloseModal
}: ConnectWalletProps) => {
  let paperClient: any = null;

  if (typeof window !== 'undefined' && config.paperClientId && config.paperNetworkName) {
    const paper = new PaperEmbeddedWalletSdk({
      clientId: config.paperClientId,
      chain: config.paperNetworkName
    });
    paperClient = paper;
  }

  const { getSignature, loginWithWallet, isTokenGating } = useWalletConnect({ orgId: config?.orgId });
  const { connectExternalWallet } = useConnectWallet();
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { data: balanceData, isFetching, isSuccess, isLoading } = useBalance({
    address,
    chainId: chain?.id
  });
  const { data: walletClient } = useWalletClient({ chainId: chain?.id });

  const { open: openWalletModal, close: closeWalletModal } = useWeb3Modal();
  const { disconnectAsync } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const walletDetails = session(SessionVariable.WalletDetails, true);

  const [loaderTitle, setLoaderTitle] = useState<string>('');
  const [modalState, setModalState] = useState(
    ModalType.CONNECT_WALLET
  );
  const [error, setError] = useState('');
  const [email, setEmail] = useState<string>('');
  const [otp, setOTP] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [newDevice, setIsNewDevice] = useState<boolean>(false);
  const [newUser, setIsNewUser] = useState<boolean>(false);
  const [recoveryCode, setRecoveryCode] = useState<string>('');
  const [isAuthToken, setIsAuthToken] = useState<boolean>(false);
  const [connectParam, setConnectParam] = useState({
    signatureMessage: '',
    walletAddress: '',
    signature: '',
    networkId: ''
  });
  const [wallet, setWalletData] = useState<WalletDetailsData>();

  const walletConnect = useRef<null | boolean>(null);

  const onDisConnect = useCallback(async () => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.removeItem(SessionVariable.WalletDetails);
    }
    if (walletDetails?.providerType === WalletProviderType.WALLET_CONNECT && isConnected) {
      await disconnectAsync();
    }
    if (walletDetails?.providerType === WalletProviderType.EMAIL) {
      await paperClient.auth.logout();
    }
    setOTP('');
    setEmail('');
    setRecoveryCode('');
    setIsNewDevice(false);
    setLoaderTitle('');
    setError('');
    setModalState(ModalType.CONNECT_WALLET);
  }, [isConnected, paperClient, walletDetails, disconnectAsync]);

  const fetchBalance = useCallback(async () => {
    if (walletDetails?.walletAddress) {
      let provider = null;
      if (walletDetails?.providerType === WalletProviderType.EMAIL) {
        provider = walletDetails?.provider;
      } else if (walletDetails?.providerType === WalletProviderType.WALLET_CONNECT) {
        provider = walletClient ? getWalletConnectProvider(walletClient) : undefined;
      } else {
        provider = getWalletProvider();
      }
      const balance = await getBalance(provider, walletDetails?.walletAddress, walletDetails?.networkDetails?.chainID, walletDetails?.providerType);
      const walletData: WalletDetailsData = {
        walletAddress: walletDetails?.walletAddress,
        providerType: walletDetails?.providerType,
        networkDetails: {
          id: walletDetails?.networkDetails?.id,
          chainID: walletDetails?.networkDetails?.chainID,
          name: walletDetails?.networkDetails?.name,
          isTestnet: walletDetails?.networkDetails?.isTestnet
        },
        balance: {
          native: balance?.native ?? 0,
          nonNative: balance?.nonNative ?? 0
        },
        provider: walletDetails?.provider
      };
      setWalletData(walletData);
      session(SessionVariable.WalletDetails, false, walletData);
      if (onChangeWalletAddress) {
        onChangeWalletAddress(walletData);
      }
    }
  }, [walletDetails, walletClient, onChangeWalletAddress]);

  useEffect(() => {
    if (isDisConnect) {
      onDisConnect();
    }
  }, [isDisConnect, onDisConnect]);

  useEffect(() => {
    if (isRefetchBalance) {
      fetchBalance();
    }
  }, [isRefetchBalance, fetchBalance]);

  const getNetworkDetailsByChainId = useCallback((networkData: SupportedNetworksData[], chainId: number) => {
    const response = networkData.filter((item: SupportedNetworksData) => item.chainID === chainId);
    return response?.[0];
  }, []);

  const connectWallet = useCallback(async () => {
    try {
      const walletResponse = await connectExternalWallet({
        address: connectParam.walletAddress,
        message: connectParam.signatureMessage,
        signature: connectParam.signature,
        orgID: config.orgId,
        networkID: connectParam.networkId
      });
      const updateProvider = {
        ...wallet, provider: null
      };
      session(SessionVariable.WalletDetails, false, updateProvider);
      if (onChangeWalletAddress && wallet) {
        onChangeWalletAddress(wallet);
      }
      setModalState(ModalType.CONNECT_WALLET);
      setLoaderTitle('');
      setIsNewDevice(false);
      setOpenModal(false);
      onCloseModal(true);
      return { status: true, message: walletResponse?.data?.connectExternalWallet };
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      if (e?.message?.includes(ErrorsType.WRONG_WALLET)) {
        setError('For compliance & security reasons, we are allowing only one active wallet for a user. Please disconnect your wallet on another account and retry or contact support.');
      } else if (e?.message?.includes(ErrorsType.HIGH_RISK)) {
        setError('You are not permitted to complete this transaction.');
      } else {
        setError('We are unable to connect your wallet');
      }
      return { status: false, message: e?.message };
    }
  }, [config, connectParam, wallet, connectExternalWallet, onCloseModal, onChangeWalletAddress]);

  useEffect(() => {
    if (isAuthToken) {
      connectWallet();
      setIsAuthToken(false);
    }
  }, [isAuthToken, connectWallet]);

  const getSignatureData = useCallback(async (provider: any, message: string, providerType: string) => {
    try {
      if (providerType === WalletProviderType.EMAIL) {
        const signature = await provider.signMessage(message);
        return {
          status: true,
          message: signature
        };
      }
      if (providerType === WalletProviderType.WALLET_CONNECT) {
        const signature = await signMessageAsync({ message });
        return {
          status: true,
          message: signature
        };
      }
      const signer = provider?.getSigner();
      const signature = await signer?.signMessage(message);
      return {
        status: true,
        message: signature
      };
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      if (typeof window !== 'undefined') {
        window.sessionStorage.removeItem(SessionVariable.WalletDetails);
        onChangeWalletAddress?.({
          walletAddress: '',
          balance: {
            native: 0,
            nonNative: 0
          },
          networkDetails: {
            chainID: 0,
            id: '',
            isTestnet: false,
            name: ''
          },
          providerType: '',
          provider: null
        });
      }
      if (e?.message?.toLowerCase()?.includes('user rejected') || e?.message?.toLowerCase()?.includes('user denied')) {
        setError('Transaction has been cancelled by user. Please try again.');
        return {
          status: false,
          message: 'Transaction has been cancelled by user. Please try again.'
        };
      }
      setError('Something went wrong');
      return {
        status: false,
        message: 'Something went wrong'
      };
    }
  }, [onChangeWalletAddress, signMessageAsync]);

  const getSignatureMessage = useCallback(
    async (
      connectedAddress: string,
      provider: any,
      chainId: number,
      providerType: string,
      balance: BalanceData
    ) => {
      try {
        setConnectParam({
          signatureMessage: '',
          walletAddress: '',
          signature: '',
          networkId: ''
        });
        const networkData = session(SessionVariable.NetworkDetails, true);
        if (chainId && networkData) {
          const networkDetails = getNetworkDetailsByChainId(networkData, chainId);
          if (networkDetails?.id) {
            const signatureMsg = await getSignature(
              connectedAddress,
              networkDetails.id
            );
            if (signatureMsg?.status) {
              const signature = await getSignatureData(
                provider,
                signatureMsg.message,
                providerType
              );
              if (signature?.status) {
                if (isWeb2Login) {
                  const walletData: WalletDetailsData = {
                    walletAddress: connectedAddress,
                    networkDetails,
                    providerType: providerType ?? '',
                    balance: {
                      native: balance?.native ?? 0,
                      nonNative: balance?.nonNative ?? 0
                    },
                    provider
                  };
                  setWalletData(walletData);
                  setConnectParam({
                    networkId: networkDetails.id,
                    signature: signature.message,
                    signatureMessage: signatureMsg.message,
                    walletAddress: connectedAddress
                  });
                  setIsAuthToken(true);
                  return;
                }
                const walletResponse = await loginWithWallet(
                  signatureMsg.message,
                  connectedAddress,
                  signature?.message,
                  chainId
                );
                const walletData: WalletDetailsData = {
                  walletAddress: connectedAddress,
                  networkDetails,
                  providerType: providerType ?? '',
                  balance: {
                    native: balance?.native ?? 0,
                    nonNative: balance?.nonNative ?? 0
                  },
                  provider:
                    providerType === WalletProviderType.EMAIL ? provider : null
                };
                setWalletData(walletData);
                if (walletResponse?.status) {
                  session(
                    SessionVariable.AuthToken,
                    false,
                    walletResponse.message
                  );
                  setConnectParam({
                    networkId: networkDetails.id,
                    signature: signature?.message,
                    signatureMessage: signatureMsg.message,
                    walletAddress: connectedAddress
                  });
                  setIsAuthToken(true);
                }
                return;
              }
              setLoaderTitle('');
              setModalState(ModalType.ERROR);
              return;
            }
            setLoaderTitle('');
            setModalState(ModalType.ERROR);
          } else {
            setModalState(ModalType.ERROR);
            setError(
              `Invalid network detected. Please Change to valid network (${ String(
                networkData.map((item: SupportedNetworksData) => item.name)
              ).replaceAll(',', ', ') })`
            );
          }
        }
      } catch (e: any) {
        setLoaderTitle('');
        setModalState(ModalType.ERROR);
        if (
          e?.message?.toLowerCase()?.includes('user rejected') ||
          e?.message?.toLowerCase()?.includes('user denied')
        ) {
          setError('Transaction has been cancelled by user. Please try again.');
        } else {
          setError('Something went wrong');
        }
      }
    },
    [getSignature, loginWithWallet, getNetworkDetailsByChainId, getSignatureData, isWeb2Login]
  );

  const connectionSuccess = useCallback((connectedAddress: string, provider: any, chainId: number, providerType: string, balance: BalanceData) => {
    try {
      const networkData = session(SessionVariable.NetworkDetails, true);
      const networkDetails = getNetworkDetailsByChainId(networkData, chainId);
      const walletData: WalletDetailsData = {
        walletAddress: connectedAddress,
        networkDetails,
        providerType: providerType ?? '',
        balance: {
          native: balance?.native ?? 0,
          nonNative: balance?.nonNative ?? 0
        },
        provider
      };

      const sessionData: WalletDetailsData = {
        walletAddress: connectedAddress,
        networkDetails,
        providerType: providerType ?? '',
        balance: {
          native: balance?.native ?? 0,
          nonNative: balance?.nonNative ?? 0
        },
        provider: null
      };
      setWalletData(walletData);
      if (onChangeWalletAddress && walletData?.walletAddress) {
        onChangeWalletAddress(walletData);
        session(SessionVariable.WalletDetails, false, sessionData);
      }
      setModalState(ModalType.CONNECT_WALLET);
      setLoaderTitle('');
      setIsNewDevice(false);
      setOpenModal(false);
      onCloseModal(true);
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      setError(e?.message);
    }
  }, [getNetworkDetailsByChainId, onChangeWalletAddress, onCloseModal]);

  const verifyOTP = useCallback(async () => {
    try {
      setLoaderTitle('Processing...');
      setModalState(ModalType.LOADING);
      if (!newDevice || newUser) {
        await paperClient.auth.verifyPaperEmailLoginOtp({
          email,
          otp
        });
      } else {
        await paperClient.auth.verifyPaperEmailLoginOtp({
          email,
          otp,
          recoveryCode
        });
      }
      const userDetails: any = await paperClient.getUser();
      const chainId = getChainId(userDetails?.wallet?.chain);
      const signer = await userDetails?.wallet?.getEthersJsSigner();
      const balance = await getBalance(signer, userDetails?.walletAddress, chainId, WalletProviderType.EMAIL);
      if (skipSignature) {
        connectionSuccess(userDetails?.walletAddress, signer, chainId, WalletProviderType.EMAIL, balance);
        return;
      }
      if (userDetails?.walletAddress) {
        getSignatureMessage(userDetails?.walletAddress, signer, chainId, WalletProviderType.EMAIL, balance);
      }
    } catch (e: any) {
      const errorMessage = String(e);
      setError('Code is incorrect');
      if (errorMessage?.includes(ErrorsType.RECOVERY_CODE)) {
        setModalState(ModalType.RECOVERY_CODE);
      } else {
        setModalState(ModalType.OTP);
      }
    }
  }, [skipSignature, newDevice, email, otp, paperClient, recoveryCode, newUser, connectionSuccess, getSignatureMessage]);

  const onClickMetamask = useCallback(async () => {
    try {
      if (!isTokenGating) {
        setOpenModal(true);
        onChangeWalletAddress?.({
          walletAddress: '',
          balance: {
            native: 0,
            nonNative: 0
          },
          networkDetails: {
            chainID: 0,
            id: '',
            isTestnet: false,
            name: ''
          },
          providerType: WalletProviderType.METAMASK,
          provider: null
        });
        if (window && window.ethereum) {
          setLoaderTitle('Connecting wallet...');
          setModalState(ModalType.LOADING);
          const isAccount = async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const accounts = await provider.listAccounts();
            return accounts.length !== 0;
          };
          const accountStatus = await isAccount();
          if (!accountStatus) {
            const { ethereum } = window;
            await ethereum.request({ method: 'eth_requestAccounts' });
          }

          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const accounts = await provider.listAccounts();
          const { chainId } = await provider.getNetwork();
          const balance = await getBalance(provider, accounts?.[0], chainId, WalletProviderType.METAMASK);
          if (skipSignature) {
            connectionSuccess(accounts?.[0], provider, chainId, WalletProviderType.METAMASK, balance);
            return;
          }
          if (accounts?.[0]) {
            await getSignatureMessage(accounts?.[0], provider, chainId, WalletProviderType.METAMASK, balance);
          }
        } else {
          setLoaderTitle('');
          setModalState(ModalType.ERROR);
          setError('Meta mask not installed in your browser');
        }
      }
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      setError(e?.message);
    }
  }, [skipSignature, isTokenGating, onChangeWalletAddress, getSignatureMessage, connectionSuccess]);

  const onClickWalletConnect = useCallback(async () => {
    try {
      setOpenModal(false);
      if (isConnected) {
        if (typeof window !== 'undefined') {
          window.sessionStorage.removeItem(SessionVariable.WalletDetails);
        }
        await disconnectAsync();
      }
      onChangeWalletAddress?.({
        walletAddress: '',
        balance: {
          native: 0,
          nonNative: 0
        },
        networkDetails: {
          chainID: 0,
          id: '',
          isTestnet: false,
          name: ''
        },
        providerType: WalletProviderType.WALLET_CONNECT,
        provider: null
      });
      openWalletModal({ view: 'Connect' });
      onCloseModal(true);
      walletConnect.current = true;
    } catch (e: any) {
      walletConnect.current = null;
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      setError(e?.message);
    }
  }, [openWalletModal, onCloseModal, onChangeWalletAddress, disconnectAsync, isConnected]);

  const onWalletConnect = useCallback(async (connectedAddress: string, connectedChainId: number, balanceNative: string) => {
    walletConnect.current = null;
    try {
      closeWalletModal();
      setOpenModal(true);
      setLoaderTitle('Connecting wallet...');
      setModalState(ModalType.LOADING);
      if (walletClient && connectedAddress && connectedChainId) {
        const walletProvider = getWalletConnectProvider(walletClient);
        const balance = await getBalance(walletProvider, connectedAddress, connectedChainId, WalletProviderType.WALLET_CONNECT);
        if (skipSignature) {
          connectionSuccess(
            connectedAddress,
            walletProvider,
            connectedChainId,
            WalletProviderType.WALLET_CONNECT,
            { native: +truncateDecimal(balanceNative), nonNative: balance?.nonNative }
          );
          return;
        }
        await getSignatureMessage(
          connectedAddress,
          walletProvider,
          connectedChainId,
          WalletProviderType.WALLET_CONNECT,
          { native: +truncateDecimal(balanceNative), nonNative: balance?.nonNative }
        );
      } else {
        setLoaderTitle('');
        setModalState(ModalType.ERROR);
        setError('Something wrong');
      }
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      setError(e?.message);
    }
  }, [walletClient, skipSignature, getSignatureMessage, connectionSuccess, closeWalletModal]);

  useEffect(() => {
    if (isConnected && address && chain?.id && walletConnect.current && balanceData?.formatted && !isFetching && isSuccess && !isLoading) {
      onWalletConnect(address, chain?.id, balanceData?.formatted);
    }
  }, [isConnected, address, chain?.id, balanceData?.formatted, isFetching, isSuccess, isLoading, onWalletConnect]);

  useEffect(() => {
    if (walletDetails?.providerType === WalletProviderType.METAMASK) {
      window.ethereum?.on('accountsChanged', onClickMetamask);
      window.ethereum?.on('chainChanged', onClickMetamask);
      return () => {
        window.ethereum?.removeListener('chainChanged', onClickMetamask);
        window.ethereum?.removeListener('accountsChanged', onClickMetamask);
      };
    }
    return undefined;
  }, [onClickMetamask, walletDetails?.providerType]);

  useEffect(() => {
    if (open) {
      setError('');
      setModalState(ModalType.CONNECT_WALLET);
      setLoaderTitle('');
      setOTP('');
      setEmail('');
      setRecoveryCode('');
    }
  }, [open]);

  const onChangeOTP = useCallback(
    (event: string) => {
      setError('');
      setOTP(event);
    },
    []
  );

  const onChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setError('');
      setEmail(event.target.value);
    },
    []
  );

  const onChangeRecoveryCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setError('');
      setRecoveryCode(event.target.value);
    },
    []
  );

  const onClickContinueWithEmail = useCallback(async () => {
    try {
      setOpenModal(true);
      if (!email) {
        setError('Email is required');
        return;
      }
      if (emailRegex.test(email)) {
        onChangeWalletAddress?.({
          walletAddress: '',
          balance: {
            native: 0,
            nonNative: 0
          },
          networkDetails: {
            chainID: 0,
            id: '',
            isTestnet: false,
            name: ''
          },
          providerType: WalletProviderType.EMAIL,
          provider: null
        });
        setError('');
        setLoaderTitle('Processing...');
        setModalState(ModalType.LOADING);
        const { isNewUser, isNewDevice } = await paperClient.auth.sendPaperEmailLoginOtp({
          email
        });
        setIsNewDevice(isNewDevice);
        setIsNewUser(isNewUser);
        if (isNewDevice && !isNewUser) {
          setModalState(ModalType.RECOVERY_CODE);
        } else {
          setModalState(ModalType.OTP);
        }
      } else {
        setError('Please enter a valid email');
      }
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.OTP);
      setError(e?.message);
    }
  }, [email, paperClient, onChangeWalletAddress]);

  const handleRetry = useCallback(async () => {
    await onDisConnect();
  }, [onDisConnect]);

  const onClickRecoveryCode = useCallback(() => {
    if (!recoveryCode) {
      setError('Recovery code is required');
      return;
    }
    setModalState(ModalType.OTP);
  }, [recoveryCode]);

  const handleCloseModal = useCallback(() => {
    setModalState(ModalType.CONNECT_WALLET);
    setError('');
    setOpenModal(false);
    onCloseModal();
  }, [onCloseModal]);

  const onClickEmail = useCallback(() => {
    setModalState(ModalType.EMAIL);
  }, []);

  return (
    <ConnectWalletLayout
      open={ open || openModal }
      error={ error }
      email={ email }
      otp={ otp }
      recoveryCode={ recoveryCode }
      image={ image }
      walletOptions={ walletOptions }
      loaderTitle={ loaderTitle }
      currentModalState={ modalState }
      content={ content }
      onChangeEmail={ onChangeEmail }
      onChangeOTP={ onChangeOTP }
      onChangeRecoveryCode={ onChangeRecoveryCode }
      onClickContinueWithEmail={ onClickContinueWithEmail }
      onClickEmail={ onClickEmail }
      onClickVerifyOTP={ verifyOTP }
      onClickMetamask={ onClickMetamask }
      onClickWalletConnect={ onClickWalletConnect }
      onClickRecoveryCode={ onClickRecoveryCode }
      handleRetry={ handleRetry }
      onCloseModal={ handleCloseModal } />
  );
};

export default ConnectWalletContainer;

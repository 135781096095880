import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import React from 'react';
import { WagmiConfig } from 'wagmi';
import { mainnet, polygon, polygonMumbai, sepolia, goerli } from 'wagmi/chains';
import { MetaData } from '../interface';

const chains = [mainnet, polygon, polygonMumbai, sepolia, goerli];

const walletConnectMetadata = {
    name: 'Mojito',
    description: 'Mojito Wallet connect',
    url: 'https://verso.pacegallery.com/',
    icons: [
        'https://storage.googleapis.com/mojito-prod-public/organizations/68ef2bec-3d7c-4c39-bde4-4013a3974925/assets/Frame_238173_cpwne5.png'
    ]
};

interface WalletConnectProviderProps {
    children: React.ReactNode;
    projectId: string;
    metaData?: MetaData;
}

export const WalletConnectProvider = ({
    children,
    metaData,
    projectId
}: WalletConnectProviderProps) => {
    const wagmiConfig = defaultWagmiConfig({
        chains,
        projectId,
        metadata: metaData || walletConnectMetadata
    });

    createWeb3Modal({ wagmiConfig, projectId, chains });

    return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
};

export const truncateDecimal = (
    price: string | number = '',
    index = 4,
    removeEmptyDot = true
) => {
    // convert number to string
    let data = price.toString();

    // cutting the number.
    if (data.indexOf('.') > -1) {
        data = data.slice(0, data.indexOf('.') + (index + 1));
    }

    // If there is no decimal, remove the '.'
    if (removeEmptyDot && data.indexOf('.') === data.length - 1) {
        data = data.slice(0, data.indexOf('.'));
    }

    return data;
};

import React, {
    useCallback,
    useState,
    CSSProperties,
    useRef,
    useEffect
} from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Image from 'next/image';
import { MixTheme } from '@styles';

export interface UploadFileProps {
    name: string;
    onFileChange: (name: string, value: FileList | null) => void;
    label?: JSX.Element | string | undefined;
    boxStyle?: CSSProperties | undefined;
    isClickableFile?: boolean;
    showCancelIcon?: boolean;
    onClearImage?: () => void;
    imageURL?: string;
}

export const UploadFile = ({
    name,
    onFileChange,
    label,
    boxStyle,
    isClickableFile = true,
    showCancelIcon = false,
    imageURL,
    onClearImage
}: UploadFileProps) => {
    const theme: MixTheme = useTheme();
    const [imageState, setImageState] = useState<string>('');
    const [fileType, setFileType] = useState<string>('');
    const inputFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (imageURL) {
            setImageState(imageURL);
            const extension = imageURL.split('.').pop();
            const formattedExtension = extension?.includes('?')
                ? extension?.split('?')?.[0]
                : extension;
            setFileType(formattedExtension ?? '');
        }
    }, [imageURL]);

    const onChange = useCallback(
        (e) => {
            if (e.target.files.length > 0) {
                onFileChange(name, e.target.files);
                setImageState(URL.createObjectURL(e.target.files[0]));
                setFileType(e.target.files[0]);
            }
        },
        [name, onFileChange]
    );
    const onImageClick = useCallback(() => {
        inputFileRef?.current?.click();
    }, [inputFileRef]);

    const handleImageClick = useCallback(() => {
        const inputEvent = new Event('input', { bubbles: true });
        inputFileRef?.current?.dispatchEvent(inputEvent);
    }, [inputFileRef]);

    const handleClearImage = useCallback(() => {
        setImageState('');
        setFileType('');
        onClearImage?.();
    }, [onClearImage]);

    return (
        <Stack flexDirection={'row'}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{
                    width: '100%',
                    height: '100%',
                    border: !fileType ? '1px dashed #8A8AB9' : '',
                    borderRadius: '5px',
                    overflow: 'hidden'
                }}
            >
                <Box
                    component={'label'}
                    sx={{
                        display: fileType ? 'none' : 'flex',
                        padding: '35px 40px',
                        color: '#BABAD6',
                        fontSize: '18px',
                        background: '#FAFAFA',
                        cursor: 'pointer',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        ...boxStyle
                    }}
                >
                    {label ? label : '+'}
                    <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={onChange}
                        onClick={() =>
                            isClickableFile ? handleImageClick() : undefined
                        }
                        ref={inputFileRef}
                    />
                </Box>
                {fileType && (
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <Image
                            src={imageState}
                            width={'100%'}
                            height={'100%'}
                            style={{
                                cursor: isClickableFile ? 'pointer' : 'default'
                            }}
                            onClick={() =>
                                isClickableFile ? onImageClick() : undefined
                            }
                        />
                    </Box>
                )}
            </Stack>
            {showCancelIcon && imageState && (
                <Box sx={{ marginLeft: '4px' }}>
                    <CloseIcon
                        onClick={handleClearImage}
                        sx={{
                            color: theme.global?.indico,
                            alignSelf: 'center',
                            position: 'absolute',
                            cursor: 'pointer'
                        }}
                    />
                </Box>
            )}
        </Stack>
    );
};

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import parse from 'html-react-parser';

export interface ModalContentContainerProps {
    image?: string;
    title: string;
    subTitle?: string;
    description?: string;
}
const ModalContentContainer = ({
    image,
    title,
    subTitle,
    description
}: ModalContentContainerProps) => {
    return (
        <Box
            sx={{
                padding: '0 0 5px 0 ',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            {image && (
                <img
                    src={image}
                    style={{
                        marginBottom: '30px',
                        width: '130px',
                        height: '130px',
                        objectFit: 'contain'
                    }}
                    alt="containerImage"
                />
            )}
            {subTitle && (
                <Typography
                    component="h4"
                    align="center"
                    sx={{
                        fontWeight: 700,
                        fontSize: '12px',
                        marginBottom: '10px'
                    }}
                >
                    {subTitle}
                </Typography>
            )}
            <Typography
                component="h4"
                align="center"
                sx={{
                    fontWeight: 700,
                    fontSize: '24px',
                    marginBottom: '16px'
                }}
            >
                {title}
            </Typography>
            {description && (
                <Typography
                    sx={{ fontSize: '16px', fontWeight: 400 }}
                    align="center"
                >
                    {parse(description)}
                </Typography>
            )}
        </Box>
    );
};
export default ModalContentContainer;

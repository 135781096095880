import { RouterPath } from '@constants';
import { Box, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

const menus = [
    {
        to: RouterPath.superAdminUsers,
        name: 'Admin'
    },
    {
        to: RouterPath.superAdminOrganizations,
        name: 'Organizations'
    }
];

const SuperadminSubmenuLayout = () => {
    const router = useRouter();
    return (
        <Box sx={{ padding: '20px' }}>
            <Stack
                display="flex"
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'16px'}
            >
                <Image
                    src={'/images/icons/rounded_logo.png'}
                    width={40}
                    height={40}
                />
                <Typography
                    variant="h4"
                    style={{ fontSize: '22px', fontWeight: 600 }}
                >
                    Mojito Admin
                </Typography>
            </Stack>
            <Stack>
                {menus.map((item) => (
                    <Link href={item.to} key={item.to}>
                        <Box
                            sx={{
                                padding: '7px',
                                borderRadius: '5px',
                                marginBottom: '10px',
                                backgroundColor:
                                    item.to === router.asPath ||
                                    (router.pathname ==
                                        RouterPath.superAdminOrganizationOverview &&
                                        item.name == 'Organizations')
                                        ? '#F2F1FF'
                                        : '',
                                color:
                                    item.to === router.asPath ||
                                    (router.pathname ==
                                        RouterPath.superAdminOrganizationOverview &&
                                        item.name == 'Organizations')
                                        ? '#6663FD'
                                        : '',
                                '&:hover': {
                                    backgroundColor: '#F2F1FF',
                                    color: '#6663FD',
                                    cursor: 'pointer'
                                }
                            }}
                        >
                            <Typography
                                variant="body1"
                                style={{ padding: '0 10px', fontWeight: 600 }}
                            >
                                {item.name}
                            </Typography>
                        </Box>
                    </Link>
                ))}
            </Stack>
        </Box>
    );
};

export default SuperadminSubmenuLayout;

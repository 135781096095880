import * as React from 'react';
import { createContext, useContext, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
    ApolloClient,
    ApolloQueryResult,
    OperationVariables,
    useApolloClient,
    useQuery
} from '@apollo/client';
import { getAllowListsQuery, getAllowListsQueryV2 } from '@graphql/queries/query';
import { Marketplace, NftContract, OrganizationData } from '@interface/OrganizationData';
import { MeData } from '@interface/MeData';
import { AllowlistData } from '@interface/Allowlist';
import { StorageService } from '@services/StorageService';
import { RouterPath, StoreProviderSkipRequests } from '@constants';
import { dropsListQuery } from '@graphql/queries/drops';
import { DropsListQueryData } from '@interface/Drops';
import { useRouter } from 'next/router';
import { RegistryItem } from '@interface/Registry';
import { useLoading } from './LoadingProvider';
import { getNftContractsV2Query } from '@graphql/queries/collections';
import { NFTContractProps } from '@interface/Collections';
import { collectionRoutes, customerRoutes, marketplaceRoutes } from '@components/shared/Sidebar/Config';
import { useMeStore } from './MeProvider';
import { useOrganizationStore } from './OrganizationProvider';
import { useGlobalState } from './GlobalContext';

interface RoyalitesData {
    walletAddress: string[];
    percentage: number[];
}
export interface ContextType {
    organization: OrganizationData;
    setActivenft: React.Dispatch<React.SetStateAction<any>> | ((data: any) => void);
    activenft: NftContract;
    meData: MeData;
    loading: boolean;
    allowlistData: AllowlistData;
    refetchAllowlistData: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>;
    allowListLoading: boolean;
    setSkipRequests: (data: StoreProviderSkipRequestsProps) => void;
    dropsListData: DropsListQueryData;
    dropsListLoading: boolean;
    refetchDropsList: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>;
    setActiveOrganizationId: (data: string, skipStorage?: boolean) => void;
    currentOrganizationId: string;
    skipRequests: StoreProviderSkipRequestsProps;
    marketPlaceId: string;
    meLoading: boolean;
    setSelectedRegistry: (item: RegistryItem) => void;
    selectedRegistry?: RegistryItem;
    createCollectionDetail: any;
    setCreateCollectionDetail: React.Dispatch<React.SetStateAction<any>>;
    royalites: RoyalitesData;
    setRoyalites: React.Dispatch<React.SetStateAction<RoyalitesData>>;
    secondaryMarketplaceId?: string;
    getNftContractsData: NFTContractProps | undefined;
    getNftContractsRefetch: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>;
    setCollectionFilter: React.Dispatch<React.SetStateAction<CollectionListQueryFilterProps>>;
    collectionFilter: CollectionListQueryFilterProps;
    setDropsFilter: React.Dispatch<React.SetStateAction<DropsListQueryFilterProps>>;
    dropsFilter: DropsListQueryFilterProps;
    isMojitoAdmin: boolean;
    setAllowListData: React.Dispatch<React.SetStateAction<AllowlistData>>;
    setAllowListFilter: React.Dispatch<React.SetStateAction<AllowListFilterProps>>;
    allowListFilter: AllowListFilterProps;
    initDropsListData: DropsListQueryData;
}

export type StoreProviderSkipRequestsProps = {
    meQuery: boolean;
    organizationQuery: boolean;
    allowListQuery: boolean;
    dropsListQuery: boolean;
    collectionsListQuery: boolean;
};

export interface CollectionListQueryFilterProps {
    limit: number;
    offset: number;
    sort: {
        column: string;
        type: string;
    };
    filter: string;
    searchKey?: string;
}

export interface DropsListQueryFilterProps {
    limit: number;
    offset: number;
    sort: {
        column: string;
        type: string;
    };
    filters: string;
    searchKey?: string;
}

export interface AllowListFilterProps {
    limit: number;
    offset: number;
    sort: {
        column: string;
        type: string;
    };
    filter: string | undefined;
    searchKey?: string;
}

const Context = createContext<ContextType>({} as ContextType);

export const useStore = () => {
    return useContext(Context);
};

const StoreProvider = ({ children }: { children?: React.ReactNode }) => {
    const [activenft, setActivenft] = useState<any>(null);
    const router = useRouter();
    const activenftId = useMemo(() => router.query?.page?.[0] || activenft?.id, [activenft, router.query]);

    const [skipRequests, setSkipRequests] = useState(StoreProviderSkipRequests);
    const [royalites, setRoyalites] = useState<RoyalitesData>();
    const [organization, setOrganization] = useState<any>();
    const [createCollectionDetail, setCreateCollectionDetail] = useState();
    const initialBindOrganizationId = useRef<null | boolean>(null);
    const [allowlistData, setAllowListData] = useState<AllowlistData | undefined>(undefined);
    const [allowListLoading, setAllowListLoading] = useState<boolean>(false);
    const [getNftContractsData, setNftContractsData] = useState<NFTContractProps | undefined>(undefined);
    const [contractLoading, setContractsLoading] = useState<boolean>(false);
    const [dropsListData, setDropsListData] = useState<DropsListQueryData | undefined>(undefined);
    const [initDropsListData, setInitDropsListData] = useState<DropsListQueryData | undefined>(undefined);
    const [dropsListLoading, setDropsListLoading] = useState<boolean>(false);
    const isCollections = useMemo(() => collectionRoutes?.includes(router.pathname), [router.pathname]);
    const isDrop = useMemo(
        () => marketplaceRoutes.includes(router.pathname) && router.pathname?.includes('drops'),
        [router.pathname]
    );
    const isAllowList = useMemo(
        () => customerRoutes?.includes(router.pathname) && router.pathname != RouterPath.customers,
        [router.pathname]
    );
    const offsetPage = useMemo(() => Number(router.query?.p?.toString() ?? '1'), [router.query]);
    const sort = useMemo(() => String(router.query?.sort?.toString() ?? 'DATE-DESC'), [router.query]);
    const filter = useMemo(() => String(router.query?.filter?.toString() ?? 'ALL'), [router.query]);
    const searchKey = useMemo(
        () => (router?.query?.searchKey != '' ? router?.query?.searchKey?.toString() : undefined),
        [router.query]
    );
    const [collectionFilter, setCollectionFilter] = useState<CollectionListQueryFilterProps>({
        limit: 8,
        offset: 0,
        sort: {
            column: 'DATE',
            type: 'DESC'
        },
        filter: 'ALL',
        searchKey: undefined
    });

    const [dropsFilter, setDropsFilter] = useState<DropsListQueryFilterProps>({
        limit: 8,
        offset: 0,
        filters: 'ALL',
        sort: {
            column: 'DATE',
            type: 'DESC'
        }
    });
    const [allowListFilter, setAllowListFilter] = useState<AllowListFilterProps>({
        limit: 8,
        offset: 0,
        filter: 'ALL',
        sort: {
            column: 'createdAt',
            type: 'DESC'
        }
    });
    const { setLoading } = useLoading();
    const [activeOrganizationId, setActiveOrganizationId] = useState<string | undefined>('');
    const apiClient: ApolloClient<object> = useApolloClient();
    const { setData: setGlobalData, data: globalData } = useGlobalState();
    const { loading, organizationData, setOrganizationHandle } = useOrganizationStore();
    const { meData, loading: meLoading } = useMeStore();
    const isOrganizationHandleBinded = useRef<null | boolean>();

    const isMojitoAdmin = useMemo(
        () => meData?.me?.user?.email?.includes('@mojito') || meData?.me?.user?.email?.includes('@getmojito'),
        [meData?.me?.user?.email]
    );

    const getSortValue = useCallback((value: string) => {
        const formattedValue = value.split('-');
        return {
            column: formattedValue[0],
            type: formattedValue[1]
        };
    }, []);

    useEffect(() => {
        if (offsetPage != undefined && isCollections) {
            setCollectionFilter((prev) => ({
                ...prev,
                offset: (offsetPage - 1) * 8,
                filter: filter ?? 'ALL',
                sort: getSortValue(sort) ?? {
                    column: 'DATE',
                    type: 'DESC'
                },
                searchKey
            }));
        }
    }, [offsetPage, sort, filter, isCollections, getSortValue, searchKey]);

    useEffect(() => {
        if (collectionFilter && isCollections) {
            setGlobalData((prev) => ({
                ...prev,
                isRefetchNFTContracts: true
            }));
        }
    }, [collectionFilter, isCollections, setGlobalData]);

    useEffect(() => {
        if (dropsFilter && isDrop) {
            setGlobalData((prev) => ({
                ...prev,
                isRefetchDrops: true
            }));
        }
    }, [dropsFilter, isDrop, setGlobalData]);

    const [selectedRegistry, setSelectedRegistry] = useState<RegistryItem>();

    const onHandleCurrentOrganization = useCallback(
        (data: string) => {
            if (data) {
                return meData?.me?.userOrgs?.find((item: any) => item?.organization?.id == data)?.organization
                    ?.handle;
            } else {
                return meData?.me?.userOrgs?.find(
                    (item: any) =>
                        item?.organization?.id == (StorageService.orgId.getValue() ?? meData?.me?.userOrgs[0])
                )?.organization?.handle;
            }
        },
        [meData]
    );
    const currentOrganizationHandle = useMemo(
        () => onHandleCurrentOrganization(activeOrganizationId ?? ''),
        [activeOrganizationId, onHandleCurrentOrganization]
    );

    useEffect(() => {
        if (currentOrganizationHandle) {
            setOrganizationHandle(currentOrganizationHandle);
            isOrganizationHandleBinded.current = true;
        }
    }, [currentOrganizationHandle, setOrganizationHandle, setGlobalData]);

    useEffect(() => {
        if (isOrganizationHandleBinded.current) {
            isOrganizationHandleBinded.current = false;
            setGlobalData((prev) => ({
                ...prev,
                isRefetchOrganization: true
            }));
        }
    }, [isOrganizationHandleBinded.current, setGlobalData]);

    useEffect(() => {
        if (organizationData?.organization && !loading) {
            setOrganization(organizationData);
        }
    }, [loading, organizationData]);

    const { refetch: getNftContractsRefetch } = useQuery(getNftContractsV2Query, {
        variables: {
            ...collectionFilter,
            orgId: activeOrganizationId
        },
        fetchPolicy: 'no-cache',
        skip: true
    });

    useEffect(() => {
        (async () => {
            if (globalData.isRefetchNFTContracts && activeOrganizationId) {
                setGlobalData((prev) => ({
                    ...prev,
                    isRefetchNFTContracts: false
                }));
                setContractsLoading(true);
                const response = await apiClient.query({
                    query: getNftContractsV2Query,
                    variables: {
                        ...collectionFilter,
                        orgId: activeOrganizationId
                    },
                    fetchPolicy: 'no-cache'
                });
                setContractsLoading(false);
                setNftContractsData(response.data);
            }
        })();
    }, [activeOrganizationId, apiClient, collectionFilter, globalData.isRefetchNFTContracts, setGlobalData]);

    useEffect(() => {
        if (getNftContractsData?.getNftContractV2?.count <= collectionFilter.offset && activeOrganizationId) {
            getNftContractsRefetch({
                ...collectionFilter,
                offset: 0,
                orgId: activeOrganizationId
            });
        }
    }, [
        getNftContractsData?.getNftContractV2?.count,
        collectionFilter,
        activeOrganizationId,
        getNftContractsRefetch
    ]);

    const { refetch: refetchAllowlistData } = useQuery(getAllowListsQueryV2, {
        fetchPolicy: 'no-cache',
        variables: {
            ...{
                ...allowListFilter,
                filter: allowListFilter?.filter == 'ALL' ? undefined : allowListFilter?.filter
            },
            orgID: activeOrganizationId
        },
        skip: true
    });

    useEffect(() => {
        if (allowListFilter) {
            setGlobalData((prev) => ({
                ...prev,
                isRefetchAllowList: true
            }));
        }
    }, [allowListFilter, setGlobalData]);

    useEffect(() => {
        (async () => {
            if (globalData.isRefetchAllowList && activeOrganizationId) {
                setGlobalData((prev) => ({
                    ...prev,
                    isRefetchAllowList: false
                }));
                setAllowListLoading(true);
                const response = await apiClient.query({
                    query: getAllowListsQueryV2,
                    variables: {
                        ...{
                            ...allowListFilter,
                            filter: allowListFilter?.filter == 'ALL' ? undefined : allowListFilter?.filter
                        },
                        orgID: activeOrganizationId
                    },
                    fetchPolicy: 'no-cache'
                });
                setAllowListLoading(false);
                setAllowListData(response.data);
            }
        })();
    }, [activeOrganizationId, apiClient, globalData.isRefetchAllowList, allowListFilter, setGlobalData]);

    const marketPlaceId = useMemo(() => {
        const bothMarketplace = organization?.organization?.marketplaces?.filter((item: Marketplace) =>
            Boolean(item.isSecondaryMarketPlace && item.isPrimaryMarketPlace)
        );
        if (bothMarketplace?.length > 0) {
            return bothMarketplace[0]?.id;
        }
        return organization?.organization?.marketplaces?.filter((item: any) =>
            Boolean(item.isPrimaryMarketPlace)
        )[0]?.id;
    }, [organization?.organization?.marketplaces]);

    const secondaryMarketplaceId = useMemo(() => {
        const bothMarketplace = organization?.organization?.marketplaces?.filter((item: Marketplace) =>
            Boolean(item.isSecondaryMarketPlace && item.isPrimaryMarketPlace)
        );
        if (bothMarketplace?.length > 0) {
            return bothMarketplace[0]?.id;
        }
        const marketplaceItem = organization?.organization?.marketplaces?.filter((item: any) =>
            Boolean(item.isSecondaryMarketPlace)
        );
        return marketplaceItem?.[0]?.id;
    }, [organization?.organization?.marketplaces]);

    const {
        // loading: dropsListLoading,
        refetch: refetchDropsList
    } = useQuery(dropsListQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            ...dropsFilter,
            id: marketPlaceId
        },
        skip: true
    });

    useEffect(() => {
        (async () => {
            if (globalData.isRefetchDrops && marketPlaceId) {
                setDropsListLoading(true);
                setGlobalData((prev) => ({
                    ...prev,
                    isRefetchDrops: false
                }));
                const response = await apiClient.query({
                    query: dropsListQuery,
                    variables: {
                        ...dropsFilter,
                        id: marketPlaceId
                    },
                    fetchPolicy: 'no-cache'
                });
                setDropsListLoading(false);
                if (!dropsFilter.searchKey) {
                    setInitDropsListData(response.data);
                }
                setDropsListData(response.data);
            }
        })();
    }, [
        apiClient,
        globalData.isRefetchDrops,
        marketPlaceId,
        dropsFilter,
        setInitDropsListData,
        setGlobalData
    ]);

    useEffect(()=> {
        if (offsetPage != undefined && isAllowList) {
            setAllowListFilter(prev=>({
                ...prev,
                filter: filter == 'ALL' ? undefined : filter,
                sort: getSortValue(router.query?.sort?.toString() ?? 'updatedAt-DESC') ?? {
                    column: 'updatedAt',
                    type: 'DESC'
                },
                offset: (offsetPage - 1) * 8
            }));
        }
    }, [filter, isAllowList, router.query , offsetPage, getSortValue]);

    useEffect(() => {
        if (offsetPage != undefined && isDrop && marketPlaceId) {
            setDropsFilter((prev) => ({
                ...prev,
                filters: filter ?? 'ALL',
                sort: getSortValue(sort) ?? {
                    column: 'DATE',
                    type: 'DESC'
                },
                searchKey: searchKey,
                offset: (offsetPage - 1) * 8
            }));
        }
    }, [offsetPage, isDrop, sort, filter, searchKey, marketPlaceId, getSortValue]);

    const findActiveNftId = useCallback(() => {
        let item = [];
        if (activenftId) {
            item = getNftContractsData?.getNftContractV2?.nftContract.find(
                (ele: any) => ele.id === activenftId
            );
        } else if (!activenftId && (router?.query?.id || router?.query?.page?.[0] || router?.query?.nft)) {
            item = getNftContractsData?.getNftContractV2?.nftContract.find(
                (ele: any) =>
                    ((ele.id === router?.query?.id || ele.id === router?.query?.page?.[0]) &&
                        router.pathname != RouterPath.addToken) ||
                    ele.id === router?.query?.nft
            );
        }

        return item;
    }, [activenftId, getNftContractsData?.getNftContractV2?.nftContract, router?.query, router?.pathname]);

    useEffect(() => {
        if ((getNftContractsData?.getNftContractV2?.nftContract?.length ?? 0) > 0 && !loading) {
            const item = findActiveNftId();
            setActivenft(item);
        }
    }, [findActiveNftId, setActivenft, getNftContractsData?.getNftContractV2?.nftContract, loading]);

    useEffect(() => {
        setLoading(loading || dropsListLoading || allowListLoading || meLoading || contractLoading);
    }, [setLoading, loading, dropsListLoading, allowListLoading, meLoading, contractLoading]);

    useEffect(() => {
        if (!initialBindOrganizationId.current && (meData?.me?.userOrgs?.length ?? 0) > 0) {
            initialBindOrganizationId.current = true;
            if (!StorageService.artistOrgId.getValue()) {
                if (!StorageService.orgId.getValue()) {
                    StorageService.orgId.setValue(meData?.me.userOrgs[0].organization.id);
                    setActiveOrganizationId(meData?.me?.userOrgs?.[0]?.organization?.id ?? '');
                } else {
                    setActiveOrganizationId(StorageService.orgId.getValue()?.toString() ?? '');
                }
            } else {
                setActiveOrganizationId(StorageService.artistOrgId.getValue() ?? '');
            }
        }
    }, [meData, setActiveOrganizationId]);

    const setActiveOrganization = useCallback((id: string, skipStorage = false) => {
        if (!skipStorage) {
            StorageService.orgId.setValue(id);
        }
        setActiveOrganizationId(id);
    }, []);

    return (
        <Context.Provider
            value={
                {
                    allowlistData,
                    organization,
                    setActivenft,
                    activenft,
                    meData,
                    loading,
                    refetchAllowlistData,
                    allowListLoading,
                    setSkipRequests,
                    dropsListData,
                    dropsListLoading,
                    refetchDropsList,
                    setActiveOrganizationId: setActiveOrganization,
                    currentOrganizationId: activeOrganizationId,
                    skipRequests,
                    marketPlaceId,
                    meLoading,
                    selectedRegistry,
                    setSelectedRegistry,
                    createCollectionDetail,
                    setCreateCollectionDetail,
                    royalites,
                    setRoyalites,
                    secondaryMarketplaceId,
                    getNftContractsData,
                    getNftContractsRefetch,
                    setCollectionFilter,
                    collectionFilter,
                    setDropsFilter,
                    dropsFilter,
                    isMojitoAdmin,
                    setAllowListData,
                    allowListFilter,
                    setAllowListFilter,
                    initDropsListData
                } as ContextType
            }
        >
            {children}
        </Context.Provider>
    );
};

export default StoreProvider;

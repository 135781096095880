import * as React from 'react';
import { useState, useCallback, ChangeEvent } from 'react';
import { ModalDialog } from '@components/Atoms';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import { MixTheme } from '@styles';
import TermsAndConditionContentLayout from '@layouts/TermsAndConditionContent.layout';

interface TermsAndConditionModalProp {
    show?: boolean;
    onSubmit?: () => void;
}

const TermsAndConditionModal = ({
    show = true,
    onSubmit
}: TermsAndConditionModalProp) => {
    const theme: MixTheme = useTheme();
    const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);
    const onCheckTermsAndCondition = useCallback(
        (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setIsSubmitEnabled(checked);
        },
        []
    );

    return (
        <ModalDialog
            open={show}
            title={
                <Stack width={'100%'} justifyContent={'center'}>
                    <Typography
                        variant="h5"
                        sx={{ fontWeight: 700, fontSize: '20px' }}
                    >
                        Terms & Conditions
                    </Typography>
                </Stack>
            }
            body={
                <Stack>
                    <Stack
                        sx={{
                            padding: '20px',
                            border: `1px solid ${theme.global?.border}`,
                            maxHeight: '300px',
                            height: '100%',
                            overflow: 'auto'
                        }}
                    >
                        <TermsAndConditionContentLayout />
                    </Stack>
                    <Stack
                        flexDirection={'row'}
                        alignItems={'flex-start'}
                        width={'100%'}
                        marginTop={2}
                    >
                        <Checkbox
                            size="small"
                            checked={isSubmitEnabled}
                            onChange={onCheckTermsAndCondition}
                            sx={{ padding: '0' }}
                        />
                        <Typography variant="body1" sx={{ marginLeft: 1 }}>
                            I've reviewed and accept the terms outlined here.
                        </Typography>
                    </Stack>
                </Stack>
            }
            onSubmit={onSubmit}
            submitButtonText="Accept"
            isSubmitEnabled={isSubmitEnabled}
            titleStyle={{
                justifyContent: 'center'
            }}
            bodyContentStyle={{
                padding: '48px 16px !important'
            }}
        />
    );
};

export default TermsAndConditionModal;

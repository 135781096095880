import { Box, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from './submenu.styles';
import { useStyle } from '@styles';
import { useRouter } from 'next/router';
import { RouterPath } from '@constants';
import { useOrganizationStore } from '@state/OrganizationProvider';

export interface ReportsTypes {
    id: string;
    name: string;
    disabled?: boolean;
}
export const reportsMenu: ReportsTypes[] = [
    {
        name: 'Sales Activity',
        id: 'salesItemsAndAuction'
    },
    {
        name: 'Invoice / NFT Delivery',
        id: 'invoiceNFTDelivery'
    },
    {
        name: 'Bids Summary',
        id: 'auctionBid'
    },
    {
        name: 'Summary Stats',
        id: 'summaryStats'
    },
    {
        name: 'Secondary Marketplace Sales',
        id: 'secondaryMarketplaceSalesReport'
    },
    {
        name: 'Claims',
        id: 'claims'
    },
    {
        name: 'Delivery',
        id: 'itemDeliveryReport'
    },
    {
        name: 'Customer Summary',
        id: 'CustomerDetails'
    },
    {
        name: 'Gas Summary',
        id: 'gas'
    },
    {
        name: 'Dutch Auction',
        id: 'dutchAuctionReport'
    },
    {
        name: 'Ballot',
        id: 'ballotReport'
    },
    {
        name: 'Payment Link',
        id: 'paymentLink'
    }
];
const ReportsSubMenuLayout = () => {
    const { styles, theme } = useStyle(makeStyles);
    const router = useRouter();
    const { organizationData } = useOrganizationStore();

    const [active, setActive] = useState<string>();
    useEffect(() => {
        if (router.pathname == RouterPath.reportDetails) {
            if (!router.query?.id) {
                setActive(reportsMenu[0].id);
                router.push({
                    pathname: RouterPath.reportDetails,
                    query: { id: reportsMenu[0].id }
                });
            } else if (router.query?.id) {
                setActive(router.query?.id?.toString());
            }
        }
    }, [active, router]);
    // revert changes for external Tx changes on dev
    //Append External Tx Gas Report for only organization handle Contain mojito
    // useEffect(()=>{
    //     if(organizationData?.organization?.handle && organizationData?.organization?.handle?.toLowerCase()?.includes('mojito')) {
    //         const isExternalTxReportAdded = reportsMenu?.filter(item=> item.id == 'ExternalTxGas')?.length > 0;
    //         if (!isExternalTxReportAdded) {
    //             reportsMenu.push({
    //                 name: 'External Transaction Gas',
    //                 id: 'ExternalTxGas'
    //             });
    //         }
    //     }
    //     else {
    //         reportsMenu = reportsMenu.filter(item=> item.id != 'ExternalTxGas');
    //     }
    // }, [organizationData?.organization?.handle]);

    const onClickItem = useCallback(
        (item: ReportsTypes) => {
            if (!item?.disabled) {
                setActive(item.id);
                router.push(
                    {
                        pathname: RouterPath.reportDetails,
                        query: { id: item.id }
                    },
                    undefined,
                    { shallow: true }
                );
            }
        },
        [router]
    );

    return (
        <Stack padding={'24px'}>
            <Typography
                variant="h4"
                style={{
                    marginBottom: '24px',
                    fontWeight: 700,
                    fontSize: '24px'
                }}
            >
                Reports
            </Typography>
            <Typography
                variant="subtitle2"
                style={{
                    color: theme.color?.textColor,
                    fontWeight: 700,
                    fontSize: '12px',
                    marginBottom: '8px',
                    textTransform: 'uppercase'
                }}
            >
                Reports
            </Typography>
            {(router.pathname == RouterPath.reports ||
                router.pathname.includes('reports')) &&
                reportsMenu &&
                reportsMenu.map((item: ReportsTypes) => {
                    return (
                        <Box key={item.id} onClick={() => onClickItem(item)}>
                            <Typography
                                variant="body1"
                                sx={styles.createdAllowlists}
                            >
                                <Typography
                                    sx={{
                                        ...(active === item.id &&
                                            styles.hoverColor),
                                        fontSize: '14px'
                                    }}
                                >
                                    {item.name}
                                </Typography>
                            </Typography>
                        </Box>
                    );
                })}
        </Stack>
    );
};

export default ReportsSubMenuLayout;

import * as React from 'react';
import Box from '@mui/material/Box';
import MUITextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { InputBaseProps, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { MixTheme } from '@styles';

interface TextFieldProps {
    title?: string;
    onChange?: (val: string, fieldName?: string) => void;
    value?: string;
    name?: string;
    error?: boolean | undefined;
    readOnly?: boolean;
    padding?: any;
    nolimit?: boolean;
    label?: string;
    width?: string;
    disabled?: boolean;
    [props: string]: any;
    placeholder?: string;
    helperText?: boolean | string | undefined;
    inputProps?: InputBaseProps['inputProps'];
    select?: boolean;
    multiline?: boolean;
    subTitle?: string | React.ReactNode;
    marginBottom?: string;
    maxRows?: number;
    minRows?: number;
    isRequired?: boolean;
    limitLength?: number;
}

export const TextField: React.FC<TextFieldProps> = ({
    title,
    onChange,
    value,
    name,
    error,
    readOnly = false,
    padding = null,
    label,
    width,
    disabled = false,
    nolimit = true,
    placeholder,
    helperText,
    inputProps,
    multiline = false,
    maxRows,
    minRows,
    isRequired = false,
    limitLength,
    ...props
}: TextFieldProps): JSX.Element => {
    const theme: MixTheme = useTheme();
    const onChangeText = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) =>
            onChange?.(e?.target?.value, name ?? ''),
        [onChange, name]
    );

    return (
        <Box
            component="form"
            sx={{ marginBottom: props?.marginBottom ?? '1rem' }}
            width={width}
        >
            {(title || !nolimit) && (
                <Grid
                    sx={{
                        display: 'flex',
                        marginBottom: props?.subTitle ? '4px' : '8px',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: isRequired ? '0.2rem' : undefined
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{ fontFamily: 'inherit' }}
                        >
                            {title}
                        </Typography>
                        {isRequired && (
                            <Typography sx={{ color: theme?.color?.error }}>
                                *{' '}
                            </Typography>
                        )}
                    </Box>
                    {!nolimit && (
                        <Typography
                            component={'span'}
                            sx={{
                                color: '#BABAD6',
                                fontSize: '12px',
                                fontFamily: 'inherit'
                            }}
                        >
                            {`${value ? value?.toString()?.length : 0}`}/
                            {limitLength ? limitLength : '500'}
                        </Typography>
                    )}
                </Grid>
            )}
            {props.subTitle && (
                <Typography
                    variant="body2"
                    sx={{
                        color: theme?.color?.textColor,
                        marginBottom: '4px'
                    }}
                >
                    {props.subTitle}
                </Typography>
            )}
            <MUITextField
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                sx={{
                    backgroundColor:
                        readOnly || disabled
                            ? theme?.global?.background
                            : undefined,
                    display: 'flex',
                    fontFamily: 'inherit'
                }}
                name={name}
                onChange={onChangeText}
                value={value}
                placeholder={placeholder}
                inputProps={
                    inputProps ?? {
                        readOnly,
                        style: {
                            padding: padding ? padding : '16.5px 14px'
                        },
                        maxLength: limitLength ? limitLength : null
                    }
                }
                disabled={disabled}
                label={label}
                error={error}
                multiline={multiline}
                maxRows={maxRows}
                minRows={minRows}
                {...props}
            />

            {error && (
                <Typography
                    component={'span'}
                    sx={{ fontFamily: 'inherit', color: 'red' }}
                >
                    {helperText}
                </Typography>
            )}
        </Box>
    );
};

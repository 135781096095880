import { LoadingComponent } from '@components/shared';
import React, { useContext, useState } from 'react';

interface LoadingContextProps {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = React.createContext<LoadingContextProps>(
    {} as LoadingContextProps
);

export const useLoading = () => useContext(LoadingContext);

const LoadingProvider = ({ children }: { children?: React.ReactNode }) => {
    const [loading, setLoading] = useState<boolean>(false);
    return (
        <LoadingContext.Provider
            value={{ loading, setLoading } as LoadingContextProps}
        >
            {loading && <LoadingComponent show={true} />}
            {children}
        </LoadingContext.Provider>
    );
};

export default LoadingProvider;

import * as React from 'react';
import Stack from '@mui/material/Stack';
import MUIButton from '@mui/material/Button';
import { SxProps, Theme, Typography } from '@mui/material';

export interface ButtonProps {
    onClick?: () => void;
    title?: React.ReactElement | string;
    reverse?: boolean;
    icon?: React.ReactElement;
    backgroundColor?: string;
    boxShadow?: string;
    color?: string;
    borderRadius?: string;
    margin?: string;
    hoverBackgroundColor?: string;
    disabled?: boolean;
    hoverBoxShadow?: string;
    padding?: string;
    buttonVariant?: 'text' | 'outlined' | 'contained';
    borderColor?: string;
    buttonIconPlacing?: string;
    pointer?: string;
    width?: string;
    sx?: SxProps<Theme>;
    buttonContainerStyle?: SxProps<Theme>;
    handleDragOver?: (e: DropEvent) => void;
    handleDrop?: (e: DropEvent) => void;
}

interface DropEvent extends React.DragEvent<HTMLInputElement> {
    dataTransfer: DataTransfer;
}

const Button = ({
    title,
    icon,
    reverse = false,
    onClick,
    backgroundColor,
    boxShadow,
    color,
    borderRadius,
    margin,
    hoverBackgroundColor,
    hoverBoxShadow,
    disabled = false,
    padding,
    buttonVariant = 'contained',
    borderColor,
    buttonIconPlacing,
    pointer,
    width,
    sx,
    buttonContainerStyle = {},
    handleDragOver,
    handleDrop
}: ButtonProps) => {
    const buttonIcon = React.useMemo(() => {
        if (buttonIconPlacing === 'Right') {
            return (
                <Stack
                    direction={reverse ? 'row-reverse' : 'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    {title}
                    <Typography
                        sx={{
                            marginLeft: '8px',
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        {icon}
                    </Typography>
                </Stack>
            );
        } else
            return (
                <Stack
                    direction={reverse ? 'row-reverse' : 'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    {icon}
                    {title}
                </Stack>
            );
    }, [buttonIconPlacing, icon, reverse, title]);
    return (
        <Stack sx={{ ...buttonContainerStyle, display: 'inline-flex' }}>
            <MUIButton
                onClick={onClick}
                variant={buttonVariant}
                disabled={disabled}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                sx={{
                    ...(sx ?? {}),
                    color: { color },
                    textTransform: 'initial',
                    borderRadius: { borderRadius },
                    fontFamily: 'inherit',
                    borderColor: { borderColor },
                    backgroundColor: { backgroundColor },
                    boxShadow: { boxShadow },
                    margin: { margin },
                    padding: { padding },
                    fontWeight: 700,
                    fontSize: '14px',
                    pointerEvents: pointer ?? '',
                    width: { width },
                    '&: hover': {
                        background: hoverBackgroundColor,
                        boxShadow: hoverBoxShadow,
                        borderColor: { borderColor }
                    }
                }}
            >
                {buttonIcon}
            </MUIButton>
        </Stack>
    );
};

export default Button;

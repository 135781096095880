import React, { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import {
    LoadingContainer,
    ConnectWithEmailContainer,
    ErrorContainer,
    OTPContainer,
    ExistingWalletContainer,
    RecoveryCodeContainer
} from '../component/index';
import { ConnectWalletLayoutProps, ModalType } from '../interface';

const ConnectWalletContainerLayout = ({
    open,
    error,
    email,
    otp,
    walletOptions,
    image,
    currentModalState,
    loaderTitle,
    content,
    recoveryCode,
    onChangeEmail,
    onChangeOTP,
    onChangeRecoveryCode,
    onClickVerifyOTP,
    onClickContinueWithEmail,
    onClickEmail,
    handleRetry,
    onClickMetamask,
    onClickWalletConnect,
    onClickRecoveryCode,
    onCloseModal
}: ConnectWalletLayoutProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('sm'));

    const modalStyle = useMemo(() => {
        return {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
                lg: '400px',
                md: '400px',
                sm: '400px',
                xs: 'calc(100% - 20px)'
            },
            bgcolor: theme.palette.background.paper,
            borderRadius: '4px',
            boxShadow: 24,
            maxHeight: { xs: 'calc(100% - 50px)', lg: 'calc(100% - 100px)' },
            overflowY: 'auto',
            padding: '16px 16px 30px 16px'
        };
    }, [theme]);

    const isLoading = useMemo(() => {
        return (
            currentModalState === ModalType.LOADING ||
            currentModalState === ModalType.ERROR
        );
    }, [currentModalState]);

    return (
        <Modal open={open}>
            <Box sx={modalStyle}>
                {isLoading ? (
                    <Box
                        sx={{
                            padding: { lg: '0px', xs: '10px', md: '30px' },
                            marginTop: { xs: 0, lg: 0 }
                        }}
                    >
                        {currentModalState === ModalType.LOADING && (
                            <LoadingContainer loaderTitle={loaderTitle} />
                        )}
                        {currentModalState === ModalType.ERROR && (
                            <ErrorContainer
                                onCloseModal={onCloseModal}
                                errorImage={image?.error}
                                errorMessage={error}
                                handleRetry={handleRetry}
                            />
                        )}
                    </Box>
                ) : (
                    <>
                        <Box
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <CloseIcon
                                sx={{
                                    cursor: 'pointer',
                                    color: theme.palette.background.default
                                }}
                                onClick={onCloseModal}
                            />
                        </Box>
                        <Box sx={{ padding: '10px' }}>
                            {currentModalState === ModalType.EMAIL &&
                                walletOptions.enableEmail && (
                                    <ConnectWithEmailContainer
                                        content={content?.emailContentData}
                                        email={email}
                                        onChangeEmail={onChangeEmail}
                                        error={error}
                                        onClickContinueWithEmail={
                                            onClickContinueWithEmail
                                        }
                                        logoUrl={image?.logo}
                                    />
                                )}
                            {currentModalState === ModalType.OTP && (
                                <OTPContainer
                                    content={content?.otpContentData}
                                    email={email}
                                    otp={otp}
                                    error={error}
                                    onChangeOTP={onChangeOTP}
                                    onClickVerifyOTP={onClickVerifyOTP}
                                    onClickRequestNewCode={
                                        onClickContinueWithEmail
                                    }
                                    logo={image?.logo}
                                />
                            )}
                            {currentModalState === ModalType.CONNECT_WALLET && (
                                <ExistingWalletContainer
                                    walletOptions={walletOptions}
                                    image={image}
                                    isMobile={isMobile}
                                    content={content?.walletOptionsContentData}
                                    onClickMetamask={onClickMetamask}
                                    onClickWalletConnect={onClickWalletConnect}
                                    onClickEmail={onClickEmail}
                                />
                            )}
                            {currentModalState === ModalType.RECOVERY_CODE && (
                                <RecoveryCodeContainer
                                    recoveryCode={recoveryCode}
                                    logo={image?.logo}
                                    error={error}
                                    onChangeRecoveryCode={onChangeRecoveryCode}
                                    onClickRecoveryCode={onClickRecoveryCode}
                                />
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default ConnectWalletContainerLayout;

import { sortProps } from '@layouts/submenus/drops.layout';

export const CapitaliseString = (data: string): string => {
    const strings = data.split(' ');
    const result = strings
        .map((str) => str[0].toUpperCase() + str.substring(1).toLowerCase())
        .join(' ');
    return result;
};

export const getSortValue = (value: string) => {
    if (value.includes('-')) {
        const splittedValue = value.split('-');
        return {
            column: splittedValue[0],
            type: splittedValue[1]
        };
    } else {
        return {
            column: '',
            type: ''
        };
    }
};

export const convertSortValue = (getValue: sortProps) => {
    return getValue?.column + '-' + getValue?.type;
};

import * as React from 'react';
import {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
    useContext
} from 'react';

interface GlobalContextDataProps {
    isRefetchOrganization: boolean;
    isRefetchMe: boolean;
    isRefetchDrops: boolean;
    isRefetchNFTContracts: boolean;
    isRefetchAllowList: boolean;
    isPageLoading: boolean;
}

interface GlobalContextProps {
    data: GlobalContextDataProps;
    setData: Dispatch<SetStateAction<GlobalContextDataProps>>;
}
const GlobalContext = createContext<GlobalContextProps>(
    {} as GlobalContextProps
);

export const useGlobalState = () => {
    return useContext(GlobalContext);
};

const GlobalContextProvider = ({
    children
}: {
    children?: React.ReactNode;
}) => {
    const [data, setData] = useState<GlobalContextDataProps>({
        isRefetchMe: false,
        isRefetchOrganization: false,
        isRefetchDrops: false,
        isRefetchNFTContracts: false,
        isRefetchAllowList: false,
        isPageLoading: false
    });
    return (
        <GlobalContext.Provider value={{ data, setData }}>
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContextProvider;

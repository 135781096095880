class SecureStorage {
    key: string;
    constructor(key: string) {
        this.key = key;
    }
    setValue = (value: any) => {
        localStorage.setItem(this.key, value);
    };
    getValue = () => {
        if (typeof window !== 'undefined') {
            return localStorage.getItem(this.key);
        }
    };
    clear = () => {
        localStorage.removeItem(this.key);
    };
}

export const StorageService = {
    user: new SecureStorage('user_data'),
    orgId: new SecureStorage('orgId'),
    token: new SecureStorage('token'),
    walletAddress: new SecureStorage('wallet-address'),
    approveSignature: new SecureStorage('approveSignature'),
    configureColumns: new SecureStorage('configureColumns'),
    adminFilter: new SecureStorage('adminFilter'),
    organizationFilter: new SecureStorage('organizationFilter'),
    bidsFilter: new SecureStorage('bidsFilter'),
    categoriesFilter: new SecureStorage('categoriesFilter'),
    registryFilter: new SecureStorage('registriesFilter'),
    ArtistFilter: new SecureStorage('artistFilter'),
    utils: new SecureStorage('utils'),
    allowlistFilter: new SecureStorage('allowlistFilter'),
    web3: new SecureStorage('web3'),
    artistOrgId: new SecureStorage('artistOrgId'),
    collectionFilter: new SecureStorage('collectionFilter'),
    spenderAddress: new SecureStorage('spenderAddress'),
    tokenListFilter: new SecureStorage('tokenlistFilter'),
    //For artist login
    tokenType: new SecureStorage('tokenType')
};

import * as React from 'react';
import { CSSProperties } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';
import Image from 'next/image';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MixTheme } from '@styles';

interface ModalDialogProps {
    title?: string | JSX.Element;
    open: boolean;
    body: JSX.Element;
    isSubmitEnabled?: boolean;
    submitButtonText?: string;
    secondaryButtonText?: string;
    otherWidth?: false | Breakpoint | undefined;
    otherImage?: string;
    submitNotNeeded?: boolean;
    submitButtonColor?: string;
    submitBackground?: string;
    onSubmit?: any;
    bodyContentStyle?: React.CSSProperties;
    titleStyle?: CSSProperties;
    disableBackDrop?: boolean;
    disableSecondaryButton?: boolean;
    onClickSecondaryButton?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    onBack?: () => void;
}

const ModalDialog = React.memo(
    ({
        open,
        isSubmitEnabled = true,
        otherImage,
        otherWidth,
        submitButtonText,
        submitNotNeeded = false,
        secondaryButtonText,
        title,
        submitButtonColor,
        submitBackground,
        onSubmit,
        body,
        bodyContentStyle,
        disableBackDrop,
        disableSecondaryButton = false,
        titleStyle = {},
        onClose,
        onCancel,
        onBack,
        onClickSecondaryButton
    }: ModalDialogProps) => {
        const theme: MixTheme = useTheme();

        const keyEventPrevent = (keyEvent: any) => {
            if (
                (keyEvent.charCode || keyEvent.keyCode) === 13 &&
                !submitNotNeeded
            ) {
                keyEvent.preventDefault();
            }
        };

        return (
            <Dialog
                open={open}
                onClose={disableBackDrop ? undefined : onClose}
                maxWidth={otherWidth || 'sm'}
                onKeyDown={keyEventPrevent}
                fullWidth
            >
                {title && (
                    <DialogTitle
                        sx={{
                            padding: 0,
                            backgroundColor: theme?.color?.white
                        }}
                    >
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            padding={'1rem'}
                            sx={{
                                ...{
                                    borderBottom: `1px solid ${theme?.global?.shadow}`
                                },
                                ...titleStyle
                            }}
                        >
                            <Stack
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'flex-start'}
                            >
                                {otherImage && (
                                    <span style={{ margin: '7px 12px 0 0' }}>
                                        <Image
                                            src={otherImage}
                                            width={20}
                                            height={20}
                                        />
                                    </span>
                                )}
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontSize: '1.25rem',
                                        fontWeight: 700
                                    }}
                                >
                                    {title}
                                </Typography>
                            </Stack>
                            {onClose && (
                                <Image
                                    src={'/images/icons/cancelIconBlack.png'}
                                    width={11}
                                    style={{ cursor: 'pointer' }}
                                    height={11}
                                    onClick={onClose}
                                />
                            )}
                        </Stack>
                    </DialogTitle>
                )}

                <DialogContent
                    sx={{
                        ...{
                            padding: '1rem !important',
                            backgroundColor: theme?.color?.white,
                            borderBottom: `1px solid ${theme?.global?.shadow}`
                        },
                        ...bodyContentStyle
                    }}
                >
                    {body}
                </DialogContent>

                {(onCancel || onSubmit) && (
                    <DialogActions
                        sx={{
                            backgroundColor: theme?.global?.background,
                            padding: '1rem'
                        }}
                    >
                        <Stack flexDirection={'row'} sx={{ width: '100%' }}>
                            <Stack sx={{ width: '50%' }}>
                                {onBack && (
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer'
                                        }}
                                        onClick={onBack}
                                    >
                                        <ArrowBackIcon
                                            sx={{
                                                position: 'relative',
                                                top: '6px'
                                            }}
                                        />
                                        onBack
                                    </Typography>
                                )}
                            </Stack>
                            <Stack
                                sx={{ width: '50%' }}
                                flexDirection={'row'}
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                            >
                                {onCancel && (
                                    <Typography
                                        variant="button"
                                        onClick={onCancel}
                                        sx={{
                                            cursor: 'pointer',
                                            textTransform: 'capitalize',
                                            marginRight: '1.5rem',
                                            fontWeight: 600
                                        }}
                                    >
                                        Cancel
                                    </Typography>
                                )}
                                {secondaryButtonText &&
                                    onClickSecondaryButton && (
                                        <Button
                                            variant={'outlined'}
                                            disabled={disableSecondaryButton}
                                            sx={{
                                                textTransform: 'capitalize',
                                                marginRight: '1rem',
                                                fontWeight: 700
                                            }}
                                            onClick={onClickSecondaryButton}
                                        >
                                            {secondaryButtonText}
                                        </Button>
                                    )}
                                {!submitNotNeeded && (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: 'capitalize',
                                            color: !isSubmitEnabled
                                                ? theme?.color?.white
                                                : submitButtonColor
                                                  ? submitButtonColor
                                                  : theme?.color?.white,
                                            backgroundColor: !isSubmitEnabled
                                                ? theme?.color?.borderColor
                                                : submitBackground
                                                  ? submitBackground
                                                  : theme?.global?.hover,
                                            fontWeight: 700,
                                            cursor: !isSubmitEnabled
                                                ? 'not-allowed'
                                                : undefined,
                                            '&: hover': {
                                                color: !isSubmitEnabled
                                                    ? theme?.color?.white
                                                    : submitButtonColor
                                                      ? submitButtonColor
                                                      : theme?.color?.white,
                                                backgroundColor:
                                                    !isSubmitEnabled
                                                        ? theme?.color
                                                              ?.borderColor
                                                        : submitBackground
                                                          ? submitBackground
                                                          : theme?.global?.hover
                                            }
                                        }}
                                        disabled={!isSubmitEnabled}
                                        onClick={onSubmit}
                                    >
                                        {submitButtonText}
                                    </Button>
                                )}
                            </Stack>
                        </Stack>
                    </DialogActions>
                )}
            </Dialog>
        );
    }
);

ModalDialog.displayName = 'ModalDialog';
export { ModalDialog };

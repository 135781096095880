import * as React from 'react';
import { useState, useCallback, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { RouterPath } from '@constants';
import { useStore } from '@state/StoreProvider';
import { makeStyles } from './submenu.styles';
import { useStyle } from '@styles';
import { useMutation } from '@apollo/client';
import CreateCollectionModal from '@layouts/collections/collectionsModal/createCollection.layout';
import { useRouter } from 'next/router';
import AddIcon from '@mui/icons-material/Add';
import { CREATE_COLLECTION_INTERNAL_MUTATION } from '@graphql/mutation/mutation';
import { useToast } from '@state/ToastProvider';
import { Toasttypes } from '@components/Atoms';
import { NFTContractData } from '@interface/Collections';
import { useGlobalState } from '@state/GlobalContext';

interface sortProps {
    column: string;
    type: string;
}

const CollectionsSubMenuLayout = () => {
    const {
        getNftContractsData,
        activenft,
        setActivenft,
        setSkipRequests,
        currentOrganizationId,
        getNftContractsRefetch,
        skipRequests,
        collectionFilter
    } = useStore();
    const { setData: setGlobalData } = useGlobalState();
    const router = useRouter();
    const { styles, theme } = useStyle(makeStyles);
    const [createCollection] = useMutation(CREATE_COLLECTION_INTERNAL_MUTATION);
    const { setToast } = useToast();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (skipRequests.meQuery)
            setSkipRequests({
                ...skipRequests,
                meQuery: false
            });
    }, [skipRequests, setSkipRequests]);

    useEffect(() => {
        setGlobalData((prev) => ({
            ...prev,
            isRefetchNFTContracts: true
        }));
    }, [setGlobalData]);

    const onClickCreateCollection = useCallback(() => {
        setOpenModal(true);
    }, []);
    const onClickClose = useCallback(() => {
        setOpenModal(false);
    }, []);

    const changeSort = useCallback((getValue: sortProps) => {
        return getValue?.column + '-' + getValue?.type;
    }, []);

    const onClickItem = useCallback(
        (item: NFTContractData) => {
            setActivenft(item);
            router.push({
                pathname: `/collections/detail/${item.id}`,
                query: {
                    active: 'overview',
                    p: collectionFilter?.offset / collectionFilter?.limit + 1,
                    sort: changeSort(collectionFilter?.sort),
                    filter: collectionFilter?.filter,
                    searchKey: collectionFilter?.searchKey
                }
            });
        },
        [setActivenft, collectionFilter, router, changeSort]
    );

    const isCollectionList = useMemo(
        () => router.pathname == RouterPath.collections,
        [router]
    );

    const onClickSubmit = useCallback(
        async (values) => {
            try {
                const response = await createCollection({
                    variables: {
                        input: {
                            orgId: currentOrganizationId,
                            contractType: values?.contractType,
                            networkId: values?.networkId,
                            collectionType: values?.collectionType,
                            isExternal: false,
                            contractName: values?.contractName,
                            contractSymbol: values?.contractSymbol,
                            walletId: values?.walletId
                        }
                    }
                });
                if (response?.data?.CreateNftContractDraft?.id) {
                    const NFTContractsResponse = await getNftContractsRefetch();
                    const element: NFTContractData | undefined =
                        NFTContractsResponse?.data?.getNftContractV2?.nftContract?.find(
                            (ele: NFTContractData) =>
                                ele.id ===
                                response?.data?.CreateNftContractDraft?.id
                        );
                    setActivenft(element);
                    router.push({
                        pathname: `${RouterPath.collectionsDetailPage}/${element?.id}`,
                        query: {
                            active: 'overview',
                            p:
                                collectionFilter?.offset /
                                    collectionFilter?.limit +
                                1,
                            sort: changeSort(collectionFilter?.sort),
                            filter: collectionFilter?.filter,
                            searchKey: collectionFilter?.searchKey
                        }
                    });
                    return true;
                }
                return false;
            } catch (e: any) {
                console.error('ERROR', e);
                setOpenModal(true);
                setToast(e.message, Toasttypes.ERROR);
                return false;
            }
        },
        [
            changeSort,
            collectionFilter?.filter,
            collectionFilter?.limit,
            collectionFilter?.offset,
            collectionFilter?.sort,
            collectionFilter?.searchKey,
            createCollection,
            currentOrganizationId,
            getNftContractsRefetch,
            router,
            setActivenft,
            setToast
        ]
    );

    return (
        <Stack padding={'16px'}>
            <Typography
                variant="h3"
                style={{
                    marginBottom: '16px',
                    fontWeight: 700,
                    fontSize: '32px'
                }}
            >
                Collections
            </Typography>
            <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'8px'}
            >
                <Typography
                    variant="subtitle2"
                    style={{
                        color: theme?.color?.textColor,
                        fontWeight: 700,
                        fontSize: '12px'
                    }}
                >
                    COLLECTIONS
                </Typography>
                <Stack
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onClick={onClickCreateCollection}
                    sx={{ cursor: 'pointer' }}
                >
                    <AddIcon
                        sx={{
                            fontSize: '14px',
                            color: theme?.global?.hover
                        }}
                    />
                    <Typography
                        variant="button"
                        style={{
                            fontSize: '12px',
                            fontWeight: 700,
                            color: theme?.color?.textColor,
                            textTransform: 'capitalize'
                        }}
                    >
                        New
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    scrollbarWidth: 'thin',
                    overflowY: 'auto',
                    overflowWrap: 'anywhere',
                    overflowX: 'hidden',
                    '&::-webkit-scrollbar': {
                        width: '0.4em'
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888'
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#555'
                    }
                }}
                flexDirection={'column'}
                maxHeight={'380px'}
                overflow={'auto'}
            >
                <Link href={RouterPath.collections}>
                    <Box
                        sx={
                            isCollectionList
                                ? styles.BoxText
                                : styles.normalBoxText
                        }
                    >
                        <Typography
                            variant="body2"
                            sx={
                                isCollectionList
                                    ? styles.typoSelectedText
                                    : styles.typoText
                            }
                        >
                            All Collections
                        </Typography>
                    </Box>
                </Link>
                {getNftContractsData?.getNftContractV2?.nftContract.length >
                0 ? (
                    getNftContractsData?.getNftContractV2?.nftContract.map(
                        (val: NFTContractData, index: number) => (
                            <Box
                                key={index}
                                sx={
                                    val?.id == activenft?.id &&
                                    !isCollectionList
                                        ? styles.BoxText
                                        : styles.normalBoxText
                                }
                                onClick={() => onClickItem(val)}
                            >
                                <Typography
                                    variant="body2"
                                    sx={
                                        val?.id == activenft?.id &&
                                        !isCollectionList
                                            ? styles.typoSelectedText
                                            : styles.typoText
                                    }
                                >
                                    {val.name ? val.name : '-'}
                                </Typography>
                            </Box>
                        )
                    )
                ) : (
                    <Typography variant="body2">
                        This section displays a list of all the NFT collections
                        you've created.
                    </Typography>
                )}
            </Stack>
            {/* )} */}
            <CreateCollectionModal
                open={openModal}
                onClickSubmit={onClickSubmit}
                onClickClose={onClickClose}
            />
        </Stack>
    );
};

export default CollectionsSubMenuLayout;

import React, { createContext, useContext, useState } from 'react';
import { WalletProviderType } from 'src/connectWalletLib/constant';

interface WalletConnectProviderProps {
    children: React.ReactNode;
}

interface ConnectWalletDataProps {
    connected: boolean;
    address: string;
    open: boolean;
    providerType: WalletProviderType;
    provider: any;
    connectedNetwork: number;
}

interface ConnectWalletContextProps {
    connect: ConnectWalletDataProps;
    setConnect: React.Dispatch<React.SetStateAction<ConnectWalletDataProps>>;
}

const connectWalletContext = createContext<ConnectWalletContextProps>(
    {} as ConnectWalletContextProps
);

export const useWalletConnect = () => useContext(connectWalletContext);

const WalletConnectProvider = ({ children }: WalletConnectProviderProps) => {
    const [connect, setConnect] = useState<ConnectWalletDataProps>({
        connected: false,
        address: '',
        open: false,
        providerType: WalletProviderType.METAMASK,
        provider: '',
        connectedNetwork: 0
    });
    return (
        <connectWalletContext.Provider value={{ connect, setConnect }}>
            {children}
        </connectWalletContext.Provider>
    );
};

export default WalletConnectProvider;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import { Configuration, RouterPath } from '@constants';
import HomeLayout from '@layouts/home/HomeLayout';
import { LoadingComponent } from '@components/shared';
import ConnectContext, { ConnectType } from '@state/ConnectContext';
import { theme, styles } from '@styles';
import '../styles/fonts.css';
import { GlobalStyles } from '@mui/material';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { MojitoApiProvider } from '@state/MojitoApiProvider';
import { Environment } from '@constants';

import '../localization';
import ContentFullContext, { ContentfullType } from '@state/ContentfullContext';
import { manageClient } from '@services/ContentFullClientService';
import StoreProvider from '@state/StoreProvider';
import { MojitoUploadProvider } from '@state/MojitoUploadProvider';
import ToastProvider from '@state/ToastProvider';
import LoadingProvider from '../state/LoadingProvider';
import Script from 'next/script';
import Head from 'next/head';
import { WalletConnectProvider as WalletConnectLibProvider } from 'src/connectWalletLib/provider/WalletConnectProvider';
import WalletConnectProvider from '@state/ConnectWalletProvider';
import MeProvider from '@state/MeProvider';
import OrganizationProvider from '@state/OrganizationProvider';
import GlobalContextProvider from '@state/GlobalContext';

const MyApp = ({ Component, pageProps }: AppProps) => {
    const [connect, setConnect] = useState<ConnectType>({
        connected: false,
        account: '',
        chainId: parseInt(Environment.CHAIN_ID) ?? 0
    });

    const [loading, setLoading] = useState(false);

    const [contentFul, setContentFul] = useState<ContentfullType>(
        {} as ContentfullType
    );

    const isOpened = useMemo(() => true, []);

    const router = useRouter();

    useEffect(() => {
        const handleStart = (url: string) => {
            url !== router.asPath && setLoading(true);
        };
        const handleComplete = () => {
            setLoading(false);
        };
        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleComplete);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleComplete);
        };
    });

    const onRedirectCallback = useCallback(() => {
        router.push(RouterPath.drops);
    }, [router]);

    const getContentFull = useCallback(async () => {
        const space = await manageClient.getSpace(Configuration.SPACEID ?? '');
        const env = await space.getEnvironment('master');
        setContentFul({
            space,
            env
        });
    }, []);

    useEffect(() => {
        getContentFull();
    }, [getContentFull]);

    const MyAppComponent = Component as any;

    return (
        <Auth0Provider
            domain={Environment.AUTH0_DOMAIN ?? ''}
            clientId={Environment.AUTH0_CLIENT_ID ?? ''}
            redirectUri={
                typeof window != 'undefined' ? window.location.origin : ''
            }
            onRedirectCallback={onRedirectCallback}
        >
            <Head>
                <script
                    // strategy="afterInteractive"
                    src={`https://www.googletagmanager.com/gtag/js?id=${Environment.GOOGLE_ANALYTICS_TRACKING_ID}`}
                />
                <script
                    id="gtag-init"
                    // strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${Environment.GOOGLE_ANALYTICS_TRACKING_ID}', {
                page_path: window.location.pathname,
            });
            `
                    }}
                />
                <Script
                    dangerouslySetInnerHTML={{
                        __html: `
          (function(apiKey){
            (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
            v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
        
                // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
                // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
                // Call this function after users are authenticated in your app and your visitor and account id values are available
                // Please use Strings, Numbers, or Bools for value types.
                pendo.initialize({
                    visitor: {
                        id:              'VISITOR-UNIQUE-ID'   // Required if user is logged in, default creates anonymous ID
                        // email:        // Recommended if using Pendo Feedback, or NPS Email
                        // full_name:    // Recommended if using Pendo Feedback
                        // role:         // Optional
        
                        // You can add any additional visitor level key-values here,
                        // as long as it's not one of the above reserved names.
                    },
        
                    account: {
                        id:           'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
                        // name:         // Optional
                        // is_paying:    // Recommended if using Pendo Feedback
                        // monthly_value:// Recommended if using Pendo Feedback
                        // planLevel:    // Optional
                        // planPrice:    // Optional
                        // creationDate: // Optional
        
                        // You can add any additional account level key-values here,
                        // as long as it's not one of the above reserved names.
                    }
                });
        })('16802b78-1060-4b6c-77fa-99a574d5f16e');
          `
                    }}
                />
            </Head>
            <WalletConnectProvider>
                <ConnectContext.Provider value={{ connect, setConnect }}>
                    <ContentFullContext.Provider value={contentFul}>
                        <WalletConnectLibProvider projectId="9d9a7f55b77da8f61a031cf718b773ca">
                            <MojitoApiProvider>
                                <MojitoUploadProvider>
                                    <GlobalContextProvider>
                                        <MeProvider>
                                            <OrganizationProvider>
                                                <LoadingProvider>
                                                    <StoreProvider>
                                                        <ThemeProvider
                                                            theme={theme}
                                                        >
                                                            <GlobalStyles
                                                                styles={styles}
                                                            />
                                                            <ToastProvider>
                                                                <HomeLayout
                                                                    isOpened={
                                                                        isOpened
                                                                    }
                                                                    isPageLoading={
                                                                        loading
                                                                    }
                                                                >
                                                                    <MyAppComponent
                                                                        {...pageProps}
                                                                        key={
                                                                            router.asPath
                                                                        }
                                                                    />
                                                                </HomeLayout>
                                                            </ToastProvider>
                                                            <LoadingComponent
                                                                show={loading}
                                                            />
                                                        </ThemeProvider>
                                                    </StoreProvider>
                                                </LoadingProvider>
                                            </OrganizationProvider>
                                        </MeProvider>
                                    </GlobalContextProvider>
                                </MojitoUploadProvider>
                            </MojitoApiProvider>
                        </WalletConnectLibProvider>
                    </ContentFullContext.Provider>
                </ConnectContext.Provider>
            </WalletConnectProvider>
        </Auth0Provider>
    );
};
export default MyApp;

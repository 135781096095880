import { TextField } from '@components/Atoms';
import { CreateSiteModalBodyLayoutProps } from '@interface/modalLayouts';
import { Box } from '@mui/material';
import React, { useCallback } from 'react';

const CreateSiteModalBodyLayout = ({
    onSetFields,
    fieldsInitialValue: { pageTitle, domainName }
}: CreateSiteModalBodyLayoutProps) => {
    const onTextChange = useCallback(
        (val: string, fieldName?: string) => {
            onSetFields(fieldName ?? '', val);
        },
        [onSetFields]
    );
    return (
        <Box>
            <TextField
                nolimit={false}
                name={'pageTitle'}
                title={'Site title'}
                value={pageTitle}
                onChange={onTextChange}
            />
            <TextField
                nolimit={false}
                name={'domainName'}
                title={'Custom website handle'}
                value={domainName}
                onChange={onTextChange}
            />
        </Box>
    );
};

export default CreateSiteModalBodyLayout;

import { MixTheme, StyledObject, Style } from '@styles';

export interface Submenu extends StyledObject {
    BoxText: Style;
    typoText: Style;
    normalBoxText: Style;
    dropSecond: Style;
    dropSubSecond: Style;
    BoxForTextCustomer: Style;
    typoSelectedText: Style;
    reportTypes: Style;
}

export const makeStyles = (theme: MixTheme) => {
    return {
        BoxText: {
            border: `1px solid ${theme?.global?.normal}`,
            backgroundColor: theme?.global?.hoverBackground,
            cursor: 'pointer',
            padding: '8px 12px',
            borderRadius: '4px',
            width: '130px'
        },
        typoText: {
            fontFamily: theme?.font?.tertiary,
            color: theme?.color?.black,
            fontSize: '14px',
            padding: '0 12px'
        },
        typoSelectedText: {
            fontFamily: theme?.font?.tertiary,
            color: theme?.global?.hover,
            fontSize: '14px'
        },
        normalBoxText: {
            border: `1px solid ${theme?.global?.normal}`,
            backgroundColor: theme?.global?.normal,
            marginTop: '15px',
            cursor: 'pointer'
        },
        dropSecond: {
            fontFamily: theme?.font?.tertiary,
            color: theme?.color?.textColor,
            fontSize: '14px',
            fontWeight: 700
        },
        dropSubSecond: {
            fontFamily: theme?.font?.sneak,
            fontSize: '14px',
            marginBottom: '14px',
            paddingLeft: '8px',
            fontWeight: 400,
            color: theme?.color?.black,
            cursor: 'pointer',

            borderRadius: '4px',
            '&:hover': {
                backgroundColor: theme?.global?.hoverBackground,
                color: theme?.palette?.primary?.main
            }
        },
        hoverColor: {
            backgroundColor: theme?.global?.hoverBackground,
            color: theme?.global?.mainlyBlue,
            padding: '6px 11px',
            borderRadius: '4px'
        },
        communitySection: {
            color: theme?.color?.textColor,
            fontWeight: 600,
            fontSize: '14px',
            marginBottom: '12px',
            fontFamily: theme?.font?.primary,
            textTransform: 'uppercase'
        },
        allCustomersSection: {
            fontWeight: 400,
            fontSize: '16px',
            marginLeft: '8px',
            fontFamily: theme?.font?.tertiary
        },
        allowlistsSection: {
            color: theme?.color?.textColor,
            fontWeight: 700,
            fontSize: '12px',
            fontFamily: theme?.font?.sneak,
            textTransform: 'uppercase'
        },
        addNewAllowlist: {
            fontSize: '12px',
            fontWeight: 700,
            color: theme?.global?.hover,
            fontFamily: theme?.font?.sneak,
            cursor: 'pointer'
        },
        createdAllowlists: {
            fontWeight: 400,
            fontSize: '16px',
            marginLeft: '8px',
            fontFamily: theme?.font?.sneak,
            marginBottom: '10px',
            cursor: 'pointer',
            '& :link': {
                textDecoration: 'none',
                color: theme?.global?.title
            },

            '& :visited': {
                textDecoration: 'none',
                color: theme?.global?.title
            },

            '& :hover': {
                textDecoration: 'none'
            },

            '& :active': {
                textDecoration: 'none',
                color: theme?.global?.title
            }
        },
        reportTypes: {
            fontWeight: 400,
            fontSize: '16px',
            marginLeft: '8px',
            fontFamily: theme?.font?.sneak,
            marginBottom: '10px',
            '& :link': {
                textDecoration: 'none',
                color: theme?.global?.title
            },

            '& :visited': {
                textDecoration: 'none',
                color: theme?.global?.title
            },

            '& :hover': {
                textDecoration: 'none'
            },

            '& :active': {
                textDecoration: 'none',
                color: theme?.global?.title
            }
        },
        BoxForTextCustomer: {
            padding: '10px',
            border: `1px solid ${theme?.global?.normal}`,
            cursor: 'pointer',
            borderRadius: '4px'
        }
    } as Submenu;
};

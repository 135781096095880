import * as React from 'react';
import Button, { ButtonProps } from './Button';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export { Button };
export const AddButton = (props: ButtonProps) => (
    <Button
        {...props}
        icon={<AddIcon sx={{ width: '16px', marginRight: '5px' }} />}
    />
);
export const SaveButton = (props: ButtonProps) => <Button {...props} />;
export const BackButton = (props: ButtonProps) => (
    <Button
        {...props}
        title={props.title || 'Back to Pages'}
        reverse={true}
        icon={<ArrowBackIosIcon sx={{ width: '14px' }} />}
    />
);

import { Column } from '../interface/discountCode';

export const discountCodeListColumns: Column[] = [
    {
        columnName: 'Name',
        dataName: 'discountName'
    },
    {
        columnName: 'Description',
        dataName: 'description'
    },
    {
        columnName: 'Code',
        dataName: 'discountCode'
    },
    {
        columnName: 'Usage',
        dataName: 'totalUsageLimit'
    },
    {
        columnName: 'Status',
        dataName: 'discountCodeStatus'
    }
];

export const lisitngColumn = [
    {
        columnName: 'Drop / Listing',
        dataName: 'name'
    },
    {
        columnName: 'Description',
        dataName: 'description'
    }
];

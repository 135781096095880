import { MixTheme, StyledObject, Style } from '@styles';

export interface AllowListStyle extends StyledObject {
    listHeading: Style;
    listContent: Style;
    listLink: Style;
    tableHeading: Style;
    tableBtn: Style;
    tableBtn2: Style;
    deleteBtn: Style;
    tableHead: Style;
    tableCell: Style;
    emptyEntryBtn: Style;
    anotherBtnLink: Style;
    container: Style;
    ctaBtn: Style;
    configureColumn: Style;
    addBtn: Style;
    addAllToList: Style;
    addAuth0IDsContainer: Style;
    addAuth0IDsAddAnotherContainer: Style;
    bulkRightAlignedCTA: Style;
    tableItem: Style;
    tableCellBg: Style;
}

export const makeStyles = (theme: MixTheme) => {
    return {
        listHeading: {
            margin: 0,
            fontWeight: 700,
            fontSize: '24px',
            color: theme?.color?.black
        },
        listContent: {
            color: theme?.color?.textColor,
            margin: 0,
            fontWeight: 500,
            fontSize: '16px'
        },
        listLink: {
            fontSize: '12px',
            fontWeight: 700,
            color: theme?.switch?.color,
            fontFamily: theme?.font?.primary,
            marginRight: '10px'
        },
        tableHeading: {
            maxHeight: '700px',
            overflow: 'auto',
            borderWidth: 1,
            borderColor: theme?.global?.gray90,
            borderStyle: 'solid'
        },
        tableBtn: {
            width: 'fit-content',
            textTransform: 'capitalize',
            backgroundColor: theme?.global?.hover,
            fontFamily: theme?.font?.tertiary,
            fontSize: '0.875rem',
            fontWeight: 700,
            margin: '12px',
            color: theme?.global?.normal,
            boxShadow: 'none',
            textAlign: 'center',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: theme?.global?.hover
            }
        },
        tableBtn2: {
            width: 'fit-content',
            textTransform: 'capitalize',
            backgroundColor: theme?.global?.hoverBackground,
            fontFamily: theme?.font?.tertiary,
            fontSize: '0.875rem',
            fontWeight: 700,
            margin: '12px',
            color: theme?.global?.hover,
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: theme?.global?.hoverBackground
            }
        },
        deleteBtn: {
            width: 'fit-content',
            textTransform: 'capitalize',
            backgroundColor: theme?.color?.white,
            fontFamily: theme?.font?.tertiary,
            fontSize: '0.875rem',
            fontWeight: 700,
            margin: '12px',
            color: theme?.color?.error,
            boxShadow: 'none',
            border: '1px solid' + theme?.color?.error,
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: theme?.color?.error,
                border: '1px solid' + theme?.color?.error
            }
        },
        tableHead: {
            backgroundColor: theme?.global?.background,
            position: 'sticky',
            top: 0,
            zIndex: 100,
            borderTop: '1px solid' + theme?.global?.defaultBackground
        },
        tableCell: {
            color: theme?.color?.textColor,
            fontFamily: theme?.font?.tertiary,
            fontWeight: 600,
            fontSize: '0.75rem'
        },
        emptyEntryBtn: {
            textTransform: 'capitalize',
            fontFamily: theme?.font?.tertiary,
            fontSize: '0.875rem',
            fontWeight: 700,
            margin: '12px',
            border: '1px solid' + theme?.global?.jacksonPurple,
            backgroundColor: theme?.global?.normal,
            color: theme?.global?.title,
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: theme?.global?.normal,
                border: '1px solid' + theme?.global?.jacksonPurple
            }
        },
        anotherBtnLink: {
            width: 'fit-content',
            textTransform: 'capitalize',
            fontFamily: theme?.font?.tertiary,
            fontSize: '0.875rem',
            fontWeight: 600,
            margin: '16px',
            backgroundColor: theme?.global?.normal,
            color: theme?.global?.hover,
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: theme?.global?.normal
            }
        },
        container: {
            padding: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderTop: 'none'
        },
        ctaBtn: {
            fontWeight: 600,
            fontFamily: theme?.font?.primary,
            color: theme?.global?.hover,
            marginLeft: '8px'
        },
        configureColumn: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 16px',
            borderBottom: '1px solid' + theme?.global?.shadow
        },
        noDataFound: {
            textAlign: 'center',
            padding: '50px 0',
            color: theme?.color?.textColor
        },
        addBtn: {
            border: '1px solid' + theme?.global?.jacksonPurple,
            color: theme?.global?.title,
            borderRadius: '4px',
            fontSize: '12px'
        },
        addAllToList: {
            padding: '8px 14px',
            borderRadius: '4px',
            marginRight: '15px',
            color: theme?.global?.inProgressBackground,
            '&:hover': {
                backgroundColor: theme?.global?.lightGray
            }
        },
        addAuth0IDsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        addAuth0IDsAddAnotherContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        bulkRightAlignedCTA: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableItem: {
            cursor: 'pointer'
        },
        tableCellBg: {
            background: theme?.global?.lightBackground,
            color: theme?.global?.jacksonPurple,
            fontSize: '12px',
            fontWeight: 600,
            padding: '17px'
        }
    } as AllowListStyle;
};

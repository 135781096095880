import React from 'react';
import { TextField } from '@components/Atoms';
import { AutoComplete } from '@components/shared';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MixTheme } from '@styles';

interface DropdownTypeValue {
    label: string;
    value: string;
}

const DropDownType: DropdownTypeValue[] = [
    {
        label: 'Sale Drop',
        value: 'SALE'
    },
    {
        label: 'Claimable Drop',
        value: 'CLAIMABLE'
    }
];

const saleDownType: DropdownTypeValue[] = [
    {
        label: 'Active',
        value: 'Active'
    },
    {
        label: 'Inactive',
        value: 'Inactive'
    },
    {
        label: 'Archived',
        value: 'Archived'
    }
];

interface FormikProps {
    formik: any;
}

const DropModal = ({ formik }: FormikProps) => {
    const theme: MixTheme = useTheme();

    return (
        <Stack>
            <Typography
                sx={{
                    fontFamily: theme?.font?.tertiary,
                    fontSize: '16px',
                    fontWeight: 400
                }}
            >
                Drop name
            </Typography>
            <TextField
                onChange={formik?.handleChange('createDropName')}
                value={formik?.values?.createDropName}
                marginBottom="0"
                inputProps={{
                    style: {
                        padding: 9,
                        background: theme?.color?.white
                    },
                    maxLength: 100
                }}
                onBlur={formik?.handleBlur('createDropName')}
                error={
                    formik?.errors?.createDropName &&
                    formik?.touched?.createDropName
                }
                helperText={formik?.errors?.createDropName}
            />
            &nbsp;
            <Typography
                sx={{
                    fontFamily: theme?.font?.tertiary,
                    fontSize: '16px',
                    fontWeight: 400
                }}
            >
                Drop Type
            </Typography>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem'
                }}
            >
                <AutoComplete
                    searchData={DropDownType}
                    valueKey={'value'}
                    labelKey={'label'}
                    value={formik?.values?.createDropType}
                    defaultValue={''}
                    width={'100%'}
                    onChange={async (data) => {
                        await formik?.setFieldValue(
                            'createDropType',
                            data.value
                        );
                    }}
                    size="small"
                    onBlur={formik?.handleBlur('createDropType')}
                    error={
                        formik?.errors?.createDropType &&
                        formik?.touched?.createDropType
                            ? formik?.errors?.createDropType
                            : undefined
                    }
                    helperText={formik?.errors?.createDropType}
                />
            </Box>
            {formik?.values?.createDropType !== '' &&
                formik?.values?.createDropType === 'SALE' && (
                    <Typography
                        style={{
                            color: theme?.color?.slate800,
                            fontWeight: 400,
                            fontSize: '14px',
                            margin: '5px'
                        }}
                    >
                        Sale drops allow you to setup a Drop for your NFTs
                    </Typography>
                )}
            {formik?.values?.createDropType !== '' &&
                formik?.values?.createDropType === 'CLAIMABLE' && (
                    <Typography
                        style={{
                            color: theme?.color?.slate800,
                            fontWeight: 400,
                            fontSize: '14px',
                            margin: '5px'
                        }}
                    >
                        Claimable drops allow you to setup a Drop for your NFTs
                        with claim codes
                    </Typography>
                )}
            &nbsp;
            <Typography
                sx={{
                    fontFamily: theme?.font?.tertiary,
                    fontSize: '16px',
                    fontWeight: 400
                }}
            >
                Status
            </Typography>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem'
                }}
            >
                <AutoComplete
                    searchData={saleDownType}
                    valueKey={'value'}
                    labelKey={'label'}
                    value={formik?.values?.status}
                    defaultValue={''}
                    width={'100%'}
                    onChange={async (data) => {
                        await formik?.setFieldValue('status', data.value);
                    }}
                    size="small"
                />
            </Box>
        </Stack>
    );
};
export default DropModal;

import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { AutoComplete } from '@components/shared';
import { ModalDialog, TextField } from '@components/Atoms';
import { MixTheme } from '@styles';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { supportedNetworks } from '@graphql/queries/loginQueries';
import { useStore } from '@state/StoreProvider';
import { StoreProviderSkipRequests } from 'src/constants/Configuration';
import { truncateStringInTheMiddle } from '@utils/truncateStrings';
import { GET_WALLETS_QUERY } from '@graphql/queries/wallets';
import { useLoading } from '@state/LoadingProvider';

interface CreateCollectionModalProps {
    open: boolean;
    onClickClose: () => void;
    onClickSubmit: (values: any) => void;
}

interface DropdownValues {
    label: string;
    value: string;
}

type InitiCollectionForm = {
    orgId: string;
    contractType: string;
    networkId: string;
    collectionType: string;
    contractName: string;
    contractSymbol: string;
    walletId: string;
};

export const contractTypes: DropdownValues[] = [
    {
        label: 'ERC-721',
        value: 'ERC721Creator'
    },
    {
        label: 'ERC-1155',
        value: 'ERC1155Creator'
    }
];

export const collectionTypes: DropdownValues[] = [
    {
        label: 'Limited Edition',
        value: 'LimitedEdition'
    },
    {
        label: 'Open Edition',
        value: 'OpenEdition'
    }
];

const CollectionSchema = Yup.object().shape({
    contractName: Yup.string()
        .required('Please enter name')
        .trim()
        .min(2, 'Atleast two characters in name')
        .max(100, 'Maximum character should be less than 100'),
    contractSymbol: Yup.string()
        .required('Please enter contract symbol')
        .trim()
        .max(10, 'Maximum 10 characters allowed'),
    collectionType: Yup.string().required('Please select a collection type'),
    networkId: Yup.string().required('Please select a network'),
    walletId: Yup.string().required('Please select a wallet'),
    contractType: Yup.string().required('Please select a contract Type')
});

const CreateCollectionBodyLayout = () => {
    const theme: MixTheme = useTheme();
    const { values, errors, setFieldValue, handleChange, touched, handleBlur } =
        useFormikContext<InitiCollectionForm>();
    const { setLoading } = useLoading();
    const { currentOrganizationId, setSkipRequests } = useStore();

    const [walletsData, setWalletsData] = useState([]);
    const [isBinded, setIsBinded] = useState<boolean>(false);

    const { data: apiWallets, loading: walletsLoading } = useQuery(GET_WALLETS_QUERY, {
        fetchPolicy: 'no-cache',
        variables: {
            orgID: currentOrganizationId,
            isDeployed: true,
            networkID: values?.networkId
        },
        skip: !currentOrganizationId || isBinded || !values?.networkId
    });

    useEffect(() => {
        setSkipRequests({
            ...StoreProviderSkipRequests,
            // organizationQuery: false,
            meQuery: false
        });
    }, [setSkipRequests]);
    const { data: networksData } = useQuery(supportedNetworks, {
        variables: {
            orgId: currentOrganizationId
        },
        skip: !currentOrganizationId
    });

    useEffect(() => {
        if (!walletsLoading && !isBinded) {
            setIsBinded(true);
            if (apiWallets?.getWallets?.data?.length > 0) {
                setWalletsData(apiWallets?.getWallets?.data);
            } else {
                setWalletsData([]);
            }
        }
    }, [apiWallets?.getWallets?.data, isBinded, walletsLoading]);

    useEffect(() => {
        setLoading(walletsLoading);
    }, [setLoading, walletsLoading]);

    const formattedWallets = useMemo(() => {
        return (
            walletsData?.map((item) => ({
                name: `${item.name} (${truncateStringInTheMiddle(item.address)})`,
                id: item.id
            })) ?? []
        );
    }, [walletsData]);

    const isTestNet = useMemo(() => {
        if (values.networkId) {
            const found = networksData?.getSupportedNetworks?.filter((item) => item.id == values.networkId);
            return found?.length > 0 && found?.[0]?.isTestnet ? true : false;
        }
        return false;
    }, [networksData?.getSupportedNetworks, values.networkId]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    nolimit={false}
                    limitLength={100}
                    value={values?.contractName}
                    onChange={handleChange('contractName')}
                    placeholder="e.g. Iced Mojito"
                    title="Enter a name for your new contract"
                    inputProps={{ maxLength: 100 }}
                    name={'contractName'}
                    error={errors?.contractName && touched?.contractName}
                    helperText={errors?.contractName}
                    onBlur={handleBlur('contractName')}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    nolimit={true}
                    maxlength={10}
                    value={values?.contractSymbol}
                    onChange={handleChange('contractSymbol')}
                    placeholder="e.g. IMJ"
                    title="Contract Symbol"
                    error={errors?.contractSymbol && touched?.contractSymbol}
                    helperText={errors?.contractSymbol}
                    onBlur={handleBlur('contractSymbol')}
                />
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1" sx={{ marginBottom: '6px' }}>
                    Network
                </Typography>
                <AutoComplete
                    searchData={networksData?.getSupportedNetworks ?? []}
                    valueKey={'id'}
                    labelKey={'name'}
                    value={values?.networkId}
                    defaultValue={''}
                    width={'100%'}
                    onChange={(data: any) => {
                        setIsBinded(false);
                        setFieldValue('networkId', data.id);
                    }}
                    onBlur={handleBlur('networkId')}
                    error={errors?.networkId && touched?.networkId ? errors?.networkId : undefined}
                    helperText={errors?.networkId}
                />
                {isTestNet && (
                    <Typography sx={{ color: theme?.color?.slate800 }}>
                        This network is used for test collections
                    </Typography>
                )}
            </Grid>
            {values?.networkId && (
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{ marginBottom: '6px' }}>
                        Deployment Wallet
                    </Typography>
                    <Typography sx={{ color: theme?.color?.slate800 }}>
                        Select the wallet where you want the Collection Contract deployed
                    </Typography>
                    <AutoComplete
                        searchData={formattedWallets}
                        placeholder="Select one..."
                        valueKey={'id'}
                        labelKey={'name'}
                        value={values?.walletId}
                        defaultValue={''}
                        onChange={async (data: any) => await setFieldValue('walletId', data.id)}
                        error={errors?.walletId && touched?.walletId ? errors?.walletId : undefined}
                        onBlur={handleBlur('walletId')}
                        helperText={errors?.walletId}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <Typography variant="body1" sx={{ marginBottom: '6px' }}>
                    Collection Type
                </Typography>
                <AutoComplete
                    searchData={collectionTypes}
                    placeholder="Select one..."
                    valueKey={'value'}
                    labelKey={'label'}
                    value={values?.collectionType}
                    defaultValue={''}
                    onChange={(data: any) => setFieldValue('collectionType', data.value)}
                    onBlur={handleBlur('collectionType')}
                    error={
                        errors?.collectionType && touched?.collectionType ? errors?.collectionType : undefined
                    }
                    helperText={errors?.collectionType}
                />

                {!errors?.collectionType && values.collectionType && (
                    <Typography
                        variant="body2"
                        sx={{
                            marginTop: '6px',
                            color: theme?.color?.slate800
                        }}
                    >
                        {values.collectionType == 'LimitedEdition' &&
                            'A Limited Edition is a digital creation that can have multiple copies, each owned by different individuals. You can mint these NFTs and prepare for your drop accordingly.'}
                        {values.collectionType == 'OpenEdition' &&
                            'An Open Edition has no predetermined number of creations. You can create approximate caps and limit the number of mints per wallet. Open edition NFTs are not pre-minted but will be minted and delivered during the Purchase or claim. '}
                        {values.collectionType == 'Limited Edition' && ''}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" sx={{ marginBottom: '6px' }}>
                    Contract Type
                </Typography>
                <AutoComplete
                    searchData={contractTypes}
                    placeholder="Select one..."
                    valueKey={'value'}
                    labelKey={'label'}
                    value={values?.contractType}
                    defaultValue={''}
                    onChange={(data: any) => setFieldValue('contractType', data?.value)}
                    onBlur={handleBlur('contractType')}
                    error={errors?.contractType && touched?.contractType ? errors?.contractType : undefined}
                    helperText={errors?.contractType}
                />
            </Grid>
        </Grid>
    );
};

const CreateCollectionModal: React.FC<CreateCollectionModalProps> = ({
    onClickClose,
    open,
    onClickSubmit
}: CreateCollectionModalProps) => {
    const formik = useFormik({
        initialValues: {
            orgId: '',
            contractType: '',
            networkId: '',
            collectionType: '',
            contractName: '',
            contractSymbol: '',
            walletId: ''
        },
        onSubmit: async (formValues, { setSubmitting }) => {
            try {
                const response = await onClickSubmit(formValues);
                if (response) {
                    handleClose();
                    return true;
                }
            } finally {
                setSubmitting(false);
            }
        },
        validationSchema: CollectionSchema,
        validateOnMount: false,
        validateOnChange: true
    });

    const { handleSubmit, isSubmitting, resetForm } = formik;

    const handleClose = useCallback(() => {
        onClickClose();
        resetForm();
    }, [onClickClose, resetForm]);

    return (
        <FormikProvider value={formik}>
            <ModalDialog
                disableBackDrop={true}
                open={open}
                otherWidth={'md'}
                isSubmitEnabled={!isSubmitting}
                title={'Create a Collection'}
                body={<CreateCollectionBodyLayout />}
                onCancel={handleClose}
                onClose={handleClose}
                submitButtonText="Continue"
                onSubmit={handleSubmit}
            />
        </FormikProvider>
    );
};

export default CreateCollectionModal;

import * as React from 'react';
import MUISwitch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

interface SwitchProps {
    onChange: (value: string) => void;
    value?: string | boolean;
    disabled?: boolean;
}

export const Switch: React.FC<SwitchProps> = ({
    onChange,
    value,
    disabled = false
}: SwitchProps): JSX.Element => {
    return (
        <MUISwitch
            {...label}
            onChange={(e) => onChange(e.target.checked ? 'on' : 'off')}
            checked={value === 'on' || value === true}
            disabled={disabled}
        />
    );
};

import * as React from 'react';
import { useCallback, useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { CoreServiceProvider } from '@mojito-inc/core-service';
import { useAuth0 } from '@auth0/auth0-react';
import CustomersSubMenuLayout from '@layouts/submenus/customers.layout';
import SitesSubmenuLayout from '@layouts/submenus/sites.layout';
import SuperadminSubmenuLayout from '@layouts/submenus/superadmin.layout';
import { useRouter } from 'next/router';
import DropSubMenuLayout from '@layouts/submenus/drops.layout';
import CollectionsSubMenuLayout from '@layouts/submenus/collections.layout';
import { client, manageClient } from '@services/ContentFullClientService';
import {
    Configuration,
    Environment,
    Images,
    StoreProviderSkipRequests
} from '@constants';
import { createRequestObject } from '@services/ContentFulService';
import { StorageService } from '@services/StorageService';
import { Organization, orgCreateMarketplace } from '@graphql/queries/query';
import {
    addPageToSite,
    PagesTypes
} from '@pages/sites/[siteId]/pages/index.service';
import Drawer from './Drawer';
import { topMenuItem, bottomMenuItem } from './Config';
import MenuPopup from './MenuPopup';
import { SiteOptions } from '@interface/PageSite';
import { useSideMenu } from './useSideMenu';
import { useStore } from '@state/StoreProvider';
import {
    ApolloClient,
    useApolloClient,
    useMutation,
    useQuery
} from '@apollo/client';
import SettingsSubmenuLayout from '@layouts/submenus/settings.layout';
import CreateSiteModal, {
    CreateSiteProps
} from '@layouts/submenus/createSiteModal.layout';
import ReportsSubMenuLayout from '@layouts/submenus/reports.layout';
import { ConnectWalletContainer } from 'src/connectWalletLib';
import { connectWalletTheme } from 'src/theme';
import { useWalletConnect } from '@state/ConnectWalletProvider';
import { WalletDetailsData } from 'src/connectWalletLib/interface';
import { WalletProviderType } from 'src/connectWalletLib/constant';
import { useGlobalState } from '@state/GlobalContext';
import { useOrganizationStore } from '@state/OrganizationProvider';
import { useMeStore } from '@state/MeProvider';

type SidebarProps = {
    isOpened: boolean;
    setContainerWidth: React.Dispatch<React.SetStateAction<string>>;
};
export interface StyleProps {
    isOpened: boolean;
    onlyMobile: boolean;
    minWidth: string;
    display: string;
}

const SiteFieldsInitialValues: CreateSiteProps = {
    pageTitle: '',
    domainName: '',
    siteId: '',
    orgId: ''
};

const Sidebar = ({ isOpened, setContainerWidth }: SidebarProps) => {
    const { activeMenu, setActiveMenu, hideSubMenu } = useSideMenu();
    const router = useRouter();
    const { setSkipRequests, currentOrganizationId } = useStore();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [sites, setSites] = useState<SiteOptions[]>([]);
    const { connect, setConnect } = useWalletConnect();
    const [createSiteId] = useMutation(orgCreateMarketplace);
    const [siteCreateFields, setSiteFields] = useState<CreateSiteProps>(
        SiteFieldsInitialValues
    );

    //For organization Optimization change
    const initialDataBind = useRef<boolean | null>(null);
    const { setData: setGlobalData, data: globalData } = useGlobalState();
    const { meData } = useMeStore();
    const {
        organizationHandle,
        setOrganizationData,
        setOrganizationLoading,
        organizationData
    } = useOrganizationStore();
    const apiClient: ApolloClient<object> = useApolloClient();

    // initial Fetch Organization QUery
    useEffect(() => {
        if (
            !initialDataBind.current &&
            meData?.me?.userOrgs &&
            !organizationData
        ) {
            initialDataBind.current = true;
            setGlobalData((prev) => ({
                ...prev,
                isRefetchOrganization: true
            }));
        }
    }, [
        meData?.me?.userOrgs,
        organizationHandle,
        organizationData,
        setGlobalData
    ]);

    useEffect(() => {
        (async () => {
            if (globalData?.isRefetchOrganization && organizationHandle) {
                setGlobalData((prev) => ({
                    ...prev,
                    isRefetchOrganization: false
                }));
                setOrganizationLoading(true);
                const response = await apiClient.query({
                    query: Organization,
                    variables: {
                        handle: organizationHandle
                    },
                    fetchPolicy: 'no-cache'
                });
                setOrganizationLoading(false);
                setOrganizationData(response.data);
            }
        })();
    }, [
        apiClient,
        globalData?.isRefetchOrganization,
        organizationHandle,
        setGlobalData,
        setOrganizationData,
        setOrganizationLoading
    ]);

    useEffect(() => {
        setSkipRequests({ ...StoreProviderSkipRequests, meQuery: false });
    }, [setSkipRequests]);

    const openDialog = useCallback(() => {
        setSiteFields(SiteFieldsInitialValues);
        setAnchorEl(null);
        setIsOpenModal(true);
    }, []);
    const closeDialog = useCallback(() => {
        setSiteFields(SiteFieldsInitialValues);
        setIsOpenModal(false);
    }, []);

    const onCreateSiteFields = useCallback(
        (fieldName: string, value: string) => {
            setSiteFields((sitesValue) => ({
                ...sitesValue,
                [fieldName]: value
            }));
        },
        []
    );

    const handleCloseModal = useCallback(() => {
        setConnect((prev) => ({
            ...prev,
            open: false
        }));
    }, [setConnect]);

    const submitDialog = useCallback(async () => {
        const space = await manageClient.getSpace(Configuration.SPACEID ?? '');
        const env = await space.getEnvironment('master');
        const contentTypes = await env.getContentType('siteSetting');
        const orgId = StorageService.orgId.getValue();
        const siteCreateFieldsData = { ...siteCreateFields };
        const values = {
            name: siteCreateFieldsData.pageTitle,
            orgId:
                orgId != 'undefined' || undefined
                    ? orgId
                    : currentOrganizationId
        };
        const siteid_ = await createSiteId({
            variables: values
        });
        siteCreateFieldsData.orgId =
            orgId != 'undefined' && orgId != null
                ? orgId
                : currentOrganizationId;
        siteCreateFieldsData.siteId = siteid_.data.orgCreateMarketplace.id;
        const requestBody = await createRequestObject(
            contentTypes.fields,
            siteCreateFieldsData
        );
        const create = await env.createEntry('siteSetting', {
            fields: {
                ...requestBody
            }
        });
        await create.publish();
        setAnchorEl(null);
        setIsOpenModal(false);

        for (const page of PagesTypes) {
            if (page) {
                const data = {
                    siteId: siteCreateFieldsData.siteId,
                    orgId: orgId,
                    pageTitle: page.title,
                    slug: page.title
                };
                await addPageToSite(data, page, env);
            }
        }
        setActiveMenu('sites');
        router.push(`/sites/${siteCreateFieldsData.siteId}/overview`);
    }, [
        currentOrganizationId,
        siteCreateFields,
        createSiteId,
        router,
        setActiveMenu
    ]);

    const loadSubMenus = useCallback(async () => {
        const orgId = StorageService.orgId.getValue();
        try {
            const space = await client.getEntries({
                content_type: 'siteSetting',
                'fields.orgId[match]': orgId
            });
            const filteredSites = space.items.map((item) => item.fields);
            setSites(filteredSites as SiteOptions[]);
        } catch (err) {
            console.log(err);
        }
    }, []);

    const handleClose = useCallback(() => {
        setSiteFields(SiteFieldsInitialValues);
        setAnchorEl(null);
    }, []);

    const { logout } = useAuth0();

    const handleChange = useCallback(
        async (id: string, event: React.MouseEvent<HTMLElement>) => {
            if (id === 'profile') {
                localStorage.clear();
                logout({ returnTo: window.location.origin });
                return;
            } else if (id === 'sites') {
                const { currentTarget } = event;
                await loadSubMenus();
                setAnchorEl(currentTarget);
                return;
            }
            setActiveMenu(id);
        },
        [logout, loadSubMenus, setActiveMenu]
    );

    const handleSubMenuSelect = useCallback(
        (menu: string, id: string) => {
            setActiveMenu(menu);
            router.push(`/sites/${id}/overview`);
            setAnchorEl(null);
        },
        [router, setActiveMenu]
    );

    const handleChangeWalletAddress = useCallback(
        (data: WalletDetailsData) => {
            if (data?.walletAddress) {
                setConnect((prev) => ({
                    ...prev,
                    address: data?.walletAddress,
                    connected: true,
                    open: false,
                    providerType: data?.providerType as WalletProviderType,
                    provider: data?.provider,
                    connectedNetwork: data?.networkDetails?.chainID
                }));
            }
        },
        [setConnect]
    );

    return (
        <Box>
            <>
                <Drawer
                    open={isOpened}
                    selectedMenu={activeMenu}
                    topMenuItem={topMenuItem}
                    bottomMenuItem={bottomMenuItem}
                    hideSubMenu={hideSubMenu}
                    setContainerWidth={setContainerWidth}
                    onChange={handleChange}
                >
                    {activeMenu == 'customers' && <CustomersSubMenuLayout />}
                    {activeMenu == 'sites' && <SitesSubmenuLayout />}
                    {activeMenu == 'superadmin' && <SuperadminSubmenuLayout />}
                    {activeMenu == 'collections' && (
                        <CollectionsSubMenuLayout />
                    )}
                    {activeMenu == 'marketplace' && <DropSubMenuLayout />}
                    {activeMenu == 'settings' && <SettingsSubmenuLayout />}
                    {activeMenu == 'reports' && <ReportsSubMenuLayout />}
                </Drawer>
                <ThemeProvider theme={connectWalletTheme}>
                    <CoreServiceProvider
                        uri={Environment.API_HOST_URL}
                        token={StorageService.token.getValue() ?? ''}
                    >
                        <ConnectWalletContainer
                            open={connect?.open}
                            // isWeb2Login={ false }
                            skipSignature
                            config={{
                                orgId: currentOrganizationId ?? '',
                                paperClientId: Environment.PAPER_CLIENT_ID,
                                paperNetworkName:
                                    Environment.PAPER_NETWORK_NAME,
                                chainId: +Environment?.CHAIN_ID ?? 1,
                                projectId: Environment.PROJECT_ID
                            }}
                            walletOptions={{
                                enableEmail: false,
                                enableMetamask: true,
                                enableWalletConnect: false
                            }}
                            onChangeWalletAddress={handleChangeWalletAddress}
                            content={{
                                otpContentData: {
                                    title: 'Connect to Pace Verso'
                                },
                                emailContentData: {
                                    title: 'Connect to Pace Verso'
                                }
                            }}
                            image={{
                                logo: '',
                                metamask: Images.META_MASK.src,
                                walletConnect: Images.WALLET_CONNECT.src
                            }}
                            isDisConnect={false}
                            walletAddress={connect?.address}
                            isRefetchBalance={false}
                            onCloseModal={handleCloseModal}
                        />
                    </CoreServiceProvider>
                </ThemeProvider>
                <MenuPopup
                    open={isOpened}
                    items={sites}
                    anchorElement={anchorEl}
                    onNewClick={openDialog}
                    onDismiss={handleClose}
                    onSelect={handleSubMenuSelect}
                />
                {isOpenModal && (
                    <CreateSiteModal
                        isOpenModal={isOpenModal}
                        siteCreateFields={siteCreateFields}
                        closeDialog={closeDialog}
                        submitDialog={submitDialog}
                        onCreateSiteFields={onCreateSiteFields}
                    />
                )}
            </>
        </Box>
    );
};

export default Sidebar;

import { Box, Stack, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { useStyle } from '@styles';
import { makeStyles } from './settings.style';
import { RouterPath } from '@constants';

interface Menus {
    to: string;
    name: string;
}

const SettingsSubmenuLayout = () => {
    const { styles, theme } = useStyle(makeStyles);
    const router = useRouter();

    const generalMenu: Menus[] = [
        {
            to: RouterPath.organizationInfo,
            name: 'Organization'
        },
        {
            to: RouterPath.accountInfo,
            name: 'Account Information'
        },
        {
            to: RouterPath.walletSettings,
            name: 'Wallets'
        },
        {
            to: RouterPath.apiKeysSettings,
            name: 'API Keys'
        },
        {
            to: RouterPath.teamMembers,
            name: 'Team Members'
        }
    ];

    const renderItem = useCallback(
        (item: Menus) => {
            return (
                <Link href={item.to} key={item.to}>
                    <Box
                        sx={
                            item.to === router.asPath ||
                            router.pathname === item.to
                                ? styles.menuListSelected
                                : styles.menuList
                        }
                    >
                        <Stack
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            margin={'5px 0 5px 0'}
                        >
                            <Typography
                                variant="body1"
                                sx={styles.subMenuSelected}
                            >
                                {item.name}
                            </Typography>
                        </Stack>
                    </Box>
                </Link>
            );
        },
        [
            router.asPath,
            router.pathname,
            styles.menuList,
            styles.menuListSelected,
            styles.subMenuSelected
        ]
    );

    return (
        <Box sx={styles.card}>
            <Typography variant="h4" style={styles.cardTitle}>
                Settings
            </Typography>
            <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={styles.menuTitle}
            >
                <Typography
                    variant="subtitle2"
                    style={{
                        color: theme?.color?.textColor,
                        fontWeight: 700,
                        fontSize: '14px',
                        fontFamily: theme?.font?.primary
                    }}
                >
                    General
                </Typography>
            </Stack>
            <Stack>{generalMenu.map((item) => renderItem(item))}</Stack>
        </Box>
    );
};

export default SettingsSubmenuLayout;

import { gql } from '@apollo/client';

export const ADD_ORGANIZATION_MUTATION = gql`
    mutation CreateOrgByUser($handle: String!, $name: String!) {
        createOrgByUser(handle: $handle, name: $name) {
            id
            userId
            organizationId
        }
    }
`;

export const CREATE_COLLECTION_INTERNAL_MUTATION = gql`
    mutation CreateNftContractDraft($input: NftCreateContractInput!) {
        CreateNftContractDraft(input: $input) {
            id
            networkId
            nftContractType {
                id
                name
            }
            name
            symbol
            wallet {
                id
            }
        }
    }
`;

export const UPDATE_COLLECTION_INTERNAL_MUTATION = gql`
    mutation UpdateNftContractDraft(
        $contractId: UUID1!
        $input: NftContractDeploymentInput
    ) {
        UpdateNftContractDraft(contractId: $contractId, input: $input) {
            id
            nftContractId
            nftContract {
                id
                nftContractType {
                    id
                    name
                }
                name
                symbol
                networkId
                wallet {
                    id
                }
            }
            collectionType
            collectionDescription
            status
            asciiMark
            deploymentType
            mintingMethod
            deploymentFlag
            baseContractStatus
            extensionContractStatus
            registerExtensionStatus
            hotWalletApprovalStatus
            delayedRevealStatus
            isRedeemable
            redeemableCollectionName
            redeemableCollectionSymbol
            redeemMaxLimit
        }
    }
`;

export const CONTRACT_DEPLOYMENT_MUTATION = gql`
    mutation NftDeployContractV2($input: DeployContractInputV2) {
        NftDeployContractV2(input: $input) {
            id
        }
    }
`;

export const CONTRACT_DELETE_MUTATION = gql`
    mutation DeleteNftContractDraft($nftContractID: UUID1!) {
        DeleteNftContractDraft(nftContractID: $nftContractID)
    }
`;

export const CREATE_TOKEN_MUTATION = gql`
    mutation createTokenDraft($tokens: [TokenDraft!]!, $contractId: UUID1!) {
        createTokenDraft(tokens: $tokens, contractId: $contractId)
    }
`;

export const UPDATE_TOKEN_MUTATION = gql`
    mutation updateTokenDraft($token: TokenDraft!) {
        updateTokenDraft(token: $token)
    }
`;

export const UPLOADARWEAVE_ASSET_MUTATION = gql`
    mutation uploadArweaveAssetV2Mutation($assetVersionId: UUID1!) {
        uploadArweaveAssetV2(assetVersionId: $assetVersionId) {
            arweaveTx
        }
    }
`;

export const UPLOADARWEAVE_TOKEN_MUTATION = gql`
    mutation uploadArweaveMetadata($tokenId: UUID1!) {
        uploadArweaveMetadata(tokenId: $tokenId)
    }
`;

export const UPLOADDYNAMIC_METADATA_TOKEN_MUTATION = gql`
    mutation UploadDynamicNftMetadata($tokenId: UUID1!) {
        UploadDynamicNftMetadata(tokenId: $tokenId)
    }
`;

export const UPLOADDYNAMIC_ASSET_MUTATION = gql`
    mutation (
        $orgId: UUID1!
        $tokenId: UUID1!
        $file: Upload!
        $name: String!
    ) {
        uploadDynamicNftAsset(
            orgId: $orgId
            tokenId: $tokenId
            file: $file
            name: $name
        ) {
            cdnUrl
            assetID
        }
    }
`;

export const MINT_TOKEN_MUTATION = gql`
    mutation mintTokens($tokenIds: [UUID1!]!) {
        mintTokens(tokenIds: $tokenIds)
    }
`;

export const DUPLICATE_TOKEN_MUTATION = gql`
    mutation createDuplicateToken($TokenId: UUID1!) {
        createDuplicateToken(TokenId: $TokenId) {
            id
        }
    }
`;

export const CONFIRM_DUTCHAUCTION_REBATE_MUTATION = gql`
    mutation confirmDutchAuctionRebate($lotID: UUID1!, $txHash: String!) {
        confirmDutchAuctionRebate(lotID: $lotID, txHash: $txHash)
    }
`;

export const DUTCHAUCTION_REBATE_MUTATION = gql`
    mutation dutchAuctionRebate($lotID: UUID1!, $walletAddress: String) {
        dutchAuctionRebate(lotID: $lotID, walletAddress: $walletAddress)
    }
`;

export const CONFIRM_DUTCHAUCTION_END_MUTATION = gql`
    mutation confirmDutchAuctionEnd($lotID: UUID1!, $endTxHash: String) {
        confirmDutchAuctionEnd(lotID: $lotID, endTxHash: $endTxHash)
    }
`;

export const UPDATE_CONTRACT_DEPLOYMENT_MUTATION = gql`
    mutation UpdateContractDepoymentStatus($input: ContractDeployment!) {
        UpdateContractDepoymentStatus(input: $input)
    }
`;

export const SAVE_DUTCH_AUCTION = gql`
    mutation SaveDutchAuction(
        $lotID: UUID1
        $orgID: UUID1!
        $input: dutchAuctionSettingInput!
        $lotInput: DutchAuctionLot
    ) {
        saveDutchAuction(
            lotID: $lotID
            orgID: $orgID
            input: $input
            lotInput: $lotInput
        ) {
            id
            marketplaceAuctionLotID
            nftContractAddress
            tokenID
            startingBidCryptoPrice
            endAuctionStatus
            tokenType
            fromTokenID
            toTokenID
            rebateEnabled
            commissionFeePercentage
            platformFeePercentage
        }
    }
`;

export const DEPLOY_DUTCHAUCTION = gql`
    mutation deployDutchAuction(
        $lotID: UUID1!
        $deployer: Boolean!
        $txHash: String
    ) {
        deployDutchAuction(lotID: $lotID, deployer: $deployer, txHash: $txHash)
    }
`;

export const SET_APPROVAL_FOR_ALL = gql`
    mutation SetApprovalForAll(
        $nftContractID: UUID1!
        $approvalAddress: String!
    ) {
        setApprovalForAll(
            nftContractID: $nftContractID
            approvalAddress: $approvalAddress
        ) {
            NFTContractId
            approvalAddress
            NFTContractAddress
        }
    }
`;

export const REGISTER_EXTENSION = gql`
    mutation registerAuctionExtension(
        $contractAddress: String!
        $networkID: UUID1!
        $orgID: UUID1!
        $extensionAddress: String
    ) {
        registerAuctionExtension(
            contractAddress: $contractAddress
            networkID: $networkID
            orgID: $orgID
            extensionAddress: $extensionAddress
        )
    }
`;

export const REVEALNFTS = gql`
    mutation revealNFTS($contractID: UUID1!) {
        revealNFTS(contractID: $contractID)
    }
`;

export const ORGANIZATIONSETUP = gql`
    mutation organizationSetup($input: OrganizationSetup!) {
        organizationSetup(input: $input) {
            organization {
                id
                handle
                name
                jwtIssuerDomain
                taxExempt
            }
            marketplace {
                id
                isSecondaryMarketPlace
                name
                organizationID
                wirePaymentExpiration
            }
            supportedCurrencies {
                id
                name
                networkId
            }
            supportedNetwork {
                id
                organizationID
                networkId
                defaultPaymentCurrencyID
            }
        }
    }
`;

export const GET_BULK_UPLOAD_ASSET_CSV = gql`
    mutation GetBulkUploadAssetCSV(
        $contractID: UUID1!
        $csvType: BulkUploadCsvType!
        $isMetaDataUploaded: Boolean
    ) {
        getBulkUploadAssetCSV(
            contractID: $contractID
            csvType: $csvType
            isMetaDataUploaded: $isMetaDataUploaded
        )
    }
`;

export const BULK_UPLOAD_ASSET_DATA = gql`
    mutation bulkUploadAssetData($input: [bulkUploadAssetDataInput]!) {
        bulkUploadAssetData(input: $input)
    }
`;

export const CREATE_BULK_NFT_TOKENS = gql`
    mutation createBulkNftTokens($contractID: UUID1!, $file: Upload!) {
        createBulkNftTokens(contractID: $contractID, file: $file) {
            count
            status
        }
    }
`;

export const DELETE_TOKEN_BULK = gql`
    mutation deleteTokenBulk($tokenIds: [UUID1!]!, $contractId: UUID1!) {
        deleteTokenBulk(tokenIds: $tokenIds, contractId: $contractId)
    }
`;

export const TransferTokenMutation = gql`
    mutation transferTokenMutation(
        $walletId: UUID1!
        $orgId: UUID1!
        $contractAddress: String!
        $tokenType: TokenType
        $tokenOnChainId: Int!
        $amount: Int
        $transferTo: String!
        $isClaimedToken: Boolean
        $nftTokenId: UUID1
    ) {
        transferToken(
            walletId: $walletId
            orgId: $orgId
            contractAddress: $contractAddress
            tokenType: $tokenType
            tokenOnChainId: $tokenOnChainId
            amount: $amount
            transferTo: $transferTo
            isClaimedToken: $isClaimedToken
            nftTokenId: $nftTokenId
        )
    }
`;

export const acceptTermsAndConditionMutation = gql`
    mutation acceptTermsAndConditionMutation($userId: UUID1!) {
        acceptTermsAndConditions(userId: $userId)
    }
`;

import React, { ReactElement, useMemo } from 'react';
import { SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { MixTheme } from '@styles';

interface TooltipProps {
    children: ReactElement;
    message: string;
    sx?: object;
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
        | undefined;
    isShow?: boolean;
    tooltipMessageSx?: SxProps;
}

const CustomTooltip = ({
    children,
    message,
    placement,
    sx,
    isShow = true,
    tooltipMessageSx
}: TooltipProps) => {
    const theme: MixTheme = useTheme();

    const defaultStyle = useMemo(
        () => ({
            bgcolor: theme?.color?.blueColor,
            padding: '4px 6px',
            fontSize: '12px',
            fontWeight: 500,
            '& .MuiTooltip-arrow': {
                color: theme?.color?.blueColor
            }
        }),
        [theme?.color?.blueColor]
    );
    if (!isShow) {
        return children;
    }

    return (
        <Tooltip
            arrow
            title={<Typography sx={tooltipMessageSx}>{message}</Typography>}
            placement={placement}
            componentsProps={{
                tooltip: {
                    sx: { ...defaultStyle, ...sx }
                }
            }}
        >
            {children}
        </Tooltip>
    );
};

export default CustomTooltip;

import { SitePagesRow } from '@interface/sites';
import { Environment } from 'contentful-management';
import { EntityType } from 'src/constants/ContentFullConstants';

export const PagesTypes: SitePagesRow[] = [
    {
        title: 'Home',
        contentFullType: 'homePage',
        path: 'homePage',
        slug: '',
        blocks: [
            {
                type: 'heroBlock',
                field: 'heroBlock'
            },
            {
                type: 'contentAndMediaBlock',
                field: 'contentMediaBlock'
            },
            {
                type: 'featureItemBlock',
                field: 'featuredItemBlock'
            }
        ]
    },
    {
        title: 'About us',
        contentFullType: 'aboutPage',
        path: 'aboutUs',
        slug: 'about',
        blocks: [
            {
                type: 'titleBlock',
                field: 'titleBlock'
            },
            {
                type: 'contentAndMediaBlock',
                field: 'contentMediaBlock'
            },
            {
                type: 'textBlock',
                field: 'textBlock'
            }
        ]
    },
    {
        title: 'Content',
        contentFullType: 'contentPage',
        path: 'content',
        slug: 'content',
        blocks: [
            {
                type: 'titleBlock',
                field: 'titleBlock'
            },
            {
                type: 'contentAndMediaBlock',
                field: 'contentMediaBlock'
            },
            {
                type: 'textBlock',
                field: 'textBlock'
            }
        ]
    },
    {
        title: 'FAQ',
        contentFullType: 'faqPage',
        path: 'faq',
        slug: 'faq',
        blocks: [
            {
                type: 'titleBlock',
                field: 'titleBlock'
            },
            {
                type: 'faqBlock',
                field: 'faqBlock'
            },
            {
                type: 'faqBlock',
                field: 'faqBlock'
            }
        ]
    },
    {
        title: 'Collection',
        contentFullType: 'collectionPage',
        path: 'collection',
        slug: 'collection',
        blocks: [
            {
                type: 'heroBlock',
                field: 'heroBlock'
            },
            {
                type: 'collectionBlock',
                field: 'collectionBlock'
            }
        ]
    }
];

export const addPageToSite = async (
    data: any,
    page: SitePagesRow,
    env: Environment
) => {
    const reqBody: any = {};
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
        reqBody[keys[i]] = {
            'en-US': data[keys[i]]
        };
    }
    const entry = await env.createEntry(page.contentFullType, {
        fields: {
            ...reqBody
        }
    });
    reqBody['type'] = {
        'en-US': page.contentFullType
    };
    reqBody['page'] = {
        'en-US': {
            sys: {
                id: entry.sys.id,
                linkType: EntityType.ENTRY,
                type: EntityType.Link
            }
        }
    };
    const createdPage = await env.createEntry('pages', {
        fields: {
            ...reqBody
        }
    });
    createdPage.publish();
    return entry;
};

import React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import ModalContentContainer from './ModalContentContainer';
import { ContentData, WalletOptionsProps } from '../interface';
import WalletListContainer from './WalletListContainer';

export interface ExistingWalletProps {
    walletOptions: WalletOptionsProps;
    image: {
        logo: string;
        metamask: string;
        walletConnect: string;
    };
    content?: ContentData;
    isMobile: boolean;
    onClickEmail: () => void;
    onClickMetamask: () => void;
    onClickWalletConnect: () => void;
}

const ExistingWalletContainer = ({
    walletOptions,
    image,
    content,
    isMobile,
    onClickEmail,
    onClickMetamask,
    onClickWalletConnect
}: ExistingWalletProps) => {
    const theme = useTheme();
    return (
        <Box>
            <ModalContentContainer
                image={image.logo}
                title={content?.title || 'Connect your wallet'}
                description={content?.description || ''}
            />
            <WalletListContainer
                isMobile={isMobile}
                walletOptions={walletOptions}
                image={{
                    metamask: image.metamask,
                    walletConnect: image.walletConnect
                }}
                onClickMetamask={onClickMetamask}
                onClickWalletConnect={onClickWalletConnect}
                onClickEmail={onClickEmail}
            />
            {walletOptions?.enableEmail && (
                <>
                    <Divider
                        sx={{
                            marginBottom: '25px'
                        }}
                        orientation="horizontal"
                        flexItem
                    >
                        or
                    </Divider>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            textAlign: 'center',
                            fontWeight: '700',
                            cursor: 'pointer',
                            color: theme.palette.primary.main
                        }}
                        data-testid="onClick_email"
                        onClick={onClickEmail}
                    >
                        Create wallet using email
                    </Typography>
                </>
            )}
        </Box>
    );
};
export default ExistingWalletContainer;

import React, { FC } from 'react';
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    from,
    InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import { Configuration, RouterPath } from '@constants';
import { StorageService } from '@services/StorageService';
import { useRouter } from 'next/router';

interface MojitoApiProviderProps {
    children: React.ReactNode;
}

export const MojitoApiProvider: FC<MojitoApiProviderProps> = ({ children }) => {
    const router = useRouter();
    const { getIdTokenClaims, logout } = useAuth0();

    const httpLink = createHttpLink({
        uri: Configuration.API_HOST_URL
    });
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(async ({ message, extensions }) => {
                if (
                    message?.includes('token expired') ||
                    extensions?.code === 401 ||
                    message?.includes('jwt token invalid')
                ) {
                    const artistOrgId = StorageService.artistOrgId.getValue();
                    localStorage.clear();
                    if (!artistOrgId)
                        logout({ returnTo: window.location.origin });
                    else router.push(RouterPath.artistLogin);
                    return;
                }
            });
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const authLink = setContext(async (_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = await getIdTokenClaims();
        const isArtistApproval = StorageService.web3.getValue();
        const artistTokentype = StorageService.tokenType.getValue();
        // return the headers to the context so httpLink can read them
        if (token) {
            StorageService.token.setValue(token.__raw);
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token.__raw}` : ''
                }
            };
        } else if (isArtistApproval && artistTokentype != 'Bearer') {
            return {
                headers: {
                    ...headers,
                    authorization: StorageService.token.getValue()
                        ? `Bearer ${StorageService.token.getValue()}`
                        : ''
                }
            };
        }
        return {
            headers: {
                ...headers,
                authorization: StorageService.token.getValue()
                    ? `Token ${StorageService.token.getValue()}`
                    : ''
            }
        };
    });

    const client = new ApolloClient({
        link: from([errorLink, authLink.concat(httpLink)]),
        cache: new InMemoryCache()
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

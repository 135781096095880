export const RouterPath = {
    root: '/',
    login: '/login',
    signUp: '/signup',
    verify: '/verify',
    settings: '/settings',
    customers: '/customers/list',
    customerDetails: '/customers/detail',
    userList: '/user-list',
    organization: '/organization',
    sites: '/sites',
    siteNavigation: '/sites/[siteId]/navigation',
    sitePages: '/sites/[siteId]/pages',
    siteSettings: '/sites/[siteId]/settings',
    siteBranding: '/sites/[siteId]/branding',
    superAdminUsers: '/users/list',
    superAdminOrganizations: '/organization/list',
    superAdminOrganizationOverview: '/organization/details/[id]',
    allowLists: '/allowlists/list',
    collections: '/collections',
    dashboard: '/dashboard',
    allowListsDetail: '/allowlists/detail/[id]',
    allowListsSetting: '/allowlists/settings/[id]',
    collectionsDetail: '/collections/detail/[[...page]]',
    drops: '/drops',
    teamMembers: '/settings/teamMembers',
    walletSettings: '/settings/wallets',
    apiKeysSettings: '/settings/apiKeys',
    accountInfo: '/settings/account',
    organizationInfo: '/settings/organization',
    dropDetail: '/drops/details/[id]',
    superAdminGoToOrganization: '/organization/details/',
    siteOverview: '/sites/[siteId]/overview',
    collectionsDetailPage: '/collections/detail',
    collectionsSettings: '/collections/[id]/settings',
    dropSettings: '/drops/settings/[id]',
    dropListingSettings: '/drops/details/[id]/settings',
    reports: '/reports/details/salesItemsAndAuction',
    reportDetails: '/reports/details/[id]',
    overview: '/secondarymarket/overview',
    listingRegistry: '/secondarymarket/listingRegistry',
    marketActivity: '/secondarymarket/marketActivity',
    listingRegistryDetails: '/secondarymarket/listingRegistry/collections/[id]',
    overrideTokenDetails:
        '/secondarymarket/listingRegistry/collections/tokens/[id]',
    addToken: '/collections/detail/addToken',
    bulkUploadToken: '/collections/detail/bulkUpload',
    overviewSettings: '/secondarymarket/overview/settings',
    artistHome: '/creator-approval',
    artistLogin: '/creator-approval/login/[id]',
    newUser: '/newuser',
    tokengates: '/tokengates',
    discounts: '/discountCode',
    paymentLinks: '/paymentLink'
};

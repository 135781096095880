import { createClient } from 'contentful';
import * as manage from 'contentful-management';
import { Configuration } from '@constants';

export const client = createClient({
    accessToken: Configuration.CREATECLIENT_ACCESSTOKEN || '',
    space: Configuration.SPACEID || '',
    environment: 'master',
    resolveLinks: true
});

export const manageClient = manage.createClient({
    accessToken: Configuration.MANAGECLIENT_ACCESSTOKEN || ''
});
export const previewClient = createClient({
    space: Configuration.SPACEID || '',
    accessToken: Configuration.PREVIWCLIENT_ACCESSTOKEN || '',
    host: Configuration.HOST,
    environment: 'master',
    resolveLinks: true
});

import { Box, Stack, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { StorageService } from '@services/StorageService';
import Image from 'next/image';
import { useStyle } from '@styles';
import { makeStyles } from '@layouts/sites/sites.style';
import { useContentfull } from '@state/ContentfullContext';
import { SitePagesRow } from '@interface/sites';

const SitesSubmenuLayout = () => {
    const { styles } = useStyle(makeStyles);
    const router = useRouter();
    const [pagedata, setPagedata] = useState<SitePagesRow[]>([]);
    const [pageName, setPageName] = useState<string>('');
    const contentFul = useContentfull();
    const env = contentFul?.env;
    const menus = [
        {
            to: `/sites/${router.query.siteId}/overview`,
            name: 'Overview'
        },
        {
            to: `/sites/${router.query.siteId}/pages`,
            name: 'Pages'
        },
        {
            to: `/sites/${router.query.siteId}/navigation`,
            name: 'Navigation'
        },
        {
            to: `/sites/${router.query.siteId}/branding`,
            name: 'Branding'
        },
        {
            to: `/sites/${router.query.siteId}/settings`,
            name: 'Settings'
        }
    ];

    const getPath = useCallback((contentType: string) => {
        if (contentType === 'homePage') return 'homePage';
        if (contentType === 'aboutPage') return 'aboutUs';
        if (contentType === 'contentPage') return 'content';
        if (contentType === 'faqPage') return 'faq';
        if (contentType === 'collectionPage') return 'collection';
        return '';
    }, []);

    const getSite = useCallback(async () => {
        const entry = await env.getEntries({
            content_type: 'siteSetting',
            'fields.siteId[match]': router.query.siteId
        });
        if (entry.items.length > 0) {
            setPageName(entry.items[0].fields.pageTitle['en-US']);
        }
        if (router.pathname == '/sites/[siteId]/pages') {
            const entries = await env.getEntries({
                content_type: 'pages',
                'fields.orgId[match]': StorageService.orgId.getValue(),
                'fields.siteId[match]': router.query.siteId
            });
            const data: SitePagesRow[] = [];
            entries.items.map((value) => {
                data.push({
                    title: value.fields.pageTitle['en-US'],
                    contentFullType: value.fields.type['en-US'],
                    templateType: value.fields.type['en-US'],
                    path: value.fields.slug['en-US'],
                    contentFulId: value.fields.page['en-US'].sys.id
                });
            });
            setPagedata(data);
        }
    }, [env, router.pathname, router.query.siteId]);

    useEffect(() => {
        getSite();
    }, [getSite, router]);

    const onMenuClick = useCallback(
        (item: { to: string; name: string }) => {
            if (item.name === 'Pages') {
                return (
                    <Box>
                        <Box
                            sx={
                                item.to === router.asPath
                                    ? styles.menuListSelected
                                    : styles.menuList
                            }
                        >
                            <Stack
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                margin={'5px 0 5px 0'}
                            >
                                <Typography
                                    variant="body1"
                                    style={
                                        item.to === router.asPath
                                            ? styles.subMenuSelected
                                            : styles.subMenuNotSelected
                                    }
                                >
                                    <Link href={item.to}>
                                        <Box sx={styles.subMenuBackground}>
                                            {item.name}
                                            {item.name === 'Pages' && (
                                                <Image
                                                    src={
                                                        '/images/icons/downArrow.svg'
                                                    }
                                                    width={8}
                                                    height={8}
                                                />
                                            )}
                                        </Box>
                                    </Link>
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={styles.subMenuList}
                                >
                                    {item.name === 'Pages' && '+    New'}
                                </Typography>
                            </Stack>
                        </Box>
                        {item.name === 'Pages' &&
                            router.pathname == '/sites/[siteId]/pages' &&
                            pagedata.length > 0 &&
                            pagedata.map((val: any) => (
                                <Stack key={val.title}>
                                    <Link
                                        href={`/createsite/${
                                            val.contentFulId
                                        }/${getPath(
                                            val.contentFullType
                                        )}?siteName=${pageName}`}
                                    >
                                        <Box sx={styles.subMenuBackground}>
                                            <Typography
                                                variant="body1"
                                                style={styles.subMenuTitle}
                                            >
                                                {val.title}
                                            </Typography>
                                        </Box>
                                    </Link>
                                </Stack>
                            ))}
                    </Box>
                );
            } else {
                return (
                    <Link href={item.to} key={item.to}>
                        <Box
                            sx={
                                item.to === router.asPath
                                    ? styles.menuListSelected
                                    : styles.menuList
                            }
                        >
                            <Stack
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                margin={'5px 0 5px 0'}
                            >
                                <Typography
                                    variant="body1"
                                    style={
                                        item.to === router.asPath
                                            ? styles.subMenuSelected
                                            : styles.subMenuNotSelected
                                    }
                                >
                                    {item.name}
                                </Typography>
                            </Stack>
                        </Box>
                    </Link>
                );
            }
        },
        [
            pageName,
            pagedata,
            router.asPath,
            router.pathname,
            styles.menuList,
            styles.menuListSelected,
            styles.subMenuBackground,
            styles.subMenuList,
            styles.subMenuNotSelected,
            styles.subMenuSelected,
            styles.subMenuTitle,
            getPath
        ]
    );

    return (
        <Box sx={styles.card}>
            <Typography
                variant="h4"
                style={{ ...styles.cardTitle, margin: '30px 0' }}
            >
                Site Builder
            </Typography>
            <Stack>{menus.map((item) => onMenuClick(item))}</Stack>
        </Box>
    );
};

export default SitesSubmenuLayout;

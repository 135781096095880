export const RPC_URL = {
    1: 'https://eth-mainnet.g.alchemy.com/v2/iB_9SpJ1yj7MtksRPR6aq7cmxzwAwaL-',
    5: 'https://eth-goerli.g.alchemy.com/v2/iB_9SpJ1yj7MtksRPR6aq7cmxzwAwaL-',
    80001: 'https://polygon-mumbai.g.alchemy.com/v2/iB_9SpJ1yj7MtksRPR6aq7cmxzwAwaL-',
    137: 'https://polygon-mainnet.g.alchemy.com/v2/iB_9SpJ1yj7MtksRPR6aq7cmxzwAwaL-'
};

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export enum ErrorsType {
    RECOVERY_CODE = 'Invalid recovery',
    HIGH_RISK = 'high risk wallet',
    WRONG_WALLET = 'wallet does not belong to the user'
}

export const SessionVariable = {
    NetworkDetails: 'NetworkDetails',
    WalletDetails: 'WalletDetails',
    AuthToken: 'AuthToken'
};

export enum WalletProviderType {
    METAMASK = 'Metamask',
    WALLET_CONNECT = 'WalletConnect',
    EMAIL = 'Email'
}

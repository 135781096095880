export const Environment = {
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    imageBaseURL: process.env.NEXT_PUBLIC_IMAGE_BASE_URL,
    AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENTID,
    API_HOST_URL: process.env.NEXT_PUBLIC_API_HOSTNAME,
    CHAIN_ID: process.env.NEXT_PUBLIC_CHAINID || '0',
    ORGANIZATION_NAME: process.env.NEXT_PUBLIC_ORGANIZATION_NAME || '',
    GOERLI_CONTRACT_ADDRESS:
        process.env.NEXT_PUBLIC_ROYALITY_GOERLI_CONTRACT_ADDRESS || '',
    GOERLI_CHAIN_ID: process.env.NEXT_PUBLIC_ROYALITY_GOERLI_CHAIN_ID || '0',
    SEPOLIA_CONTRACT_ADDRESS:
        process.env.NEXT_PUBLIC_ROYALITY_SEPOLIA_CONTRACT_ADDRESS || '',
    SEPOLIA_CHAIN_ID: process.env.NEXT_PUBLIC_ROYALITY_SEPOLIA_CHAIN_ID || '0',
    ETHEREUM_MAINNET_CONTRACT_ADDRESS:
        process.env.NEXT_PUBLIC_ETHEREUM_MAINNET_CONTRACT_ADDRESS || '',
    ETHEREUM_MAINNET_CHAIN_ID:
        process.env.NEXT_PUBLIC_ETHEREUM_MAINNET_CHAIN_ID || '0',
    POLYGON_MAINNET_CHAIN_ID:
        process.env.NEXT_PUBLIC_ROYALITY_POLYGON_MAINNET_CHAIN_ID || '0',
    MUMBAI_TESTNET_CHAIN_ID:
        process.env.NEXT_PUBLIC_ROYALITY_MUMBAI_TEST_CHAIN_ID || '0',
    MUMBAI_TESTNET_CONTRACT_ADDRESS:
        process.env.NEXT_PUBLIC_MUMBAI_TESTNET_CONTRACT_ADDRESS || '',
    WALLET_CONNECT_ID: process.env.NEXT_PUBLIC_WALLET_CONNECT_ID || '0',
    GOOGLE_ANALYTICS_TRACKING_ID:
        process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID,
    PAPER_CLIENT_ID: process.env.NEXT_PUBLIC_PAPER_CLIENT_ID || '',
    PAPER_NETWORK_NAME: process.env.NEXT_PUBLIC_PAPER_NETWORK_NAME || 'Sepolia',
    PROJECT_ID:
        process.env.NEXT_PUBLIC_PROJECT_ID ||
        '9d9a7f55b77da8f61a031cf718b773ca',
    ORG_IGNORE_GAS_FEE_RESTRICTION:
        process.env.NEXT_PUBLIC_ORG_IGNORE_GAS_FEE_RESTRICTION || ''
};

export const getChainId = (name: string): number => {
    if (name === 'Polygon') {
        return 137;
    }
    if (name === 'Mumbai') {
        return 80001;
    }
    if (name === 'Ethereum') {
        return 1;
    }
    if (name === 'Goerli') {
        return 5;
    }
    if (name === 'Sepolia') {
        return 11155111;
    }
    return 0;
};

import { type WalletClient } from 'wagmi';
import { providers } from 'ethers';

export function getWalletConnectProvider(walletClient: WalletClient) {
    const { chain, transport } = walletClient;
    const network = {
        chainId: chain.id,
        name: chain.name,
        ensAddress: chain.contracts?.ensRegistry?.address
    };
    const provider = new providers.Web3Provider(transport, network);
    return provider;
}

import { createTheme } from '@mui/material/styles';
import { MixThemeOptions, MixTheme } from './ThemeOptions';

export const theme: MixTheme = createTheme({
    typography: {
        fontFamily: 'Sneak'
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: 'Sneak';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
            }
            @font-face {
                font-family: 'Sneak';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
              }
          `
        }
    },
    palette: {
        primary: {
            main: '#6663FD'
        },
        secondary: {
            main: '#FFFFFF'
        },
        myAwesomeColor: 'red'
    },
    font: {
        primary: 'Sneak',
        secondary: 'Sneak',
        tertiary: 'Sneak',
        sneak: 'Sneak'
    },
    global: {
        background: '#FAFAFC',
        darkBackground: '#0F172A',
        hoverBackground: '#F2F1FF',
        hover: '#6663FD',
        shadow: '#E0E0E0',
        title: '#000000',
        border: '#CACAE0',
        defaultBackground: '#E0E0E0',
        completedBackground: '#C9C8FE',
        inProgressBackground: '#BDBDBD',
        inProgressIndicator: '#F0EB47',
        completedIndicator: '#32C48D',
        normal: '#FFFFFF',
        indico: '#B0AFFE',
        tintsOfSeashell: '#F8F676',
        crusta: '#F98028',
        gray93: '#FDEDED',
        redOxide: '#5F2120',
        gray90: '#E5E5E5',
        jacksonPurple: '#9A9AC3',
        whitesmoke: '#F5F5F5',
        lightGray: '#EEEEEE',
        red: '#dd1717',
        grey: '#9E9E9E',
        mainlyBlue: '#6663FD',
        warning: '#FCAD36',
        lightIndico: '#E1E1FF',
        darkIndico: '#3330B6',
        lightBackground: '#FBFBFC',
        loginCardBackground: 'rgba(255, 255, 255, 0.4)',
        yellowBackground: '#FCFB99',
        lightGreenBackground: ' #E7EFE8'
    },
    switch: {
        color: '#6663fd'
    },
    color: {
        green: '#3E8045',
        textColor: '#8A8AB9',
        white: '#ffffff',
        black: '#000000',
        gray: '#FAFAFA',
        theadBg: '#FAFAFC',
        theadColor: '#8A8AB9',
        statusDeployed: '#EBF9F4',
        statusDraft: '#EAEAF3',
        deployedColor: '#196247',
        draftColor: '#6A6AA6',
        boxBorder: '#BABAD6',
        error: '#CE2818',
        purple: '#B0AFFE',
        danger: '#FEE3E5',
        gray600: '#757575',
        slate800: '#5C5C9B',
        oslo50: '#F4F4F5',
        oslo300: '#8C9092',
        darkGray: '#8C939E',
        borderColor: '#DADAE9',
        blueColor: '#53538B',
        warningBackground: '#FFFFE5',
        statusSuccessBackground: '#E7EFE8',
        slate600: '#7A7AB0',
        accordionColor: '#EEEEF4',
        borderColorOslo300: '#BABEC5',
        borderColorRed: '#D0372E',
        alertBackground: '#FFFEC0',
        red600: '#BF1E18',
        oslo200: '#D1D4D8',
        coreGhost: '#EEEFF4',
        coreBackground: '#A6FF00',
        indigoBlueBackground: '#2E2BA4',
        ThreadBlue: '#A8AEB6',
        grayColor: '#A3A9B1',
        lightBgColor: '#393939',
        lightGreenColor: '#CFDFD1',
        grayOsloColor: '#70767E',
        lightGrayOsloColor: '#E8E9EC',
        lockIconColor: '#7E848E',
        statusChipSuccessColor: '#0A4310',
        statusChipSuccessBackgroundColor: '#B7CFB9'
    }
} as MixThemeOptions);

import { SiteOptions } from '@interface/PageSite';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import React, { useCallback } from 'react';

interface MenuPopupProps {
    open: boolean;
    items: SiteOptions[];
    anchorElement?: HTMLElement | null;
    onNewClick: () => void;
    onDismiss: () => void;
    onSelect: (title: string, id: string) => void;
}

interface DropdownItemProps {
    label: string;
    id: string;
    onClick: (title: string, id: string) => void;
}

const DropdownItem = ({ label, id, onClick }: DropdownItemProps) => {
    const onSiteMenuClicked = useCallback(() => {
        onClick('sites', id);
    }, [id, onClick]);

    return (
        <MenuItem onClick={onSiteMenuClicked}>
            <Stack
                flexDirection={'row'}
                width={'100%'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Typography variant={'body2'} sx={{ fontFamily: 'Sneak' }}>
                    {label}
                </Typography>
                <Image
                    src={'/images/icons/eyeIcon.png'}
                    width={18}
                    height={13}
                />
            </Stack>
        </MenuItem>
    );
};

const MenuPopup = ({
    open,
    items,
    anchorElement,
    onNewClick,
    onDismiss,
    onSelect
}: MenuPopupProps) => {
    const openDialog = useCallback(() => {
        onNewClick();
    }, [onNewClick]);

    const handleClose = useCallback(() => {
        onDismiss();
    }, [onDismiss]);

    return (
        <>
            {anchorElement && (
                <Menu
                    MenuListProps={{
                        'aria-labelledby': 'long-button'
                    }}
                    anchorEl={anchorElement}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            width: '24ch',
                            top: '250px',
                            left: '25px'
                        }
                    }}
                    sx={{
                        top: '74px',
                        left: '29px'
                    }}
                >
                    <Stack
                        padding={'0 1rem'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontFamily: 'Sneak',
                                color: '#9E9E9E',
                                fontSize: '0.875rem',
                                textTransform: 'uppercase'
                            }}
                        >
                            Sites
                        </Typography>
                        <Typography
                            variant="h6"
                            onClick={openDialog}
                            style={{
                                cursor: 'pointer',
                                fontFamily: 'Sneak',
                                fontWeight: 600,
                                color: '#6663FD',
                                fontSize: '0.75rem'
                            }}
                        >
                            +&nbsp;&nbsp;New
                        </Typography>
                    </Stack>
                    <MenuItem
                        key={'goToDetail'}
                        onClick={() => console.log('CALLED')}
                    >
                        <Typography
                            variant={'body2'}
                            sx={{ fontFamily: 'Sneak' }}
                        >
                            Mojito Demo
                        </Typography>
                    </MenuItem>
                    {items.map((item: SiteOptions) => (
                        <DropdownItem
                            key={item.siteId}
                            id={item.siteId}
                            label={item.pageTitle}
                            onClick={onSelect}
                        />
                    ))}
                </Menu>
            )}
        </>
    );
};

// menuLink={  }

export default MenuPopup;

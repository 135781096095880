import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
    Input,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Typography,
    useTheme,
    Pagination as MUIPagination
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { MixTheme } from '@styles';
import { debounce } from 'lodash';

interface BottomPaginationInterface {
    total: number;
    limit: number;
    offset?: number;
    onHandlePagination: (pageNumber: number, limit: number) => void;
    isGridPagination?: boolean;
}

const limitOptions = [
    {
        value: 10,
        label: 10
    },
    {
        value: 20,
        label: 20
    },
    {
        value: 30,
        label: 30
    },
    {
        value: 40,
        label: 40
    }
];

export const Pagination = ({
    limit,
    onHandlePagination,
    total,
    isGridPagination = false,
    offset = 0
}: BottomPaginationInterface) => {
    const theme: MixTheme = useTheme();
    const [currentPage, setCurrentPage] = useState<number>(offset ? offset : 0);
    const [pagelimit, setPageLimit] = useState<number>(limit ? limit : 10);
    useMemo(() => {
        setCurrentPage(offset ?? 0);
        setPageLimit(limit);
    }, [offset, limit]);

    const totalPage = useMemo(
        () =>
            total
                ? total % pagelimit != 0
                    ? Math.trunc(total / pagelimit) + 1
                    : total / pagelimit
                : 1,
        [total, pagelimit]
    );

    const canMoveNext = useMemo(() => {
        return totalPage - 1 > currentPage;
    }, [totalPage, currentPage]);

    const canMovePrevious = useMemo(() => {
        return currentPage > 0;
    }, [currentPage]);

    const onHandlePreview = useCallback(() => {
        if (!canMovePrevious) return;
        onHandlePagination(currentPage - 1, pagelimit);
        setCurrentPage((prevPage) => prevPage - 1);
    }, [canMovePrevious, currentPage, pagelimit, onHandlePagination]);

    const onHandleNext = useCallback(() => {
        if (!canMoveNext) return;
        onHandlePagination(currentPage + 1, pagelimit);
        setCurrentPage((prevPage) => prevPage + 1);
    }, [canMoveNext, currentPage, pagelimit, onHandlePagination]);

    const handlePageInputDebounceFn = useCallback(
        (inputValue: any) => {
            onHandlePagination(
                Number(inputValue) >= totalPage
                    ? Number(totalPage) - 1
                    : Number(inputValue || 1) - 1,
                pagelimit
            );
            document.getElementById('pageRef')?.blur();
        },
        [onHandlePagination, pagelimit, totalPage]
    );

    const debouncePageInputChange = useCallback(
        (val: string) => {
            const callDebounce = debounce((debounceValue) => {
                handlePageInputDebounceFn?.(debounceValue);
            }, 1000);
            callDebounce(val);
        },
        [handlePageInputDebounceFn]
    );

    const onHandlePageNumber = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setCurrentPage(
                Number(e.target.value) >= totalPage
                    ? Number(totalPage)
                    : e.target?.value
                      ? Number(e.target?.value) - 1
                      : -1
            );
            debouncePageInputChange(e.target.value);
        },
        [totalPage, debouncePageInputChange]
    );

    const onHandleLimitChange = useCallback(
        (e: SelectChangeEvent) => {
            setPageLimit(Number(e.target.value));
            onHandlePagination(0, Number(e.target.value));
            setCurrentPage(0);
        },
        [onHandlePagination]
    );

    const handleGridPagination = useCallback(
        (selectedPage: number) => {
            onHandlePagination(selectedPage, limit);
        },
        [onHandlePagination, limit]
    );

    return (
        <>
            {isGridPagination ? (
                <MUIPagination
                    count={totalPage}
                    size="large"
                    page={offset}
                    variant="outlined"
                    shape="rounded"
                    onChange={(
                        _e: React.ChangeEvent<unknown>,
                        selectedPage: number
                    ) => handleGridPagination(selectedPage)}
                />
            ) : (
                <Stack
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: '15px',
                        width: '100%',
                        borderTop: `1px solid ${theme?.color?.borderColor}`
                    }}
                >
                    <Stack
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        padding={'15px'}
                    >
                        <Stack
                            fontSize={'0.875rem'}
                            fontWeight={400}
                            style={{ fontFamily: theme?.font?.primary }}
                        >{`${total ? total : 0} ${
                            total == 0 || total == 1 ? 'item' : 'items'
                        }`}</Stack>
                        <Stack>
                            <Stack
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                            >
                                <Typography
                                    variant="body2"
                                    onClick={onHandlePreview}
                                    sx={{
                                        color: theme?.palette?.primary?.main,
                                        opacity: canMovePrevious ? 1 : 0.8,
                                        marginRight: '40px',
                                        fontFamily: theme?.font?.primary,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                        cursor: canMovePrevious
                                            ? 'pointer'
                                            : 'not-allowed'
                                    }}
                                >
                                    Previous
                                </Typography>
                                <Typography
                                    variant={'h6'}
                                    sx={{
                                        fontFamily: theme?.font?.primary,
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    Page
                                </Typography>
                                <Input
                                    sx={{
                                        width: '41px',
                                        height: '36px',
                                        margin: '0 16px',
                                        fontFamily: theme?.font?.primary,
                                        fontSize: '0.875rem',
                                        border: `1px solid${theme?.global?.shadow}`,
                                        '& input': {
                                            textAlign: 'center'
                                        },
                                        '&:before': {
                                            borderBottomStyle: 'none !important'
                                        }
                                    }}
                                    id="pageRef"
                                    value={
                                        currentPage < 0
                                            ? ''
                                            : currentPage
                                              ? currentPage >= totalPage
                                                  ? currentPage
                                                  : currentPage + 1
                                              : 1
                                    }
                                    onChange={onHandlePageNumber}
                                    disabled={!canMoveNext && !canMovePrevious}
                                />
                                <Typography
                                    variant={'h6'}
                                    sx={{
                                        fontFamily: theme?.font?.primary,
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    of&nbsp;&nbsp;&nbsp;{totalPage}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    onClick={onHandleNext}
                                    sx={{
                                        color: theme?.global?.mainlyBlue,
                                        opacity: canMoveNext ? 1 : 0.8,
                                        marginLeft: '40px',
                                        fontFamily: theme?.font?.primary,
                                        fontSize: '0.875rem',
                                        cursor: canMoveNext
                                            ? 'pointer'
                                            : 'not-allowed'
                                    }}
                                >
                                    Next
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Stack
                                flexDirection={'row'}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Typography
                                    variant={'h6'}
                                    sx={{
                                        marginRight: '16px',
                                        fontFamily: theme?.font?.primary,
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    Show
                                </Typography>
                                <FormControl>
                                    <Select
                                        value={limit.toString()}
                                        onChange={onHandleLimitChange}
                                        sx={{
                                            width: '80px',
                                            height: '40px',
                                            borderRadius: 0,
                                            backgroundColor: theme?.color?.white
                                        }}
                                    >
                                        {limitOptions.map((item) => (
                                            <MenuItem
                                                value={item.value}
                                                sx={{
                                                    fontFamily:
                                                        theme?.font?.primary,
                                                    fontSize: '14px'
                                                }}
                                                key={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

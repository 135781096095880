import { Toast, Toasttypes } from '@components/Atoms';
import React, { createContext, useCallback, useContext, useState } from 'react';

interface ToastContextProps {
    setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    setToastType: React.Dispatch<React.SetStateAction<Toasttypes>>;
    setToast: (msg: string | React.ReactNode, type: Toasttypes) => void;
}

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

export const useToast = () => useContext(ToastContext);

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
    const [showToast, setShowToast] = useState<boolean>(false);
    const [message, setMessage] = useState<string | React.ReactNode>('');
    const [toastType, setToastType] = useState<Toasttypes | undefined>();

    const setToast = useCallback(
        (msg: string | React.ReactNode, type: Toasttypes) => {
            setMessage(msg);
            setToastType(type);
            setShowToast(true);
        },
        []
    );

    const handleCloseSnackbar = useCallback(
        (event?: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            setShowToast(false);
        },
        []
    );

    return (
        <ToastContext.Provider
            value={
                {
                    setShowToast,
                    setMessage,
                    setToastType,
                    setToast
                } as ToastContextProps
            }
        >
            {children}
            <Toast
                isOpen={showToast}
                message={message}
                type={toastType}
                onClose={handleCloseSnackbar}
            />
        </ToastContext.Provider>
    );
};

export default ToastProvider;

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper, SxProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import React, { ChangeEvent, useCallback, useState } from 'react';
import { debounce } from 'lodash';

interface InputSearchProps {
    name: string;
    placeholder: string;
    onChangeText?: (value: string) => void;
    onClick?: (value: string) => void;
    value?: string;
    sx?: SxProps<Theme>;
}

export const InputSearch: React.FC<InputSearchProps> = ({
    name,
    value,
    placeholder,
    onChangeText,
    onClick,
    sx = {}
}: InputSearchProps) => {
    const theme = useTheme();

    const [searchText, setSearchText] = useState<string>(value || '');

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
            const callDebounce = debounce((debounceValue) => {
                onChangeText?.(debounceValue);
            }, 500);
            callDebounce(event.target.value);
        },
        [onChangeText]
    );

    const handleClick = useCallback(() => {
        onClick?.(searchText);
    }, [onClick, searchText]);

    const handleClear = useCallback(() => {
        setSearchText('');
        onChangeText?.('');
        onClick?.('');
    }, [onChangeText, onClick]);

    return (
        <Paper
            sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                height: 36,
                border: `1px solid ${theme.palette.secondary.main}`,
                boxShadow: 'inherit',
                ...sx
            }}
        >
            <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={handleClick}
            >
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={placeholder}
                inputProps={{ 'aria-label': placeholder }}
                value={searchText}
                onChange={handleChange}
                name={name}
                autoComplete={'off'}
            />
            {searchText && (
                <IconButton
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                    onClick={handleClear}
                >
                    <ClearIcon />
                </IconButton>
            )}
        </Paper>
    );
};

export const Configuration = {
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    imageBaseURL: process.env.NEXT_PUBLIC_IMAGE_BASE_URL,
    AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENTID,
    API_HOST_URL: process.env.NEXT_PUBLIC_API_HOSTNAME,

    HOST: process.env.NEXT_PUBLIC_HOST,
    SPACEID: process.env.NEXT_PUBLIC_SPACE_ID,
    CREATECLIENT_ACCESSTOKEN: process.env.NEXT_PUBLIC_ACCESS_TOKEN_CREATECLIENT,
    MANAGECLIENT_ACCESSTOKEN: process.env.NEXT_PUBLIC_ACCESS_TOKEN_MANAGECLIENT,
    PREVIWCLIENT_ACCESSTOKEN: process.env.NEXT_PUBLIC_ACCESS_TOKEN_PREVIEWCLIENT
};

export const StoreProviderSkipRequests = {
    meQuery: true,
    organizationQuery: true,
    allowListQuery: true,
    dropsListQuery: true,
    collectionsListQuery: true
};
